import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { FaAward, FaRegStopCircle } from "react-icons/fa";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Overlay,
  Alert,
  Popover,
  OverlayTrigger,
} from "react-bootstrap";
import moment from "moment";
import { toast } from "react-toastify";

import BaseView from "../BaseView";

// import goldendreams modules
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import { WorkersNationalitiesArray } from "../../common/utilities/choices";

import {
  AvailableShifts,
  ContactJobTitles,
  DayOfWeekOff,
  EmployerPaysPolicyTypes,
  Genders,
  JobApplicationStatuses,
  JobTypes,
  OvertimeExpectations,
  PaymentTerms,
  PaymentTiming,
} from "../../common/utilities/choices";
import { getCities } from "../../common/redux/actions/CitiesActions";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getCurrencies } from "../../common/redux/actions/CurrenciesActions";
import { getJobBenefits } from "../../common/redux/actions/JobBenefitsActions";
import { getJobBenefitCategories } from "../../common/redux/actions/JobBenefitCategoriesActions";
import { getJobPositionCategories } from "../../common/redux/actions/JobPositionCategoriesActions";
import { getIndustries } from "../../common/redux/actions/IndustriesActions";
import { getRejectionReasons } from "../../common/redux/actions/RejectionReasonsActions";
import { getSubIndustries } from "../../common/redux/actions/SubIndustriesActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import {
  acceptJobApplication,
  rejectJobApplication,
} from "../../common/redux/actions/ProfileActions";

import { readNotification } from "../../common/redux/actions/ProfileActions";
import ShareButtons from "../../components/ShareButtons";
import JobsApplicationModal from "./JobsApplicationModal";
import { reverseTranslateEmployerSize } from "../../common/utilities/utilities";

export default function JobsDetailedViewV2(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const availableShifts = AvailableShifts({}, context, false);
  const cities = useSelector((state) => state.CitiesState);
  const city = useSelector((state) => state.CitiesState.details);
  const contactJobTitles = ContactJobTitles({}, context, false);
  const countries = useSelector((state) => state.CountriesState);
  const currencies = useSelector((state) => state.CurrenciesState);
  const dayOfWeekOff = DayOfWeekOff({}, context, false);
  const employerPaysPolicyTypes = EmployerPaysPolicyTypes({}, context, false);
  const genders = Genders({}, context, false);
  const jobApplicationStatuses = JobApplicationStatuses({}, context, false);
  const jobBenefits = useSelector((state) => state.JobBenefitsState);
  const jobBenefitCategories = useSelector(
    (state) => state.JobBenefitCategoriesState
  );
  const jobPositionCategories = useSelector(
    (state) => state.JobPositionCategoriesState
  );
  const jobTypes = JobTypes({}, context, false);
  const industries = useSelector((state) => state.IndustriesState);
  const language = useSelector((state) => state.i18nState.lang);
  const overtimeExpectations = OvertimeExpectations({}, context, false);
  const paymentTerms = PaymentTerms({}, context, false);
  const paymentTiming = PaymentTiming({}, context, false);
  const profile = useSelector((state) => state.ProfileState.data);
  const rejectionReasons = useSelector((state) => state.RejectionReasonsState);
  const subindustries = useSelector((state) => state.SubIndustriesState);
  const states = useSelector((state) => state.StatesState);

  const [application, setApplication] = useState(null);
  const [job, setJob] = useState(null);
  const [show, setShow] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [applyingJob, setApplyingJob] = useState(false);
  const [acceptingJobOffer, setAcceptingJobOffer] = useState(false);
  const [rejectingJobOffer, setRejectingJobOffer] = useState(false);
  const [missingDocuments, setMissingDocuments] = useState([]);
  const [raCountryObj, setRaCountryObj] = useState({});

  const [showPopupWindow, setShowPopupWindow] = useState(null);
  const options = WorkersNationalitiesArray({}, context, false);
  const jobId = parseInt(props.match.params.id);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  let cancel = null;

  // References
  const popoverTarget = useRef(null);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    !countries.fetched && !countries.loading && dispatch(getCountries());
    !currencies.fetched && !currencies.loading && dispatch(getCurrencies());
    !jobBenefits.fetched && dispatch(getJobBenefits());
    !jobBenefitCategories.fetched && dispatch(getJobBenefitCategories());
    !jobPositionCategories.fetched && dispatch(getJobPositionCategories());
    !industries.fetched && !industries.loading && dispatch(getIndustries());
    !rejectionReasons.fetched &&
      !rejectionReasons.loading &&
      dispatch(getRejectionReasons());
    !subindustries.fetched &&
      !subindustries.loading &&
      dispatch(getSubIndustries());
    !states.fetched && !states.loading && dispatch(getStates());
    !cities.fetched && !cities.loading && dispatch(getCities());
  }, []);

  const fetchData = async (
    jobId,
    language,
    setError,
    setLoading,
    setJob,
    cancel
  ) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_V2_URL}/jobs/${jobId}?lang=${language}`,
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      });

      setError(false);
      setLoading(false);
      setJob(response.data);
    } catch (error) {
      if (axios.isCancel(error)) return;
      setError(true);
    }
  };

  useEffect(() => {
    if (jobId && jobId !== null) {
      fetchData(jobId, language, setError, setLoading, setJob, cancel);

      // Cleanup function
      return () => {
        if (cancel) {
          cancel();
        }
      };
    }
  }, [jobId, language]);

  const jobStatusRenderer = (job) => {
    if (job.is_closed) {
      return (
        <Row style={{ textAlign: "center" }}>
          <p style={{ color: "brown" }}>
            <i className="fas fa-exclamation-circle fa-md"></i>
            {context.t("Recruitment closed!")}
          </p>
        </Row>
      );
    }

    if (
      (!job.is_closed && moment().isAfter(job.expired_at)) ||
      moment().isAfter(job.closed_at)
    ) {
      return (
        <Row style={{ textAlign: "center" }}>
          <p style={{ color: "brown" }}>
            <i className="fas fa-exclamation-circle fa-md"></i>
            {context.t("Recruitment Expired!")}
          </p>
        </Row>
      );
    }
  };

  useEffect(() => {
    let missingDocuments = [];

    if (profile.resume) {
      if (profile.resume.country == 2) {
        missingDocuments = [
          !profile.nrc && context.t("NRC"),
          !profile.householdlist && context.t("Household List"),
          !profile.passport && context.t("Passport (PJ)"),
          !profile.labourcard && context.t("Labour Card"),
          !profile.covidvaccinecertificate &&
            context.t("Covid Vaccine Certificate"),
        ].filter((x) => x);
      } else if (profile.resume.country == 3) {
        missingDocuments = [
          !profile.passport && context.t("Passport"),
          !profile.idcard && context.t("Identification Card"),
          !profile.birthcertificate && context.t("Birth Certificate"),
          !profile.familyrecordbook && context.t("Family Record Book"),
          !profile.residencebook && context.t("Residence Book"),
          !profile.covidvaccinecertificate &&
            context.t("Covid Vaccine Certificate"),
        ].filter((x) => x);
      } else {
        missingDocuments = [
          !profile.passport && context.t("Passport"),
          !profile.workpermit && context.t("Work Permit"),
          !profile.visa && context.t("Visa"),
          !profile.pinkcard && context.t("Pink Card"),
          !profile.cancellationletter && context.t("Cancellation Letter"),
        ].filter((x) => x);
      }
    }
    setMissingDocuments(missingDocuments);
  }, [profile]);

  // mark notification as read (if any)
  useEffect(() => {
    if (job && profile.notifications) {
      markAsRead();
    }
  }, [profile.notifications, job]);

  useEffect(() => {
    if (job && profile && profile.applications) {
      let jobApplication = profile.applications.filter(
        (application) => application.job == job.id
      )[0];
      setApplication(jobApplication);
    }
  }, [job, profile.applications]);

  useEffect(() => {
    if (
      job &&
      job.job_poster_country &&
      job.job_poster_country !== null &&
      countries.fetched &&
      countries.items
    ) {
      setRaCountryObj(countries.items[job.job_poster_country]);
    }
  }, [job]);

  useEffect(() => {
    let jobId = parseInt(props.match.params.id);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/jobs/${jobId}/viewed/?lang=${language}`,
        { withCredentials: true }
      )
      .then((res) => console.log("viewed"))
      .catch((err) => console.log("viewed recently"));
  }, []);

  const handleApplicationConfirmation = () => {
    if (profile.resume) {
      setShow(true);
    } else {
      setShowPopover(true);
    }
  };

  const handleApply = (jobId) => {
    history.push(`/jobs/${job.id}/screening`);
  };

  const handleAcceptJobOffer = () => {
    setAcceptingJobOffer(true);
    if (application) {
      dispatch(acceptJobApplication(application.id))
        .then((res) =>
          toast.success(context.t("Successfully accepted job offer"))
        )
        .catch((err) => {
          toast.error(context.t("Couldn't accept due to server error"));
        })
        .finally(() => setAcceptingJobOffer(false));
    }
  };

  // dynamic apply button
  const applyButton = (profile, job) => {
    // check the users has the resume
    if (
      profile.resume &&
      (!job.is_closed ||
        !moment().isAfter(job.expired_at) ||
        !moment().isAfter(job.closed_at))
    ) {
      // recruitment agency conditional statement
      if (job.recruitment_agency) {
        if (profile.resume.country == job.job_poster_country) {
          return (
            <Button
              onClick={() => handleApplicationConfirmation()}
              className="apply-button"
              ref={popoverTarget}
              disabled={applyingJob}
            >
              <strong>
                {applyingJob ? context.t("Loading…") : context.t("Apply")}
                &nbsp;&nbsp;&gt;
              </strong>
            </Button>
          );
        } else {
          return (
            <Alert className="warning-message button-modified">
              <i class="fa-solid fa-triangle-exclamation"></i>&nbsp;&nbsp;
              {context.t(
                "Sorry you cannot apply for this job. This job is for the jobseekers who live in {country}.",
                {
                  country:
                    countries.items[job.job_poster_country] &&
                    countries.items[job.job_poster_country].name,
                }
              )}
            </Alert>
          );
        }
      } else {
        // employer job conditional statement
        if (countries.items[profile.resume.country].code == "th") {
          return (
            <Button
              onClick={() => handleApplicationConfirmation()}
              className="apply-button"
              ref={popoverTarget}
              disabled={applyingJob}
            >
              <strong>
                {applyingJob ? context.t("Loading…") : context.t("Apply")}
                &nbsp;&nbsp;&gt;
              </strong>
            </Button>
          );
        } else {
          return (
            <p className="warning-message">
              <i class="fa-solid fa-triangle-exclamation"></i>&nbsp;&nbsp;
              {context.t(
                "Sorry you cannot apply job now as current jobs are for those in Thailand."
              )}
            </p>
          );
        }
      }
    } else {
      return (
        <center>
          <Link to="/resume" style={{ color: "green" }}>
            {context.t("Click here to create a resume to apply for this job")}
          </Link>
        </center>
      );
    }
  };

  const handleRejectJobOffer = () => {
    setRejectingJobOffer(true);

    dispatch(rejectJobApplication(application.id))
      .then((res) =>
        toast.success(context.t("Successfully rejected job offer"))
      )
      .catch((err) => {
        toast.error(context.t("Couldn't reject due to server error"));
      })
      .finally(() => setRejectingJobOffer(false));
  };

  const popUpConfirmation = (
    <Popover>
      <Popover.Title as="h6" style={{ color: "brown" }}>
        <strong>{context.t("Confirm Reject Job Offer")}</strong>
      </Popover.Title>
      <Popover.Content>
        <p>
          {context.t(
            "Are you sure you would like to reject the job offer? This action cannot be undone later.?"
          )}
        </p>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              size="sm"
              className="button-modified"
              style={{ width: "100%" }}
              onClick={() => document.body.click()}
            >
              {context.t("Cancel")}
            </Button>
          </Col>
          <Col>
            <Button
              variant="danger"
              size="sm"
              className="button-modified"
              style={{ width: "100%" }}
              onClick={handleRejectJobOffer}
            >
              {context.t("Confirm")}
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  const handleCreateResume = () => {
    history.push({
      pathname: "/resume",
      search: `?next=${location.pathname}`,
    });
  };

  const unreadNotifications = () => {
    return profile.notifications.filter(
      (notification) =>
        notification.unread &&
        // application notification
        (([
          "APPLICATION_ACCEPTED",
          "APPLICATION_DOWNLOADED",
          "APPLICATION_DOCUMENTS_REQUESTED",
          "APPLICATION_OFFERED",
          "APPLICATION_REJECTED_BY_BUSINESS",
          "APPLICATION_SHORTLISTED",
          "APPLICATION_VIEWED",
        ].includes(notification.type) &&
          notification.target_object_id == job.id) ||
          // new job posted notification
          (notification.type === "NEW_JOB_POSTED" &&
            notification.action_object_id == job.translation.id))
    );
  };

  const markAsRead = () => {
    let notifications = unreadNotifications();
    for (const notification of notifications) {
      dispatch(readNotification(notification.id || notification.uuid));
    }
  };

  const nationality = (shortDescriptions) => {
    let nationalities = [];
    options.map((option) => {
      if (shortDescriptions.includes(option.short)) {
        nationalities.push(option.value);
      }
    });
    return nationalities.join(" ,");
  };

  return (
    <BaseView title={context.t("Jobs")} backurl="/jobs">
      <hr className="afterTopNavigationBar"></hr>
      {industries.loading ||
      subindustries.loading ||
      job == null ||
      currencies.loading ||
      city.loading ||
      countries.loading ||
      states.loading ||
      jobBenefits.loading ||
      !job ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container style={{ textAlign: "left" }}>
          <JobsApplicationModal
            show={show}
            handleClose={handleClose}
            job={job}
            profile={profile}
            handleApply={handleApply}
          />
          {application && application.status == "REJECTED_BY_BUSINESS" && (
            <Alert variant="danger" className="button-modified">
              <p className="job-label-header" style={{ marginBottom: 0 }}>
                {context.t("Rejection Reason")}:
                {rejectionReasons.items[application.rejection_reason] &&
                  rejectionReasons.items[application.rejection_reason].name}
              </p>
              {application.rejection_reason_other && (
                <p className="job-label-header">
                  {context.t("Details")}: {application.rejection_reason_other}
                </p>
              )}
            </Alert>
          )}

          <Row>
            <Col xs={12}>
              <strong>
                <span className="job-label-header">{context.t("Posted")}:</span>
                {moment(job.posted_at).format("LL")}
              </strong>
            </Col>
            <Col xs={12}>
              <strong>
                <span className="job-label-header">
                  {context.t("Closing date")}:
                </span>
                {job.recruitment_agency
                  ? moment(job.closed_at).format("LL")
                  : moment(job.expired_at).format("LL")}
              </strong>
            </Col>
          </Row>
          <hr />
          <Link to={`/employers/${job.employer}?prev=/jobs/${job.id}`}>
            <div className="employer-information">
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <p className="label-employer">
                    {job.employer_workers_name || job.employer_name}
                  </p>
                  <Image
                    src={
                      job.emp_logo
                        ? job.emp_logo
                        : "/static/media/ImagePlaceHolder.png"
                    }
                    crossOrigin="anonymous"
                    className="job-emp-ra-profile"
                    onError={addDefaultImageSrc}
                  />
                  {job.have_employer_pays_policy ? (
                    <div style={{ paddingTop: 20 }}>
                      <span style={{ paddingRight: 5 }}>
                        <FaAward />
                      </span>
                      {context.t("Employer Pays Policy")}
                    </div>
                  ) : (
                    <div style={{ paddingTop: 20, color: "brown" }}>
                      <i class="fa-regular fa-circle-xmark"></i>
                      {context.t("Employer does not have Employer Pays Policy")}
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs={12} lg={12} md={12}>
                  {job.job_title && (
                    <h5
                      className="job-label-header"
                      style={{ marginBottom: 0 }}
                    >
                      {job.job_title}
                    </h5>
                  )}
                  <h5 className="job-label-header" style={{ marginBottom: 0 }}>
                    {job.translation.title}
                  </h5>
                  {job.employer_state && job.employer_city && (
                    <p style={{ marginBottom: 0 }}>
                      {states.items[job.employer_state] &&
                        states.items[job.employer_state].name}
                      ,
                      {cities.items[job.employer_city] &&
                        cities.items[job.employer_city].name}
                    </p>
                  )}
                  {job.recruitment_agency && (
                    <>
                      <p style={{ marginBottom: 0 }}>
                        {context.t("Employer Size")} :
                        {reverseTranslateEmployerSize(job.employer_size)}
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        {context.t(
                          "Nationalities of Workers working at this workplace"
                        )}
                        : {nationality(job.nationalities_of_workers)}
                      </p>
                    </>
                  )}
                  {/* <p style={{ marginBottom: 0 }}>
                    {context.t("Open to: {genders}", {
                      genders:
                        job.open_to_genders
                          .map((gender) => genders[gender])
                          .join(", ") || "-",
                    })}
                  </p> */}
                  {!job.headcount_by_genders ? (
                    <p style={{ marginBottom: 0 }}>
                      {context.t("Number of Workers Needed")}:
                      {job.number_of_headcount_needed || "-"}
                    </p>
                  ) : (
                    <p style={{ marginBottom: 0 }}>
                      {context.t("Number of Workers Needed")}:
                      {job.number_of_male_headcount_needed +
                        job.number_of_female_headcount_needed || "-"}
                    </p>
                  )}
                  {job.headcount_by_genders &&
                    job.number_of_male_headcount_needed && (
                      <>
                        <p style={{ marginBottom: 0 }}>
                          {context.t("Number of Male Headcount Needed")}:
                          {job.number_of_male_headcount_needed || "-"}
                        </p>
                      </>
                    )}
                  {job.headcount_by_genders &&
                    job.number_of_female_headcount_needed && (
                      <>
                        <p style={{ marginBottom: 0 }}>
                          {context.t("Number of Female Headcount Needed")}:
                          {job.number_of_female_headcount_needed || "-"}
                        </p>
                      </>
                    )}
                  <p style={{ marginBottom: 0 }}>
                    {context.t("Available Shifts")}:
                    {availableShifts[job.available_shifts] || "-"}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {context.t("Overtime Expectation")}:
                    {overtimeExpectations[job.overtime_expectation] || "-"}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {context.t("Minimum Working Hours")}:
                    {job.minimum_working_hours || "-"}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {context.t("Day of Week Off")}:
                    {dayOfWeekOff[job.day_of_week_off] || "-"}
                  </p>
                </Col>
              </Row>
            </div>
          </Link>

          <Row className="mt-4">
            <Col xs={12}>
              <p className="job-label-header" style={{ marginBottom: 0 }}>
                {context.t("Job Description")}
              </p>
              <p style={{ marginBottom: 0 }}>
                {job.translation.description
                  ? job.translation.description
                  : "xxxx"}
              </p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <p className="job-label-header" style={{ marginBottom: 0 }}>
                {context.t("Job Requirements")}
              </p>
              <p style={{ marginBottom: 0 }}>
                {job.translation.requirements
                  ? job.translation.requirements
                  : "xxxx"}
              </p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <p className="job-label-header" style={{ marginBottom: 0 }}>
                {context.t("Job Industry")}
              </p>
              <p style={{ marginBottom: 0 }}>
                {subindustries.items[job.employer_industry]
                  ? `${
                      industries.items[
                        subindustries.items[job.employer_industry].industry_id
                      ].name
                    }:
                    ${subindustries.items[job.employer_industry].name}
                   `
                  : "xxxx"}
              </p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <p className="job-label-header" style={{ marginBottom: 0 }}>
                {context.t("Job Position Category")}
              </p>
              <p style={{ marginBottom: 0 }}>
                {(jobPositionCategories.items[job.job_position_category] &&
                  jobPositionCategories.items[job.job_position_category]
                    .name) ||
                  "-"}
              </p>
            </Col>
          </Row>

          {job.translation.job_position_details && (
            <Row className="mt-3">
              <Col xs={12}>
                <p style={{ marginBottom: 0 }}>
                  <span className="job-label-header">
                    {context.t("Job Position Details")}:
                  </span>
                  {job.translation.job_position_details}
                </p>
              </Col>
            </Row>
          )}

          <Row className="mt-3">
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                <span className="job-label-header">
                  {context.t("Terms of Payment")}
                </span>
              </p>
              <p>{paymentTerms[job.terms_of_payment]} </p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                <span className="job-label-header">
                  {context.t("Timing of Payment")}
                </span>
              </p>
              <p> {paymentTiming[job.timing_of_payment]} </p>
            </Col>
          </Row>

          {job.timing_of_payment_other && (
            <Row className="mt-3">
              <Col xs={12}>
                <p style={{ marginBottom: 0 }}>
                  <span className="job-label-header">
                    {context.t("Timing of Payment Other")}:
                  </span>
                  {job.timing_of_payment_other}
                </p>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                {job.terms_of_payment == "Monthly" ? (
                  <span>
                    <span className="job-label-header">
                      {context.t("Salary")}:
                    </span>
                    {context.t("{amount} {currency}/month", {
                      amount: job.salary || "-",
                      currency:
                        currencies.items[job.currency] &&
                        currencies.items[job.currency].code,
                    })}
                  </span>
                ) : (
                  <span>
                    <span className="job-label-header">
                      {context.t("Daily Wage Rate")}:
                    </span>
                    {context.t("{amount} {currency}/day", {
                      amount: job.daily_wage_rate || "-",
                      currency:
                        currencies.items[job.currency] &&
                        currencies.items[job.currency].code,
                    })}
                  </span>
                )}
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                <span className="job-label-header">
                  {context.t("Job Type")}:
                </span>
                {job.job_type && jobTypes[job.job_type || "-"]}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                <span className="job-label-header">
                  {context.t("Contract Duration")}:
                </span>
                {context.t("{duration} months", {
                  duration: job.contract_duration_months,
                })}
              </p>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                <span className="job-label-header">
                  {context.t("Regular OT Rate")}:
                </span>
                {context.t("{amount} {currency}/hour", {
                  amount: job.ot_rate_regular,
                  currency:
                    currencies.items[job.currency] &&
                    currencies.items[job.currency].code,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                <span className="job-label-header">
                  {context.t("Holiday OT Rate")}:
                </span>
                {context.t("{amount} {currency}/hour", {
                  amount: job.ot_rate_holiday,
                  currency:
                    currencies.items[job.currency] &&
                    currencies.items[job.currency].code,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                <span className="job-label-header">
                  {context.t("Holiday Rate")}:
                </span>
                {context.t("{amount} {currency}/hour", {
                  amount: job.normal_rate_holiday,
                  currency:
                    currencies.items[job.currency] &&
                    currencies.items[job.currency].code,
                })}
              </p>
            </Col>
          </Row>
          <hr />
          {job.recruitment_agency && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("ER information")}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.have_ethical_recruitment_policy ? (
                      <>
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#4caf50" }}
                        ></i>
                        <span> {context.t("Ethical recruitment policy")} </span>
                      </>
                    ) : (
                      <>
                        <FaRegStopCircle style={{ color: "brown" }} />
                        <span>
                          {context.t("Not Have Ethical recruitment policy")}
                        </span>
                      </>
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.ra_have_ethical_recruitment_policy ? (
                      <>
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#4caf50" }}
                        ></i>
                        <span>
                          {context.t("RA has Ethical recruitment policy")}
                        </span>
                      </>
                    ) : (
                      <>
                        <FaRegStopCircle style={{ color: "brown" }} />
                        <span>
                          {context.t(
                            "RA has not the Ethical recruitment policy"
                          )}
                        </span>
                      </>
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.have_employer_pays_policy ? (
                      <>
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#4caf50" }}
                        ></i>
                        <span>
                          {context.t("Employer pays policy")}
                          {job.employer_pays_policy_type &&
                            `(${
                              employerPaysPolicyTypes[
                                job.employer_pays_policy_type
                              ]
                            })`}
                        </span>
                      </>
                    ) : (
                      <>
                        <FaRegStopCircle style={{ color: "brown" }} />
                        <span>
                          {context.t("Not have Employer pays policy")}
                        </span>
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          {!job.recruitment_agency && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("Recruitment Information")}:
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.have_ethical_recruitment_policy ? (
                      <>
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#4caf50" }}
                        ></i>
                        <span> {context.t("Ethical recruitment policy")} </span>
                      </>
                    ) : (
                      <>
                        <FaRegStopCircle style={{ color: "brown" }} />
                        <span>
                          {context.t("Not have Ethical recruitment policy")}
                        </span>
                      </>
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.have_employer_pays_policy ? (
                      <>
                        <i
                          className="fas fa-check-circle"
                          style={{ color: "#4caf50" }}
                        ></i>
                        <span>
                          {context.t("Employer pays policy")}
                          {job.employer_pays_policy_type &&
                            `(${
                              employerPaysPolicyTypes[
                                job.employer_pays_policy_type
                              ]
                            })`}
                        </span>
                      </>
                    ) : (
                      <>
                        <FaRegStopCircle style={{ color: "brown" }} />
                        <span>
                          {context.t("Not have Employer pays policy")}
                        </span>
                      </>
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.worker_pays_visa_fees &&
                    job.worker_pays_visa_fees_amount ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("The workers have to pay visa fee")}(
                          {job.worker_pays_visa_fees_amount}
                          {currencies.items[job.currency] &&
                            currencies.items[job.currency].code}
                          )
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("Employer pays for the visa fees")}
                        </span>
                      </>
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.worker_pays_work_permit_fees &&
                    job.worker_pays_work_permit_fees ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("The workers have to pay work permit fee")}
                          ({job.worker_pays_work_permit_fees_amount}
                          {currencies.items[job.currency] &&
                            currencies.items[job.currency].code}
                          )
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("Employer pays for the work permit fees")}
                        </span>
                      </>
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.worker_pays_medical_checkup_fees &&
                    job.worker_pays_medical_checkup_fees ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers have to pay medical checkup fee"
                          )}
                          ({job.worker_pays_medical_checkup_fees_amount}
                          {currencies.items[job.currency] &&
                            currencies.items[job.currency].code}
                          )
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays for the medical checkup fees"
                          )}
                        </span>
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          {job.recruitment_agency &&
            job.is_skill_training_required_for_this_job && (
              <>
                <Row className="mt-3">
                  <Col xs={12}>
                    <p className="job-label-header" style={{ marginBottom: 0 }}>
                      {context.t("Skill Training Information")}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {!job.worker_pays_skill_training ? (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t("Employer pays Cost of skill-training")}
                            &nbsp;
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay Cost of skill-training"
                            )}
                            &nbsp; (
                            {context.t("{amount} {currency}", {
                              amount:
                                job.worker_pays_skill_training_amount || "-",
                              currency:
                                currencies.items[job.currency] &&
                                currencies.items[job.currency].code,
                            })}
                            )
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p style={{ marginBottom: 0 }}>
                      {!job.is_accommodation_provided_during_skill_training ? (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The employer provides accommodation during skill-training of Workers"
                            )}
                            &nbsp;
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay accommodations fee during skill-training of Workers"
                            )}
                            &nbsp; (
                            {context.t("{amount} {currency}", {
                              amount:
                                job.is_accommodation_provided_during_skill_training_amount ||
                                "-",
                              currency:
                                currencies.items[job.currency] &&
                                currencies.items[job.currency].code,
                            })}
                            )
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p style={{ marginBottom: 0 }}>
                      {!job.is_meal_provided_during_skill_training ? (
                        <span>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The employer provides meals during skill-training of Workers"
                            )}
                            &nbsp;
                          </span>
                        </span>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay meals fee during skill-training of Workers"
                            )}
                            &nbsp;
                            <span className="info-text">
                              {context.t("Based on individual cost/spending.")}
                            </span>
                            {/* ({context.t('{amount} {currency}', {
                              amount: job.is_meal_provided_during_skill_training_amount || '-',
                              currency: currencies.items[job.ra_currency] && currencies.items[job.ra_currency].code
                            })}) */}
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p style={{ marginBottom: 0 }}>
                      {!job.workers_pay_transport_costs_for_attending_skill_training ? (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The employer pays transportation costs to and from for attending skill training"
                            )}
                            &nbsp;
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay transportation costs to and from for attending skill training"
                            )}
                            &nbsp;
                            <span className="info-text">
                              {context.t("Based on individual cost/spending.")}
                            </span>
                            {/* ({context.t('{amount} {currency}', {
                              amount: job.workers_pay_transport_costs_for_attending_skill_training_amount || '-',
                              currency: currencies.items[job.ra_currency] && currencies.items[job.ra_currency].code
                            })}) */}
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                </Row>
                <hr />
              </>
            )}
          {job.recruitment_agency &&
            job.is_pre_departure_orientation_training_required_for_this_job && (
              <>
                <Row className="mt-3">
                  <Col xs={12}>
                    <p className="job-label-header" style={{ marginBottom: 0 }}>
                      {context.t("Costs For Pre-Departure")}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {!job.worker_pays_cost_of_pre_departure_orientation_training ? (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "Employer pays Cost of pre-departure orientation training"
                            )}
                            &nbsp;
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay Cost of pre-departure orientation training"
                            )}
                            &nbsp; (
                            {context.t("{amount} {currency}", {
                              amount:
                                job.worker_pays_cost_of_pre_departure_orientation_training_amount ||
                                "-",
                              currency:
                                currencies.items[job.currency] &&
                                currencies.items[job.currency].code,
                            })}
                            )
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p style={{ marginBottom: 0 }}>
                      {!job.is_accommodation_provided_for_pre_departure_orientation_training ? (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "Employer provides accommodation during pre-departure orientation training"
                            )}
                            &nbsp;
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay accommodations fee during pre-departure orientation training"
                            )}
                            &nbsp; (
                            {context.t("{amount} {currency}", {
                              amount:
                                job.accommodation_for_pre_departure_orientation_training_amount ||
                                "-",
                              currency:
                                currencies.items[job.currency] &&
                                currencies.items[job.currency].code,
                            })}
                            )
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p style={{ marginBottom: 0 }}>
                      {!job.is_meal_provided_for_pre_departure_orientation_training ? (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "Employer provides meals during pre-departure orientation training"
                            )}
                            &nbsp;
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay meals fee during pre-departure orientation training"
                            )}
                            &nbsp;
                            <span className="info-text">
                              {context.t("Based on individual cost/spending.")}
                            </span>
                            {/* ( {context.t('{amount} {currency}', {
                              amount: job.is_meal_provided_for_pre_departure_orientation_training_amount || '-',
                              currency: currencies.items[job.ra_currency] && currencies.items[job.ra_currency].code
                            })}) */}
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs={12}>
                    <p style={{ marginBottom: 0 }}>
                      {!job.worker_pay_transport_costs_for_pre_departure_orientation ? (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "Employer pays transportation costs to and from pre-departure orientation training"
                            )}
                            &nbsp;
                          </span>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span>
                            {context.t(
                              "The workers need to pay transportation costs to and from pre-departure orientation training"
                            )}
                            &nbsp;
                            <span className="info-text">
                              {context.t("Based on individual cost/spending.")}
                            </span>
                            {/* ({context.t('{amount} {currency}', {
                              amount: job.worker_pay_transport_costs_for_pre_departure_orientation_amount || '-',
                              currency: currencies.items[job.ra_currency] && currencies.items[job.ra_currency].code
                            })}) */}
                          </span>
                        </>
                      )}
                    </p>
                  </Col>
                  <Col className="mt-3" xs={12}>
                    <p style={{ marginBottom: 0 }}>
                      <span className="job-label-header">
                        {context.t(
                          "Additional information for Pre-departure orientation course"
                        )}
                      </span>
                      <br />
                      {
                        job.additional_information_for_pre_departure_orientation_course
                      }
                    </p>
                  </Col>
                </Row>
                <hr />
              </>
            )}

          {job.recruitment_agency && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t(
                      "Costs and Fees for recruitment in the origin country"
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_recruitment_service_fee ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays recruitment service fee to RA and sub-agent"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay recruitment service fee to RA and sub-agent"
                          )}
                          &nbsp; (
                          {context.t("{amount} {currency}", {
                            amount:
                              job.worker_pays_recruitment_service_fee_amount ||
                              "-",
                            currency:
                              currencies.items[job.origin_country_currency] &&
                              currencies.items[job.origin_country_currency]
                                .code,
                          })}
                          )
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_travel_cost_for_job_interview ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays travel costs for a job interview"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay travel costs for a job interview"
                          )}
                          &nbsp;
                          {/* ({
                              context.t('{amount} {currency}', {
                                amount: job.worker_pays_travel_cost_for_job_interview_amount || '-',
                                currency: currencies.items[job.origin_country_currency] && currencies.items[job.origin_country_currency].code
                              })
                            }) */}
                        </span>
                        <span className="info-text">
                          {context.t("Based on individual cost/spending.")}
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_accommodation_costs_for_contract_signing ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays accommodation costs for contract signing"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay accommodation costs for contract signing"
                          )}
                          &nbsp; (
                          {context.t("{amount} {currency}", {
                            amount:
                              job.worker_pays_accommodation_costs_for_contract_signing_amount ||
                              "-",
                            currency:
                              currencies.items[job.origin_country_currency] &&
                              currencies.items[job.origin_country_currency]
                                .code,
                          })}
                          )
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_transport_cost_for_contract_signing ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays transportation cost to and from for contract signing"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay transportation cost to and from for contract signing"
                          )}
                          &nbsp;
                          {/* (
                            {
                              context.t('{amount} {currency}', {
                                amount: job.worker_pays_transport_cost_for_contract_signing_amount || '-',
                                currency: currencies.items[job.origin_country_currency] && currencies.items[job.origin_country_currency].code
                              })
                            }
                            ) */}
                        </span>
                        <span className="info-text">
                          {context.t("Based on individual cost/spending.")}
                        </span>
                        <span></span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.is_meal_provided_during_contract_signing ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer provides meals for contract signing"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay meals fee for contract signing"
                          )}
                          &nbsp;
                          {/* ({
                              context.t('{amount} {currency}', {
                                amount: job.is_meal_provided_during_contract_signing_amount || '-',
                                currency: currencies.items[job.origin_country_currency] && currencies.items[job.origin_country_currency].code
                              })
                            }) */}
                        </span>
                        <span className="info-text">
                          {context.t("Based on individual cost/spending.")}
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_medical_checkup_fees ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("Employer pays medical check-up fee")}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay medical check-up fee"
                          )}
                          &nbsp; (
                          {context.t("{amount} {currency}", {
                            amount:
                              job.worker_pays_medical_checkup_fees_amount ||
                              "-",
                            currency:
                              currencies.items[job.origin_country_currency] &&
                              currencies.items[job.origin_country_currency]
                                .code,
                          })}
                          )
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_passport_application_fee ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("Employer pays passport application fee")}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay passport application fee"
                          )}
                          &nbsp; (
                          {context.t("{amount} {currency}", {
                            amount:
                              job.worker_pays_passport_application_fee_amount ||
                              "-",
                            currency:
                              currencies.items[job.origin_country_currency] &&
                              currencies.items[job.origin_country_currency]
                                .code,
                          })}
                          )
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                {raCountryObj &&
                  raCountryObj.code &&
                  raCountryObj.code != "ne" && (
                    <Col xs={12}>
                      <p style={{ marginBottom: 0 }}>
                        {!job.worker_pays_owic_card_fee ? (
                          <>
                            <i className="fas fa-thin fa-angle-right" />
                            <span>
                              {context.t(
                                "Employer pays Overseas Worker Identification Card (OWIC) fee"
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            <i className="fas fa-thin fa-angle-right" />
                            <span>
                              {context.t(
                                "The workers need to pay Overseas Worker Identification Card (OWIC) fee"
                              )}
                              &nbsp; (
                              {context.t("{amount} {currency}", {
                                amount:
                                  job.worker_pays_owic_card_fee_amount || "-",
                                currency:
                                  currencies.items[
                                    job.origin_country_currency
                                  ] &&
                                  currencies.items[job.origin_country_currency]
                                    .code,
                              })}
                              )
                            </span>
                          </>
                        )}
                      </p>
                    </Col>
                  )}
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_transport_costs_to_the_border_or_airport ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays Transportation costs to the border or airport"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay Transportation costs to the border or airport"
                          )}
                          &nbsp;
                          {/* ({
                              context.t('{amount} {currency}', {
                                amount: job.worker_pays_transport_costs_to_the_border_or_airport_amount || '-',
                                currency: currencies.items[job.origin_country_currency] && currencies.items[job.origin_country_currency].code
                              })
                            }) */}
                        </span>
                        <span className="info-text">
                          {context.t("Based on individual cost/spending.")}
                        </span>
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          {job.recruitment_agency && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t(
                      "Costs and fees for recruitment in the destination country"
                    )}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_transport_cost_to_the_destination_country ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays Transportation cost from the Thai border to the workplace (or to the destination country)"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay Transportation cost from the Thai border to the workplace (or to the destination country)"
                          )}
                          &nbsp;
                          {/* ({context.t('{amount} {currency}', {
                              amount: job.worker_pays_transport_cost_to_the_destination_country_amount || '-',
                              currency: currencies.items[job.destination_country_currency] && currencies.items[job.destination_country_currency].code
                            })}) */}
                        </span>
                        <span className="info-text">
                          {context.t("Based on individual cost/spending.")}
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_visa_fees ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span> {context.t("Employer pays Visa fee")} </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("The workers need to pay Visa fee")} &nbsp;
                          (
                          {context.t("{amount} {currency}", {
                            amount: job.worker_pays_visa_fees_amount || "-",
                            currency:
                              currencies.items[
                                job.destination_country_currency
                              ] &&
                              currencies.items[job.destination_country_currency]
                                .code,
                          })}
                          )
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_work_permit_fees ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span> {context.t("Employer pays Work permit")} </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t("The workers need to pay Work permit")}
                          &nbsp; (
                          {context.t("{amount} {currency}", {
                            amount:
                              job.worker_pays_work_permit_fees_amount || "-",
                            currency:
                              currencies.items[
                                job.destination_country_currency
                              ] &&
                              currencies.items[job.destination_country_currency]
                                .code,
                          })}
                          )
                        </span>
                      </>
                    )}
                  </p>
                </Col>
                <Col xs={12}>
                  <p style={{ marginBottom: 0 }}>
                    {!job.worker_pays_medical_checkup_fee ? (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "Employer pays Medical check-up fee (if workers have to take another check up)"
                          )}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="fas fa-thin fa-angle-right" />
                        <span>
                          {context.t(
                            "The workers need to pay Medical check-up fee (if workers have to take another check up)"
                          )}
                          &nbsp; (
                          {context.t("{amount} {currency}", {
                            amount:
                              job.worker_pays_medical_checkup_fee_amount || "-",
                            currency:
                              currencies.items[
                                job.destination_country_currency
                              ] &&
                              currencies.items[job.destination_country_currency]
                                .code,
                          })}
                          )
                        </span>
                      </>
                    )}
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          {/* {
            job.recruitment_agency &&
            <>
              <Row className='mt-3'>
                <Col xs={12}>
                  <p className='job-label-header' style={{ marginBottom: 0 }}> {context.t('COVID-19 related costs and procedures')} </p>
                  < p style={{ marginBottom: 0 }}>
                    {
                      !job.worker_pays_pcr_test_fee ?
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('Employer pays for the mandatory PCR test fee prior to departure')} </span>
                        </>
                        :
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('The workers need to pay for the mandatory PCR test fee prior to departure')} &nbsp;
                            ({context.t('{amount} {currency}', {
                              amount: job.worker_pays_pcr_test_fee_amount || '-',
                              currency: currencies.items[job.ra_currency] && currencies.items[job.ra_currency].code
                            })})
                          </span>
                        </>
                    }
                  </p>
                </Col>
                <Col xs={12}>
                  < p style={{ marginBottom: 0 }}>
                    {
                      !job.worker_pays_the_pcr_test_fee_on_thailand_side ?
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('Employer pays the PCR test fee on Thailand side')} </span>
                        </>
                        :
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('The workers need to pay the PCR test fee on Thailand side')} &nbsp;
                            ({context.t('{amount} {currency}', {
                              amount: job.worker_pays_the_pcr_test_fee_on_thailand_side_amount || '-',
                              currency: currencies.items[job.currency] && currencies.items[job.currency].code
                            })})
                          </span>
                        </>
                    }
                  </p>
                </Col>
                <Col xs={12}>
                  < p style={{ marginBottom: 0 }}>
                    {
                      !job.worker_pays_quarantine_fee ?
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('Employer pays for the quarantine in the destination country')} </span>
                        </>
                        :
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('The workers need to pay for the quarantine in the destination country')} &nbsp;
                            ({context.t('{amount} {currency}', {
                              amount: job.worker_pays_quarantine_fee_amount || '-',
                              currency: currencies.items[job.currency] && currencies.items[job.currency].code
                            })})
                          </span>
                        </>
                    }
                  </p>
                </Col>
                <Col xs={12}>
                  < p style={{ marginBottom: 0 }}>
                    {
                      !job.worker_pays_covid_19_insurance_fee_in_destination_country ?
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('Employer pays for the Covid-19 insurance fee in the destination country')} </span>
                        </>
                        :
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('The workers need to pay for the Covid-19 insurance fee in the destination country')} &nbsp;
                            ({context.t('{amount} {currency}', {
                              amount: job.worker_pays_covid_19_insurance_fee_in_destination_country_amount || '-',
                              currency: currencies.items[job.currency] && currencies.items[job.currency].code
                            })})
                          </span>
                        </>
                    }
                  </p>
                </Col>
                <Col xs={12}>
                  < p style={{ marginBottom: 0 }}>
                    {
                      !job.worker_pays_covid_19_vaccination_fee ?
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('Employer pays for the Covid-19 vaccination fee')} </span>
                        </>
                        :
                        <>
                          <i className="fas fa-thin fa-angle-right" />
                          <span> {context.t('The workers need to pay for the Covid-19 vaccination fee')} &nbsp;
                            ({context.t('{amount} {currency}', {
                              amount: job.worker_pays_covid_19_vaccination_fee_amount || '-',
                              currency: currencies.items[job.currency] && currencies.items[job.currency].code
                            })})
                          </span>
                        </>
                    }
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          } */}
          {job.recruitment_agency && job.other_costs && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("Other Costs")}
                  </p>
                  <p style={{ marginBottom: 0 }}>{job.other_costs}</p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          {job.information_on_repatriation && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("Information on repatriation")}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    <span>{job.information_on_repatriation || "-"}</span>
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          <Row className="mt-3">
            <Col xs={12}>
              <p className="job-label-header" style={{ marginBottom: 0 }}>
                {context.t("Benefits")}
              </p>

              <p style={{ marginBottom: 0 }}>
                <span>{context.t("Paid annual leave per year")}:</span>
                {job.number_paid_annual_leave || "-"}
              </p>
              <p style={{ marginBottom: 0 }}>
                <span>{context.t("Paid sick leave per year")}:</span>
                {job.number_paid_sick_leave || "-"}
              </p>
              {job.ra_recruit_country != 4 && (
                <p style={{ marginBottom: 0 }}>
                  <span>{context.t("Paid business leave per year")}:</span>
                  {job.number_paid_business_leave || "-"}
                </p>
              )}
              <p style={{ marginBottom: 0 }}>
                <span>{context.t("Paid national holidays per year")}:</span>
                {job.number_paid_national_holidays || "-"}
              </p>
              <p>
                {job.is_social_security_maternity_leave_provided ? (
                  <>
                    <i
                      className="fas fa-check-circle"
                      style={{ color: "#4caf50" }}
                    ></i>
                    {raCountryObj &&
                    raCountryObj.code &&
                    raCountryObj.code === "ne" ? (
                      <span>
                        {context.t(
                          "Social security benefits are provided as required by law"
                        )}
                      </span>
                    ) : (
                      <span>
                        {context.t(
                          "Social security and maternity leave benefits are provided as required by law"
                        )}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    <FaRegStopCircle style={{ color: "brown" }} />
                    {raCountryObj &&
                    raCountryObj.code &&
                    raCountryObj.code === "ne" ? (
                      <span>
                        {context.t(
                          "Social security leave benefits are not provided"
                        )}
                      </span>
                    ) : (
                      <span>
                        {context.t(
                          "Social security and maternity leave benefits are not provided"
                        )}
                      </span>
                    )}
                  </>
                )}
              </p>
              {job.any_additional_leave && (
                <>
                  <p>
                    <span className="job-label-header">
                      {context.t("Any additional leave detail")}
                    </span>
                    <div>{job.any_additional_leave}</div>
                  </p>
                </>
              )}
              <hr />
              {Array.isArray(job.benefits) && job.benefits.length > 0 && (
                <>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("Other Benefits")}
                  </p>
                  <ul>
                    {job.benefits.map((benefit, key) => (
                      <li key={key}>
                        {jobBenefits.items[benefit] &&
                          jobBenefits.items[benefit].name}
                      </li>
                    ))}
                  </ul>
                  <hr />
                </>
              )}
            </Col>
          </Row>
          {job.translation.benefits_details && (
            <>
              <Row>
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("Benefits Details")}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.translation.benefits_details || "-"}
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          <Row>
            <Col xs={12}>
              <p className="job-label-header" style={{ marginBottom: 0 }}>
                {context.t("Other Information")}
              </p>
              <p style={{ marginBottom: 0 }}>
                {job.is_accommodation_on_site ? (
                  <>
                    <i
                      className="fas fa-check-circle"
                      style={{ color: "#4caf50" }}
                    />
                    <span>
                      {context.t("Accommodation on-site is available")}
                    </span>
                  </>
                ) : (
                  <>
                    <FaRegStopCircle style={{ color: "brown" }} />
                    <span>
                      {context.t(
                        "No information is provided on whether Accommodation on-site or not"
                      )}
                    </span>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                {job.family_can_live_together ? (
                  <>
                    <i
                      className="fas fa-check-circle"
                      style={{ color: "#4caf50" }}
                    />
                    <span>{context.t("Family members can live together")}</span>
                  </>
                ) : (
                  <>
                    <FaRegStopCircle style={{ color: "brown" }} />
                    <span>
                      {context.t(
                        "No information is provided on whether Family members can live together or not"
                      )}
                    </span>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                {job.is_rent_utilites_auto_deducted ? (
                  <>
                    <i
                      className="fas fa-check-circle"
                      style={{ color: "#4caf50" }}
                    />
                    <span>
                      {context.t(
                        "Rent & Utilites are auto deducted from the payroll"
                      )}
                    </span>
                  </>
                ) : (
                  <>
                    <FaRegStopCircle style={{ color: "brown" }} />
                    <span>
                      {context.t(
                        "No information is provided for a deduction on Rent & Utilities"
                      )}
                    </span>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                {job.safe_grievance_mechanism ? (
                  <>
                    <i
                      className="fas fa-check-circle"
                      style={{ color: "#4caf50" }}
                    />
                    <span>
                      {context.t("Safe functioning grievance mechanism")}
                    </span>
                  </>
                ) : (
                  <>
                    <FaRegStopCircle style={{ color: "brown" }} />
                    <span>
                      {context.t(
                        "No Information is provided  for grievance mechanism"
                      )}
                    </span>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p style={{ marginBottom: 0 }}>
                {job.probation_period ? (
                  <>
                    <i
                      className="fas fa-check-circle"
                      style={{ color: "#4caf50" }}
                    />
                    <span> {context.t("Probation period")} </span>
                  </>
                ) : (
                  <>
                    <FaRegStopCircle style={{ color: "brown" }} />
                    <span>
                      {context.t(
                        "No Information is provided for probation period"
                      )}
                    </span>
                  </>
                )}
              </p>
            </Col>
          </Row>
          <hr />

          {job.translation.accommodation_details && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("Accommodation Details")}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.translation.accommodation_details || "-"}
                  </p>
                </Col>
              </Row>
              <hr />
            </>
          )}
          {job.translation.safe_grievance_mechanism_details && (
            <Row className="mt-3">
              <Col xs={12}>
                <p className="job-label-header" style={{ marginBottom: 0 }}>
                  {context.t("Grievance Mechanism Details")}
                </p>
                <p style={{ marginBottom: 0 }}>
                  {job.translation.safe_grievance_mechanism_details}
                </p>
              </Col>
            </Row>
          )}

          {job.translation.probation_period_details && (
            <Row className="mt-3">
              <Col xs={12}>
                <p className="job-label-header" style={{ marginBottom: 0 }}>
                  {context.t("Probation Period Details")}
                </p>
                <p style={{ marginBottom: 0 }}>
                  {job.translation.probation_period_details}
                </p>
              </Col>
            </Row>
          )}
          {job.translation.is_rent_utilites_auto_deducted_details && (
            <Row className="mt-3">
              <Col xs={12}>
                <p className="job-label-header" style={{ marginBottom: 0 }}>
                  {context.t("Rent/utilites deduction details")}
                </p>
                <p style={{ marginBottom: 0 }}>
                  {job.translation.is_rent_utilites_auto_deducted_details}
                </p>
              </Col>
            </Row>
          )}
          {!job.recruitment_agency && (
            <>
              <Row className="mt-3">
                <Col xs={12}>
                  <p className="job-label-header" style={{ marginBottom: 0 }}>
                    {context.t("Contact Person")}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.translation.contact_name}
                  </p>
                  <p style={{ marginBottom: 0 }}>
                    {job.translation.contact_job_title &&
                      contactJobTitles[job.translation.contact_job_title]}
                  </p>
                  <a
                    href={`tel: ${job.translation.contact_phone_number}`}
                    style={{ display: "block" }}
                  >
                    {job.translation.contact_phone_number}
                  </a>
                </Col>
              </Row>
              <hr />
            </>
          )}

          {job.recruitment_agency ? (
            <Link
              to={`/recruitment-agencies/${job.recruitment_agency}?prev=/jobs/${job.id}`}
            >
              <div className="mt-2 recruiter-information">
                <Row>
                  <Col xs={12}>
                    <p className="job-label-header" style={{ marginBottom: 0 }}>
                      {context.t("Recruitment Agency Information")}
                    </p>
                    <hr />
                    <Image
                      src={
                        job.business_logo
                          ? job.business_logo
                          : "/static/media/ImagePlaceHolder.png"
                      }
                      crossOrigin="anonymous"
                      className="job-emp-ra-profile mt-2"
                      onError={addDefaultImageSrc}
                    />
                    &nbsp;&nbsp;
                    {job.recruitment_agency_name}
                  </Col>
                </Row>
              </div>
            </Link>
          ) : (
            ""
          )}

          <Row className="mt-3" style={{ textAlign: "center" }}>
            <ShareButtons title={job.job_title ? job.job_title : ""} />
          </Row>

          <hr />

          {jobStatusRenderer(job)}

          {application && application.status ? (
            <React.Fragment>
              <Row className="mt-3" style={{ textAlign: "center" }}>
                {/* STATUS Alert */}
                <Col xs={12} className="mt-2">
                  <Alert
                    className="hover-effect"
                    variant={
                      application &&
                      ["VIEWED", "APPLIED"].includes(application.status)
                        ? "info"
                        : ["OFFERED", "ACCEPTED", "CONTRACT_SIGNED"].includes(
                            application.status
                          )
                        ? "success"
                        : [
                            "REJECTED_BY_APPLICANT",
                            "REJECTED_BY_BUSINESS",
                          ].includes(application.status)
                        ? "danger"
                        : "warning"
                    }
                  >
                    <strong>
                      {application &&
                        jobApplicationStatuses[application.status]}
                    </strong>
                  </Alert>
                </Col>

                {/* STATUS DETAILS getNeededProfileDocuments*/}
                {application.documents_requested_at &&
                  missingDocuments.length > 0 && (
                    <Col xs={12} className="mt-2">
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "0.5rem",
                          border: "1px solid grey",
                        }}
                      >
                        <p>
                          {context.t(
                            "This employer requested to view and check your documents {timeAgo}. They will be allowed to view them only, not download.",
                            {
                              timeAgo: (
                                <b>
                                  {moment(
                                    application.documents_requested_at
                                  ).fromNow()}
                                </b>
                              ),
                            }
                          )}
                        </p>
                        <p>
                          {context.t(
                            "However, you must first have uploaded the required documents on your resume page:"
                          )}
                        </p>
                        <ul>
                          {missingDocuments.map((documentName, key) => (
                            <li key={key}>{documentName}</li>
                          ))}
                        </ul>
                        <Button
                          style={{ width: "100%" }}
                          onClick={() =>
                            history.push(
                              `/resume-edit?prev=${location.pathname}`
                            )
                          }
                        >
                          {context.t("Upload missing documents")}
                        </Button>
                      </div>
                    </Col>
                  )}
              </Row>
              {/* STATUS ACTION BUTTONS */}
              {application.status == "OFFERED" && (
                <Row>
                  <Col xs={12} className="mt-2" md={6} lg={6}>
                    {showPopupWindow}
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={popUpConfirmation}
                      rootClose={true}
                    >
                      <Button
                        className="btn-block btn-md button-modified"
                        variant="danger"
                        disabled={acceptingJobOffer || rejectingJobOffer}
                      >
                        <strong>
                          {rejectingJobOffer
                            ? context.t("Loading…")
                            : context.t("Reject")}
                        </strong>
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    <Button
                      className="btn-block btn-md button-modified"
                      style={{ backgroundColor: "#28a745" }}
                      onClick={handleAcceptJobOffer}
                      disabled={acceptingJobOffer || rejectingJobOffer}
                    >
                      <strong>
                        {acceptingJobOffer
                          ? context.t("Loading…")
                          : context.t("Accept")}
                      </strong>
                    </Button>
                  </Col>
                </Row>
              )}
            </React.Fragment>
          ) : (
            applyButton(profile, job)
          )}
          <Overlay show={showPopover} target={popoverTarget.current}>
            <Popover>
              <Popover.Title as="h4">
                {context.t("Missing resume")}
              </Popover.Title>
              <Popover.Content>
                {context.t("{clickHereLink} to create your GD resume", {
                  clickHereLink: (
                    <a href="#" onClick={handleCreateResume}>
                      {context.t("Click here")}
                    </a>
                  ),
                })}
              </Popover.Content>
            </Popover>
          </Overlay>
        </Container>
      )}
    </BaseView>
  );
}

JobsDetailedViewV2.contextTypes = {
  t: PropTypes.func.isRequired,
};
