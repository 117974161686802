import axios from 'axios';

import { addProfileVote } from './ProfileActions';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const POLLS_QUESTIONS_GET_START = 'POLLS_QUESTIONS_GET_START';
export const POLLS_QUESTIONS_GET_SUCCESS = 'POLLS_QUESTIONS_GET_SUCCESS';
export const POLLS_QUESTIONS_GET_FAIL = 'POLLS_QUESTIONS_GET_FAIL';

export const POLLS_VOTE_POST_START = 'POLLS_VOTE_POST_START';
export const POLLS_VOTE_POST_SUCCESS = 'POLLS_VOTE_POST_SUCCESS';
export const POLLS_VOTE_POST_FAIL = 'POLLS_VOTE_POST_FAIL';


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getPollsQuestionsStart = () => {
  return {
    type: POLLS_QUESTIONS_GET_START,
  }
}

export const getPollsQuestionsSuccess = (questions) => {
  return {
    type: POLLS_QUESTIONS_GET_SUCCESS,
    questions: questions,
    latest: questions[0]
  }
}

export const getPollsQuestionsFail = error => {
  return {
    type: POLLS_QUESTIONS_GET_FAIL,
    error: error
  }
}

export const getPollsQuestions = () => {
  return (dispatch, getState) => {
    dispatch(getPollsQuestionsStart());
    const language = getState().i18nState.lang
    return axios.get(`${process.env.REACT_APP_API_URL}/polls/questions?lang=${language}`)
    .then(res => {
      dispatch(getPollsQuestionsSuccess(res.data));
      return getState().PollsState.items
    })
    .catch(err => {
      dispatch(getPollsQuestionsFail(err))
      throw err
    })
  }
}

// POST:
export const postPollsVoteStart = () => {
  return {
    type: POLLS_VOTE_POST_START,
  }
}

export const postPollsVoteSuccess = (vote) => {
  return {
    type: POLLS_VOTE_POST_SUCCESS,
    vote: vote
  }
}

export const postPollsVoteFail = error => {
  return {
    type: POLLS_QUESTIONS_GET_FAIL,
  }
}

export const postPollsVote = (payload) => {
  return (dispatch, getState) => {
    dispatch(postPollsVoteStart());
    dispatch(addProfileVote(payload))
    return axios.post(`${process.env.REACT_APP_API_URL}/polls/vote`, payload)
    .then(res => {
      dispatch(postPollsVoteSuccess(payload));
    })
    .catch(err => {
      dispatch(postPollsVoteFail(err))
      throw err
    })
  }
}
