import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from 'react-bootstrap';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import BaseView from '../BaseView';
import JobListItem from '../../components/JobListItem';

import { getJobs } from '../../common/redux/actions/JobsActions';
import FilterResultsNotFound from "../../components/FilterResultsNotFound";

const cache = new CellMeasurerCache({
  minHeight: 112,
  fixedWidth: true
});

export default function JobApplicationsView(props, context) {
  const dispatch = useDispatch()

  const jobs = useSelector(state => state.JobsState)
  const profile = useSelector(state => state.ProfileState.data)
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);



  // references
  const listRef = useRef()

  const [jobsFiltered, setJobsFiltered] = useState([])
  let currentCountry = localStorage.getItem("currentCountry")

  // Update the screen height state when the window is resized
  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    !jobs.loading && dispatch(getJobs(currentCountry))
      // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  useEffect(() => {
    if (profile.applications) {
      let appliedJobs = profile.applications.map(app => app.job)
      let jobsFiltered = jobs.items.filter(job => appliedJobs.includes(job.id))
      setJobsFiltered(jobsFiltered)
    }
  }, [profile, jobs])

  useEffect(() => {
    cache.clearAll()
    listRef.current && listRef.current.forceUpdateGrid()
  }, [jobsFiltered])

  const rowRenderer = ({ index, isScrolling, key, parent, style }) => {
    return (
      <CellMeasurer
        cache={cache}
        key={key}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div ref={registerChild} style={style}>
            <JobListItem job={jobsFiltered[index]} scrollToIndex={index} fromApplication />
          </div>
        )}
      </CellMeasurer>
    )
  }

  return (
    <BaseView title={context.t('Job Applications')} backurl='/marketplace'>
      <hr className='afterTopNavigationBar'></hr>
      {jobs.fetching ?
        <div style={{ display: 'flex', height: 'calc(100% - 118px)' }}>
          <img src={'/static/media/spinner.png'} alt='' className='LoadingSpinner' />
        </div>
        :
        <Container>
          <Row>
            {jobsFiltered.length == 0 && !jobs.fetching &&
              <FilterResultsNotFound text={context.t('Sorry, no job applications found!')} />
            }
          </Row>
          <Row>
            <Col>
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    className='withoutScrollBar'
                    ref={listRef}
                    height={screenHeight}
                    width={width}
                    rowCount={jobsFiltered.length}
                    overscanRowCount={10}
                    rowHeight={cache.rowHeight}
                    rowRenderer={rowRenderer}
                    scrollToAlignment="start"
                    deferredMeasurementCache={cache}
                  />
                )}
              </AutoSizer>
            </Col>
          </Row>
        </Container>
      }
    </BaseView>
  )
}

JobApplicationsView.contextTypes = {
  t: PropTypes.func.isRequired
}
