import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { Container, Row, Col, Form, Button, Image, Spinner, Card } from 'react-bootstrap';

import BaseView from '../BaseView';
import Divider from '../../components/Divider';
import CitySelect from '../../components/selects/CitySelect';
import CountrySelect from '../../components/selects/CountrySelect';
import EducationLevelSelect from '../../components/selects/EducationLevelSelect';
import GenderSelect from '../../components/selects/GenderSelect';
import CountryCodesSelect from "../../components/selects/CountryCodesSelect";
import IndustrySelect from '../../components/selects/IndustrySelect';
import StateSelect from '../../components/selects/StateSelect';
import { dayRegex, monthRegex, birthYearRegex, passportYearRegex, workerPhoneRegex, lineRegex, viberRegex, certificateOfIdentityYearRegex } from '../../common/utilities/regex';
import { daysInMonth } from '../../common/utilities/utilities'
import { CountryCodes } from "../../common/utilities/choices";
import { createResume, updateResume, updateResumePhoto} from '../../common/redux/actions/ProfileActions'

import { addDefaultImageSrc } from '../../common/utilities/utilities';
import { updateUserDocuments } from "../../common/redux/actions/ProfileActions";
import JobseekersCreateResumeHelpModal from './JobseekersCreateResumeHelpModal';
import 'react-medium-image-zoom/dist/styles.css'
import WorkingExperienceRemoveConfirmationModal from "./WorkingExperienceRemoveConfirmationModal";
import RemoveDocumentConfirmationModal from './RemoveDocumentConfirmationModal';
import ReadLevelSelect from "../../components/selects/ReadLevelSelect";
import SpeakLevelSelect from "../../components/selects/SpeakLevelSelect";
import LanguageSkillSelect from "../../components/selects/LanguageSkillSelect";
import { getCountries } from "../../common/redux/actions/CountriesActions";

export default function JobseekersEditResumeView(props, context) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const language = useSelector(state => state.i18nState.lang)
  const languages = useSelector(state => state.LanguagesState)
  const profile = useSelector(state => state.ProfileState.data)
  const resume = useSelector(state => state.ProfileState.resume)
  const countries = useSelector(state => state.CountriesState)

  const educationLevels = useSelector(state => state.EducationLevelsState.items)
  const [validated, setValidated] = useState(false);
  const [workersInThai, setDocInputFields] = useState(null);
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(null)
  const [documentRemoveConfirmation, setDocumentRemoveConfrmation] = useState(null)
  const [payload, setPayload] = useState({
    jobseeker: (profile) ? profile.id : null,
    full_name: profile.resume && profile.resume.full_name,
    gender: profile.resume && profile.resume.gender || 'M',
    date_of_birth: profile.resume && profile.resume.date_of_birth,
    phone_number: profile.resume &&  profile.resume.phone_number || '',
    country_code: profile.resume && profile.resume.country_code || '',
    emergency_phone_number: profile.resume && profile.resume.emergency_phone_number || '',
    line_id_number: profile.resume && profile.resume.line_id_number || '',
    viber_id_number: profile.resume && profile.resume.viber_id_number || '',
    email: profile.resume && profile.resume.email || '',
    country: profile.resume && profile.resume.country,
    state: profile.resume && profile.resume.state,
    city: profile.resume && profile.resume.city,
    address_details: profile.resume && profile.resume.address_details,
    national_id_number: profile.resume && profile.resume.national_id_number || '',
    passport_number: profile.resume && profile.resume.passport_number || '',
    certificate_of_identity_number: profile.resume && profile.resume.certificate_of_identity_number || '',
    passport_expire_date: profile.resume && profile.resume.passport_expire_date || null,
    certificate_of_identity_expire_date: profile.resume && profile.resume.certificate_of_identity_expire_date || null,
    pinkcard_id_number: profile.resume && profile.resume.pinkcard_id_number || '',
    have_medical_operation_recently: profile.resume && profile.resume.have_medical_operation_recently || false,
    education: profile.resume && profile.resume.education,
    major: profile.resume && profile.resume.major,
    languages: profile.resume && profile.resume.languages || {},
    date_of_birth_day: `${profile.resume && new Date(profile.resume.date_of_birth).getDate() || ''}`,
    date_of_birth_month: `${profile.resume && new Date(profile.resume.date_of_birth).getMonth() + 1 || ''}`,
    date_of_birth_year: `${profile.resume && new Date(profile.resume.date_of_birth).getFullYear() || ''}`,
    passport_expire_day: `${profile.resume && profile.resume.passport_expire_date && new Date(profile.resume.passport_expire_date).getDate() || ''}`,
    passport_expire_month: `${profile.resume && profile.resume.passport_expire_date && new Date(profile.resume.passport_expire_date).getMonth() + 1 || ''}`,
    passport_expire_year: `${profile.resume && profile.resume.passport_expire_date && new Date(profile.resume.passport_expire_date).getFullYear() || ''}`,
    certificate_of_identity_expire_month: `${profile.resume && profile.resume.certificate_of_identity_expire_date && new Date(profile.resume.certificate_of_identity_expire_date).getMonth() + 1 || ''}`,
    certificate_of_identity_expire_year: `${profile.resume && profile.resume.certificate_of_identity_expire_date && new Date(profile.resume.certificate_of_identity_expire_date).getFullYear() || ''}`,
    working_experience: profile.resume && profile.resume.working_experience || [],
    languages: profile.resume && listObjToArr(profile.resume.languages) || []
  })

  const [photo, setPhoto] = useState({
    url: profile.resume && profile.resume.photo,
    file: null
  })

  const [documents, setDocuments] = useState({
    passport: {
      url: profile.passport,
    },
    idcard: {
      url: profile.idcard,
    },
    birthcertificate: {
      url: profile.birthcertificate,
    },
    familyrecordbook: {
      url: profile.familyrecordbook,
    },
    residencebook: {
      url: profile.residencebook,
    },
    householdlist: {
      url: profile.householdlist,
    },
    nrc: {
      url: profile.nrc,
    },
    labourcard: {
      url: profile.labourcard,
    },
    covidvaccinecertificate: {
      url: profile.covidvaccinecertificate,
    },
    visa: {
      url: profile.visa,
    },
    workpermit: {
      url: profile.workpermit,
    },
    pinkcard: {
      url: profile.pinkcard,
    },
    cancellationletter: {
      url: profile.cancellationletter,
    },
  })
  const [currentCountry, setCurrentCountry] = useState(null);
  const [countryObj, setCountryObj] = useState({});

  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
  }, []);

  useEffect(() => {
    if(countries.fetched) {
      setCountryObj(countries.items[currentCountry])
    }
  }, [currentCountry])

  // country to check (Khmer & Myanmar)
  const checkCountry = [2, 3]

  const options = CountryCodes(context)

  const documentInputFieldsForWorkerInNepal = payload => {
    return (<>
      <Divider text={context.t('Documents')} style={{ fontWeight: 500 }} />
      <Image
        src="/static/media/passport_nepal.png"
        crossOrigin='anonymous'
        style={{
          marginTop: 16,
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Row style={{ marginTop: '0.5rem' }}>
        <Form.Group as={Col} controlId="passportNumber">
          <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Passport Number')} ({context.t('optional')})</Form.Label>
          <Form.Control
            name='passport_number'
            placeholder={context.t('Passport number')}
            defaultValue={payload.passport_number}
            onChange={handleInputChange}
            style={{ height: 30 }}
          />
          <Form.Text muted>
            {context.t('Enter your Passport Number as shown in the picture')}
          </Form.Text>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Row>
            <Col>
              <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Valid till')}</Form.Label>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col style={{ display: 'inline-flex' }}>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_day}
                  onChange={handlePassportExireDayChange}
                  style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                  min={1}
                  max={31}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Day')}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_month}
                  onChange={handlePassportExireMonthChange}
                  style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                  min={1}
                  max={12}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Month')}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_year}
                  onChange={handlePassportExireYearChange}
                  min={2020}
                  max={2060}
                  style={{ height: 30, maxWidth: 57 }}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Year')}
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form.Group>
      </Form.Row>
      <div style={{ marginTop: '-2rem', marginBottom: '0.5rem' }}>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_day && !payload.passport_expire_day.match(dayRegex) && 'block' }}
        >
          {context.t('Day: please enter the number between 1 and 31')}
        </Form.Control.Feedback>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_month && !payload.passport_expire_month.match(monthRegex) && 'block' }}
        >
          {context.t('Month: please enter the number between 1 and 12')}
        </Form.Control.Feedback>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_year && !payload.passport_expire_year.match(passportYearRegex) && 'block' }}
        >
          {context.t('Year: please enter the number between 2020 and 2040')}
        </Form.Control.Feedback>
      </div>
    </>)
  }

  const documentInputFieldsForWorkerInKhmer = payload => {
    return (<>
      <Divider text={context.t('Documents')} style={{ fontWeight: 500 }} />

      <Image
        src="/static/media/national_id_khmer.jpg"
        crossOrigin='anonymous'
        style={{
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Group style={{ marginTop: '0.5rem' }}>
        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('National ID Number')} ({context.t('required')})</Form.Label>
        <Form.Control
          name='national_id_number'
          placeholder={context.t('Enter your National Identification Card number')}
          defaultValue={payload.national_id_number}
          onChange={handleInputChange}
          style={{ height: 30 }}
          required
        />
        <Form.Text muted>
          {context.t('Enter your National Identification Card Number as shown in the picture')}
        </Form.Text>
      </Form.Group>

      <Image
        src="/static/media/passport_khmer.jpg"
        crossOrigin='anonymous'
        style={{
          marginTop: 16,
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Row style={{ marginTop: '0.5rem' }}>
        <Form.Group as={Col} controlId="passportNumber">
          <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Passport Number')} ({context.t('optional')})</Form.Label>
          <Form.Control
            name='passport_number'
            placeholder={context.t('Passport number')}
            defaultValue={payload.passport_number}
            onChange={handleInputChange}
            style={{ height: 30 }}
          />
          <Form.Text muted>
            {context.t('Enter your Passport Number as shown in the picture')}
          </Form.Text>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Row>
            <Col>
              <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Valid till')}</Form.Label>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col style={{ display: 'inline-flex' }}>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_day}
                  onChange={handlePassportExireDayChange}
                  style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                  min={1}
                  max={31}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Day')}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_month}
                  onChange={handlePassportExireMonthChange}
                  style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                  min={1}
                  max={12}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Month')}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_year}
                  onChange={handlePassportExireYearChange}
                  min={2020}
                  max={2060}
                  style={{ height: 30, maxWidth: 57 }}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Year')}
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form.Group>
      </Form.Row>
      <div style={{ marginTop: '-2rem', marginBottom: '0.5rem' }}>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_day && !payload.passport_expire_day.match(dayRegex) && 'block' }}
        >
          {context.t('Day: please enter the number between 1 and 31')}
        </Form.Control.Feedback>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_month && !payload.passport_expire_month.match(monthRegex) && 'block' }}
        >
          {context.t('Month: please enter the number between 1 and 12')}
        </Form.Control.Feedback>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_year && !payload.passport_expire_year.match(passportYearRegex) && 'block' }}
        >
          {context.t('Year: please enter the number between 2020 and 2040')}
        </Form.Control.Feedback>
      </div>
    </>)
  }

  const documentInputFieldsForWorkerInMyanmar = payload => {
    return (<>
      <Divider text={context.t('Documents')} style={{ fontWeight: 500 }} />

      <Image
        src="/static/media/nrc_myanmar.jpg"
        crossOrigin='anonymous'
        style={{
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Group style={{ marginTop: '0.5rem' }}>
        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('National ID Number')} ({context.t('required')})</Form.Label>
        <Form.Control
          name='national_id_number'
          placeholder={context.t('Enter your NRC number')}
          defaultValue={payload.national_id_number}
          onChange={handleInputChange}
          style={{ height: 30 }}
          required
        />
        <Form.Text muted>
          {context.t('Enter your NRC Number as shown in the picture (eg: 12/TaKaNa(N)123456)')}
        </Form.Text>
      </Form.Group>

      <Image
        src="/static/media/passport_myanmar.jpg"
        crossOrigin='anonymous'
        style={{
          marginTop: 16,
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Row style={{ marginTop: '0.5rem' }}>
        <Form.Group as={Col} controlId="passportNumber">
          <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Passport Number')} ({context.t('optional')})</Form.Label>
          <Form.Control
            name='passport_number'
            placeholder={context.t('Passport number')}
            defaultValue={payload.passport_number}
            onChange={handleInputChange}
            style={{ height: 30 }}
          />
          <Form.Text muted>
            {context.t('Enter your Passport Number as shown in the picture (eg: MD123456)')}
          </Form.Text>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Row>
            <Col>
              <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Valid till')}</Form.Label>
            </Col>
          </Form.Row>

          <Form.Row>
            <Form.Group>
              <Form.Control
                type="number"
                pattern="[0-9]*"
                value={payload.passport_expire_day}
                onChange={handlePassportExireDayChange}
                style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                min={1}
                max={31}
                required={payload.passport_number}
              />
              <Form.Text className="text-muted">
                {context.t('Day')}
              </Form.Text>
            </Form.Group>
            <Col style={{ display: 'inline-flex' }}>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_month}
                  onChange={handlePassportExireMonthChange}
                  style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                  min={1}
                  max={12}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Month')}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_year}
                  onChange={handlePassportExireYearChange}
                  min={2020}
                  max={2060}
                  style={{ height: 30, maxWidth: 57 }}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Year')}
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form.Group>
      </Form.Row>
      <div style={{ marginTop: '-2rem', marginBottom: '0.5rem' }}>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_day && !payload.passport_expire_day.match(dayRegex) && 'block' }}
        >
          {context.t('Day: please enter the number between 1 and 31')}
        </Form.Control.Feedback>

        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_month && !payload.passport_expire_month.match(monthRegex) && 'block' }}
        >
          {context.t('Month: please enter the number between 1 and 12')}
        </Form.Control.Feedback>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_year && !payload.passport_expire_year.match(passportYearRegex) && 'block' }}
        >
          {context.t('Year: please enter the number between 2020 and 2040')}
        </Form.Control.Feedback>
      </div>
    </>)
  }

  const documentInputFieldsForWorkerInThai = payload => {
    return (<>
      <Divider text={context.t('Documents')} style={{ fontWeight: 500 }} />

      <Image
        src={getNationalIdPictureURL()}
        crossOrigin='anonymous'
        style={{
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Group style={{ marginTop: '0.5rem' }}>
        <Form.Label style={{ fontSize: 'smaller' }}>{context.t('National ID Number')} ({context.t('optional')})</Form.Label>
        <Form.Control
          name='national_id_number'
          placeholder={context.t('Enter your NRC number')}
          defaultValue={payload.national_id_number}
          onChange={handleInputChange}
          style={{ height: 30 }}
        />
        <Form.Text muted>
          {context.t('Enter your NRC Number as shown in the picture (eg: 12/TaKaNa(N)123456)')}
        </Form.Text>
      </Form.Group>

      <Image
        src={getPassportPictureURL()}
        crossOrigin='anonymous'
        style={{
          marginTop: 16,
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Row style={{ marginTop: '0.5rem' }}>
        <Form.Group as={Col} controlId="passportNumber">
          <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Passport Number')} ({context.t('optional')})</Form.Label>
          <Form.Control
            name='passport_number'
            placeholder={context.t('Passport number')}
            defaultValue={payload.passport_number}
            onChange={handleInputChange}
            style={{ height: 30 }}
          />
          <Form.Text muted>
            {context.t('Enter your Passport Number as shown in the picture (eg: MD123456)')}
          </Form.Text>
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Row>
            <Col>
              <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Valid till')}</Form.Label>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col style={{ display: 'inline-flex' }}>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_day}
                  onChange={handlePassportExireDayChange}
                  style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                  min={1}
                  max={31}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Day')}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_month}
                  onChange={handlePassportExireMonthChange}
                  style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                  min={1}
                  max={12}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Month')}
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="number"
                  pattern="[0-9]*"
                  value={payload.passport_expire_year}
                  onChange={handlePassportExireYearChange}
                  min={2020}
                  max={2060}
                  style={{ height: 30, maxWidth: 57 }}
                  required={payload.passport_number}
                />
                <Form.Text className="text-muted">
                  {context.t('Year')}
                </Form.Text>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form.Group>
      </Form.Row>
      <div style={{ marginTop: '-2rem', marginBottom: '0.5rem' }}>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && payload.passport_expire_day && !payload.passport_expire_day.match(dayRegex) && 'block' }}
        >
          {context.t('Day: please enter the number between 1 and 31')}
        </Form.Control.Feedback>

        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && !payload.passport_expire_month.match(monthRegex) && 'block' }}
        >
          {context.t('Month: please enter the number between 1 and 12')}
        </Form.Control.Feedback>
        <Form.Control.Feedback
          type="invalid"
          style={{ display: validated && !payload.passport_expire_year.match(passportYearRegex) && 'block' }}
        >
          {context.t('Year: please enter the number between 2020 and 2040')}
        </Form.Control.Feedback>
      </div>

      {language !== 'kh' &&
        // not aplicable for Khmer users, just hide it
        <>
          <Image
            src={'/static/media/certificate_of_identity_myanmar.jpg'}
            crossOrigin='anonymous'
            style={{
              marginTop: 16,
              width: '100%',
              maxWidth: '400px',
              border: '1px solid grey'
            }}
          />

          <Form.Row style={{ marginTop: '0.5rem' }}>
            <Form.Group as={Col} controlId="certificateOfIdentity">
              <Form.Label style={{ fontSize: 'smaller' }}>{context.t('CI Number')} ({context.t('optional')})</Form.Label>
              <Form.Control
                name='certificate_of_identity_number'
                placeholder={context.t('CI number')}
                defaultValue={payload.certificate_of_identity_number}
                onChange={handleInputChange}
                style={{ height: 30 }}
              />
              <Form.Text muted>
                {context.t('Enter your CI Number')}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Row>
                <Col>
                  <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Valid till')}</Form.Label>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col style={{ display: 'inline-flex' }}>
                  <Form.Group>
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      value={payload.certificate_of_identity_expire_month}
                      onChange={handleCIExireMonthChange}
                      style={{ height: 30, maxWidth: 39, marginRight: 16 }}
                      min={1}
                      max={12}
                      required={payload.certificate_of_identity_number}
                    />
                    <Form.Text className="text-muted">
                      {context.t('Month')}
                    </Form.Text>
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      type="number"
                      pattern="[0-9]*"
                      value={payload.certificate_of_identity_expire_year}
                      onChange={handleCIExireYearChange}
                      min={2020}
                      max={2060}
                      style={{ height: 30, maxWidth: 57 }}
                      required={payload.certificate_of_identity_number}
                    />
                    <Form.Text className="text-muted">
                      {context.t('Year')}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form.Group>
          </Form.Row>

          <div style={{ marginTop: '-2rem', marginBottom: '0.5rem' }}>
            <Form.Control.Feedback
              type="invalid"
              style={{ display: validated && payload.certificate_of_identity_expire_month && !payload.certificate_of_identity_expire_month.match(monthRegex) && 'block' }}
            >
              {context.t('Month: please enter the number between 1 and 12')}
            </Form.Control.Feedback>
            <Form.Control.Feedback
              type="invalid"
              style={{ display: validated && payload.certificate_of_identity_expire_year && !payload.certificate_of_identity_expire_year.match(certificateOfIdentityYearRegex) && 'block' }}
            >
              {context.t('Year: please enter the number between 2020 and 2040')}
            </Form.Control.Feedback>
          </div>
        </>
      }

      <Image
        src={'/static/media/pink_card.jpg'}
        crossOrigin='anonymous'
        style={{
          marginTop: 16,
          width: '100%',
          maxWidth: '400px',
          border: '1px solid grey'
        }}
      />
      <Form.Group controlId="pinkIdNumber" style={{ marginTop: '0.5rem' }}>
        <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Pink Card Number')} ({context.t('optional')})</Form.Label>
        <Form.Control
          name='pinkcard_id_number'
          placeholder={context.t('Pink Card Number')}
          defaultValue={payload.pinkcard_id_number}
          onChange={handleInputChange}
          style={{ height: 30 }}
        />
        <Form.Text muted>
          {context.t('Enter your Pink Card Number as shown in the picture, the pink card number mush have 13 digits. (eg: 00 1234 567890 1)')}
        </Form.Text>
      </Form.Group>
    </>)
  }

  const otherDocs = (payload) => {
    return (<>
      <Divider
        text={context.t('Uploaded Documents')}
        style={{ fontWeight: 500 }}
        className='mt-4'
      />

      <Form.Group controlId="documentsgroup">
        <Row>
          <Col>
            <label htmlFor='passport-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Passport')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.passport.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.passport.url &&
                  <Button
                    name='passport'
                    variant='danger'
                    size='sm'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove Passport')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='passport-input'
              type="file"
              name="passport"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='visa-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Visa')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.visa.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.visa.url &&
                  <Button
                    name='visa'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove visa')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='visa-input'
              type="file"
              name="visa"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='workpermit-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Work Permit')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.workpermit.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.workpermit.url &&
                  <Button
                    name='workpermit'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove work permit')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='workpermit-input'
              type="file"
              name="workpermit"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='pinkcard-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Pink Card')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.pinkcard.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.pinkcard.url &&
                  <Button
                    name='pinkcard'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove pink card')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='pinkcard-input'
              type="file"
              name="pinkcard"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='cancellationletter-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Cancellation letter')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.cancellationletter.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.cancellationletter.url &&
                  <Button
                    name='cancellationletter'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove canc. letter')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='cancellationletter-input'
              type="file"
              name="cancellationletter"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>)
  }

  const myanmarDocs = () => {
    return (<>
      <Divider
        text={context.t('Uploaded Documents for Myanmar')}
        style={{ fontWeight: 500 }}
        className='mt-4'
      />

      <Form.Group controlId="documentsgroup">
        <Row>
          <Col>
            <label htmlFor='nrc-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('NRC')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.nrc.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.nrc.url &&
                  <Button
                    name='nrc'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove NRC')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='nrc-input'
              type="file"
              name="nrc"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='householdlist-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Household List')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.householdlist.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.householdlist.url &&
                  <Button
                    name='householdlist'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove Household List')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='householdlist-input'
              type="file"
              name="householdlist"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='passport-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Passport (PJ)')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.passport.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.passport.url &&
                  <Button
                    name='passport'
                    variant='danger'
                    size='sm'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove Passport')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='passport-input'
              type="file"
              name="passport"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='labourcard-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Labour Card')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.labourcard.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.labourcard.url &&
                  <Button
                    name='labourcard'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove Labour Card')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='labourcard-input'
              type="file"
              name="labourcard"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
          <Col>
            <label htmlFor='covidvaccinecertificate-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Covid Vaccine Certificate')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.covidvaccinecertificate.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.covidvaccinecertificate.url &&
                  <Button
                    name='covidvaccinecertificate'
                    variant='danger'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    size='sm'
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove Covid Vaccine Certificate')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='covidvaccinecertificate-input'
              type="file"
              name="covidvaccinecertificate"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>)
  }

  const nepalDocs = () => {
    return (<>
      <Divider
        text={context.t('Uploaded Documents for Nepal')}
        style={{ fontWeight: 500 }}
        className='mt-4'
      />

      <Form.Group controlId="documentsgroup">
        <Row>
          <Col>
            <label htmlFor='passport-input'>
              <Card className='mt-4'>
                <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                  <Form.Label style={{ margin: 0 }}>
                    <h6> {context.t('Passport')} </h6>
                  </Form.Label>
                </Card.Header>
                <Card.Body>
                  <Image
                    src={documents.passport.url || '/static/media/ImagePlaceholder.png'}
                    crossOrigin='anonymous'
                    style={{
                      width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                    }}
                    onError={addDefaultImageSrc}
                  />
                </Card.Body>
                {documents.passport.url &&
                  <Button
                    name='passport'
                    variant='danger'
                    size='sm'
                    style={{ position: 'absolute', bottom: 0, width: '100%' }}
                    onClick={handleRemoveDocumentConfirmation}
                  >
                    {context.t('Remove Passport')}
                  </Button>
                }
              </Card>
            </label>
            <input
              id='passport-input'
              type="file"
              name="passport"
              onChange={documentUploadHandler}
              style={{ display: 'none' }}
            />
          </Col>
        </Row>
      </Form.Group>
    </>)
  }

  const khmerDocs = () => {
    return (
      <>
        <Divider
          text={context.t('Uploaded Documents for Khmer')}
          style={{ fontWeight: 500 }}
          className='mt-4'
        />

        <Form.Group controlId="documentsgroup">
          <Row>
            <Col>
              <label htmlFor='passport-input'>
                <Card className='mt-4'>
                  <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                    <Form.Label style={{ margin: 0 }}>
                      <h6> {context.t('Passport')} </h6>
                    </Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={documents.passport.url || '/static/media/ImagePlaceholder.png'}
                      crossOrigin='anonymous'
                      style={{
                        width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                      }}
                      onError={addDefaultImageSrc}
                    />
                  </Card.Body>
                  {documents.passport.url &&
                    <Button
                      name='passport'
                      variant='danger'
                      size='sm'
                      style={{ position: 'absolute', bottom: 0, width: '100%' }}
                      onClick={handleRemoveDocumentConfirmation}
                    >
                      {context.t('Remove Passport')}
                    </Button>
                  }
                </Card>
              </label>
              <input
                id='passport-input'
                type="file"
                name="passport"
                onChange={documentUploadHandler}
                style={{ display: 'none' }}
              />
            </Col>
            <Col>
              <label htmlFor='idcard-input'>
                <Card className='mt-4'>
                  <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                    <Form.Label style={{ margin: 0 }}>
                      <h6> {context.t('Identification Card')} </h6>
                    </Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={documents.idcard.url || '/static/media/ImagePlaceholder.png'}
                      crossOrigin='anonymous'
                      style={{
                        width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                      }}
                      onError={addDefaultImageSrc}
                    />
                  </Card.Body>
                  {documents.idcard.url &&
                    <Button
                      name='idcard'
                      variant='danger'
                      style={{ position: 'absolute', bottom: 0, width: '100%' }}
                      size='sm'
                      onClick={handleRemoveDocumentConfirmation}
                    >
                      {context.t('Remove Identification Card')}
                    </Button>
                  }
                </Card>
              </label>
              <input
                id='idcard-input'
                type="file"
                name="idcard"
                onChange={documentUploadHandler}
                style={{ display: 'none' }}
              />
            </Col>
            <Col>
              <label htmlFor='birthcertificate-input'>
                <Card className='mt-4'>
                  <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                    <Form.Label style={{ margin: 0 }}>
                      <h6> {context.t('Birth Certificate')} </h6>
                    </Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={documents.birthcertificate.url || '/static/media/ImagePlaceholder.png'}
                      crossOrigin='anonymous'
                      style={{
                        width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                      }}
                      onError={addDefaultImageSrc}
                    />
                  </Card.Body>
                  {documents.birthcertificate.url &&
                    <Button
                      name='birthcertificate'
                      variant='danger'
                      style={{ position: 'absolute', bottom: 0, width: '100%' }}
                      size='sm'
                      onClick={handleRemoveDocumentConfirmation}
                    >
                      {context.t('Remove Birth Certificate')}
                    </Button>
                  }
                </Card>
              </label>
              <input
                id='birthcertificate-input'
                type="file"
                name="birthcertificate"
                onChange={documentUploadHandler}
                style={{ display: 'none' }}
              />
            </Col>
            <Col>
              <label htmlFor='familyrecordbook-input'>
                <Card className='mt-4'>
                  <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                    <Form.Label style={{ margin: 0 }}>
                      <h6> {context.t('Family Record Book')} </h6>
                    </Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={documents.familyrecordbook.url || '/static/media/ImagePlaceholder.png'}
                      crossOrigin='anonymous'
                      style={{
                        width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                      }}
                      onError={addDefaultImageSrc}
                    />
                  </Card.Body>
                  {documents.familyrecordbook.url &&
                    <Button
                      name='familyrecordbook'
                      variant='danger'
                      style={{ position: 'absolute', bottom: 0, width: '100%' }}
                      size='sm'
                      onClick={handleRemoveDocumentConfirmation}
                    >
                      {context.t('Remove Family Record Book')}
                    </Button>
                  }
                </Card>
              </label>
              <input
                id='familyrecordbook-input'
                type="file"
                name="familyrecordbook"
                onChange={documentUploadHandler}
                style={{ display: 'none' }}
              />
            </Col>
            <Col>
              <label htmlFor='residencebook-input'>
                <Card className='mt-4'>
                  <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                    <Form.Label style={{ margin: 0 }}>
                      <h6> {context.t('Residence Book')} </h6>
                    </Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={documents.residencebook.url || '/static/media/ImagePlaceholder.png'}
                      crossOrigin='anonymous'
                      style={{
                        width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                      }}
                      onError={addDefaultImageSrc}
                    />
                  </Card.Body>
                  {documents.residencebook.url &&
                    <Button
                      name='residencebook'
                      variant='danger'
                      style={{ position: 'absolute', bottom: 0, width: '100%' }}
                      size='sm'
                      onClick={handleRemoveDocumentConfirmation}
                    >
                      {context.t('Remove Residence Book')}
                    </Button>
                  }
                </Card>
              </label>
              <input
                id='residencebook-input'
                type="file"
                name="residencebook"
                onChange={documentUploadHandler}
                style={{ display: 'none' }}
              />
            </Col>
            <Col>
              <label htmlFor='covidvaccinecertificate-input'>
                <Card className='mt-4'>
                  <Card.Header style={{ textAlign: 'center', padding: 0 }}>
                    <Form.Label style={{ margin: 0 }}>
                      <h6> {context.t('Covid Vaccine Certificate')} </h6>
                    </Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Image
                      src={documents.covidvaccinecertificate.url || '/static/media/ImagePlaceholder.png'}
                      crossOrigin='anonymous'
                      style={{
                        width: '110px', height: '110px', borderRadius: '100%', objectFit: 'cover', marginTop: '7px'
                      }}
                      onError={addDefaultImageSrc}
                    />
                  </Card.Body>
                  {documents.covidvaccinecertificate.url &&
                    <Button
                      name='covidvaccinecertificate'
                      variant='danger'
                      style={{ position: 'absolute', bottom: 0, width: '100%' }}
                      size='sm'
                      onClick={handleRemoveDocumentConfirmation}
                    >
                      {context.t('Remove Covid Vaccine Certificate')}
                    </Button>
                  }
                </Card>
              </label>
              <input
                id='covidvaccinecertificate-input'
                type="file"
                name="covidvaccinecertificate"
                onChange={documentUploadHandler}
                style={{ display: 'none' }}
              />
            </Col>
          </Row>
        </Form.Group>
      </>
    )
  }


  const [requiredDocuments, setRequiredDocuments] = useState(null)

  // this country hook effect control the entire Resume form
  // resume has changed depending on the country state

  useEffect(() => {
    if(countryObj && Object.keys(countryObj).length != 0){
      switch (countryObj.code) {
        case "mm":
          setRequiredDocuments(myanmarDocs(payload));
          setDocInputFields(documentInputFieldsForWorkerInMyanmar(payload))
          break;
        case "kh":
          setRequiredDocuments(khmerDocs(payload));
          setDocInputFields(documentInputFieldsForWorkerInKhmer(payload))
          break;
        case "ne":
          setRequiredDocuments(nepalDocs(payload));
          setDocInputFields(documentInputFieldsForWorkerInNepal(payload))
          break;
        default:
          setRequiredDocuments(otherDocs(payload))
          setDocInputFields(documentInputFieldsForWorkerInThai(payload))
          break;
      }
    }
  }, [countryObj, payload])


  useEffect(() => {
    if(countryObj && Object.keys(countryObj).length != 0){
         switch (countryObj.code) {
          case "mm":
            setRequiredDocuments(myanmarDocs(payload));
            setDocInputFields(documentInputFieldsForWorkerInMyanmar(payload))
            break;
          case "kh":
            setRequiredDocuments(khmerDocs(payload));
            setDocInputFields(documentInputFieldsForWorkerInKhmer(payload))
            break;
          case "ne":
            setRequiredDocuments(nepalDocs(payload));
            setDocInputFields(documentInputFieldsForWorkerInNepal(payload))
            break;
          default:
            setRequiredDocuments(otherDocs(payload))
            setDocInputFields(documentInputFieldsForWorkerInThai(payload))
            break;
        }
    }
  }, [documents, payload])

  const [showValidationError, setShowValidationError] = useState(false)

  function listObjToArr(Objs) {

    if (Object.getOwnPropertyNames(Objs).length > 0) {
      let converted = []
      Object.values(Objs).forEach(value => converted.push(value))
      return converted;
    } else {
      return [];
    }

  }

  useEffect(() => {
    if (profile.resume && profile.resume.photo) {
      setPhoto({
        ...photo,
        url: profile.resume.photo
      })
    }
    if (profile.resume && profile.resume.country) {
      setCurrentCountry(profile.resume.country)
    }
  }, [profile.resume])

  useEffect(() => {
    setShowValidationError(false)
  }, [payload])

  const documentUploadHandler = event => {
    setDocuments({
      ...documents,
      [event.target.name]: {
        url: URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
      }
    })
  }

  const documentRemoveHandler = docName => {
    setDocuments({
      ...documents,
      [docName]: {
        url: null,
        file: null,
      }
    })
    setDocumentRemoveConfrmation(null)
  }

  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPayload({ ...payload, [name]: value })
  }
  
  const handleCountryCodeChange = (country_code) => { 
    setPayload({...payload, country_code: country_code })
  }

  const handleCountryChange = (country) => {
    setCurrentCountry(country)
    setPayload({ ...payload, country: country })
  }

  const handleStateChange = (state) => {
    setPayload({ ...payload, state: state })
  }

  const handleCityChange = (city) => {
    setPayload({ ...payload, city: city })
  }

  const handleEducationLevelChange = (educationLevel) => {
    setPayload({ ...payload, education: educationLevel })
  }

  const handlePreferredLocationChange = (preferredLocation) => {
    setPayload({ ...payload, locations: preferredLocation })
  }

  const handlePreferredIndustryChange = (preferredIndustry) => {
    setPayload({ ...payload, industries: preferredIndustry })
  }

  const handlePreferredLanguageChange = (preferredLanguage) => {
    setPayload({ ...payload, languages: preferredLanguage })
  }

  const handleDateOfBirthDayChange = (event) => {
    const value = event.target.value.slice(0, 2)
    const dob = `${payload.date_of_birth_year}-${payload.date_of_birth_month}-${value}`
    setPayload({ ...payload, date_of_birth: dob, date_of_birth_day: value })
  }

  const handleDateOfBirthMonthChange = (event) => {
    const value = event.target.value.slice(0, 2)
    const dob = `${payload.date_of_birth_year}-${value}-${payload.date_of_birth_day}`
    setPayload({ ...payload, date_of_birth: dob, date_of_birth_month: value })
  }

  const handleDateOfBirthYearChange = (event) => {
    const value = event.target.value.slice(0, 4)
    const dob = `${value}-${payload.date_of_birth_month}-${payload.date_of_birth_day}`
    setPayload({ ...payload, date_of_birth: dob, date_of_birth_year: value })
  }

  const handlePassportExireDayChange = (event) => {
    const value = event.target.value.slice(0, 2)
    const expireDate = `${payload.passport_expire_year}-${payload.passport_expire_month}-${value}`
    setPayload({ ...payload, passport_expire_date: expireDate, passport_expire_day: value })
  }

  const handlePassportExireMonthChange = (event) => {
    const value = event.target.value.slice(0, 2)
    const expireDate = `${payload.passport_expire_year}-${value}-${payload.passport_expire_day}`
    setPayload({ ...payload, passport_expire_date: expireDate, passport_expire_month: value })
  }

  const handlePassportExireYearChange = (event) => {
    const value = event.target.value.slice(0, 4)
    const expireDate = `${value}-${payload.passport_expire_month}-${payload.passport_expire_day}`
    setPayload({ ...payload, passport_expire_date: expireDate, passport_expire_year: value })
  }

  const handleCIExireMonthChange = (event) => {
    const value = event.target.value.slice(0, 2)
    const expireDate = `${payload.certificate_of_identity_expire_year}-${value}-1`
    setPayload({ ...payload, certificate_of_identity_expire_date: expireDate, certificate_of_identity_expire_month: value })
  }

  const handleCIExireYearChange = (event) => {
    const value = event.target.value.slice(0, 4)
    const expireDate = `${value}-${payload.certificate_of_identity_expire_month}-1`
    setPayload({ ...payload, certificate_of_identity_expire_date: expireDate, certificate_of_identity_expire_year: value })
  }

  const handleChangePhoto = event => {
    // var ext = event.target.files[0].name.match(/\.([^\.]+)$/)[1]
    // if (['jpeg', 'jpg', 'png', 'heic'].indexOf(ext) < 0) {
    //   toast.error(context.t('Please use one of the following formats: .jpeg, .jpg or .png'), 10)
    // }
    // else {
    setPhoto({
      ...photo,
      url: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    })
    // }
  }

  const submitUserDocuments = () => {
    const files = {}

    Object.keys(documents).map(key => {
      if (documents[key].file || documents[key].file === null) {
        files[key] = documents[key].file
      }
    })

    // upload the files
    if (Object.keys(files).length > 0) {
      dispatch(updateUserDocuments(files)).catch(
        err => toast.error(context.t("Couldn't update some of the documents"))
      )
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    const customValidated = (
      payload.education && payload.country && payload.state
      && payload.date_of_birth_day.match(dayRegex)
      && payload.date_of_birth_month.match(monthRegex)
      && payload.date_of_birth_year.match(birthYearRegex)
      && payload.phone_number
      && !displayDayOutOfRangeError()
      && !displayPassportExpireDateError()
      && !displayCIExpireDateError()
      && payload.working_experience.filter(item => (!item.title || !item.years || !item.industry_id)).length == 0
      && payload.languages.filter(item => (!item.language || !item.read_level || !item.speak_level)).length == 0
    )

    if (form.checkValidity() === false || !customValidated) {
      setShowValidationError(true)
      event.stopPropagation();
    }

    else {
      let payloadCleaned = { ...payload }
      payloadCleaned.languages = Object.keys(payloadCleaned.languages).map(key => payloadCleaned.languages[key])
      payloadCleaned.jobseeker = profile.id

      // clean up passport expire date
      if (payload.passport_expire_year && payload.passport_expire_month && payload.passport_expire_day) {
        payloadCleaned.passport_expire_date = `${payload.passport_expire_year}-${payload.passport_expire_month}-${payload.passport_expire_day}`
      } else {
        payloadCleaned.passport_expire_date = null
      }

      // clean up CI expire date
      if (payload.certificate_of_identity_expire_year && payload.certificate_of_identity_expire_month) {
        payloadCleaned.certificate_of_identity_expire_date = `${payload.certificate_of_identity_expire_year}-${payload.certificate_of_identity_expire_month}-1`
      } else {
        payloadCleaned.certificate_of_identity_expire_date = null
      }


      if (profile.resume) {
        dispatch(updateResume(profile.resume.id, payloadCleaned)).then(
          res => {
            toast.success(context.t('Resume has been successfuly updated'));
            if (photo.file) {
              dispatch(updateResumePhoto(profile.resume.id, photo.file)).catch(
                err => toast.error(context.t("Couldn't update photo"))
              )
            }

            // upload user documents [passport, visa, etc]
            submitUserDocuments()
            history.push(params.get('next') ? params.get('next') : params.get('prev') ? params.get('prev') : '/resume')
          }
        ).catch(
          err => {
            toast.error(context.t('Resume has not been updated due to server error'));
          }
        )
      } else {
        dispatch(createResume(payloadCleaned)).then(
          res => {
            toast.success(context.t('Resume has been successfuly created'));
            if (photo.file) {
              dispatch(updateResumePhoto(res.id, photo.file)).catch(
                err => toast.error(context.t("Couldn't update photo"))
              )
            }

            // upload user documents [passport, visa, etc]
            submitUserDocuments()
            history.push(params.get('next') ? params.get('next') : params.get('prev') ? params.get('prev') : '/resume')
          }
        ).catch(
          err => toast.error(context.t('Resume has not been created due to server error'))
        )
      }
    }

    setValidated(true);
  };

  const displayDayOutOfRangeError = () => {
    if (validated && payload.date_of_birth_day
      && payload.date_of_birth_month && payload.date_of_birth_year) {
      const days = parseInt(payload.date_of_birth_day)
      const month = parseInt(payload.date_of_birth_month)
      const year = parseInt(payload.date_of_birth_year)
      return days > daysInMonth(month, year)
    }
    return false
  }

  const displayPassportExpireDateError = () => {
    if (payload.passport_expire_year || payload.passport_expire_month || payload.passport_expire_day) {
      return !passportYearRegex.test(payload.passport_expire_year) || !monthRegex.test(payload.passport_expire_month) || !dayRegex.test(payload.passport_expire_day)
    }
    return false
  }

  const displayCIExpireDateError = () => {
    if (payload.certificate_of_identity_expire_year || payload.certificate_of_identity_expire_month) {
      return !certificateOfIdentityYearRegex.test(payload.certificate_of_identity_expire_year) || !monthRegex.test(payload.certificate_of_identity_expire_month)
    }
    return false
  }

  const handleAddWorkingExperienece = () => {
    setPayload({
      ...payload,
      working_experience: [
        ...payload.working_experience,
        { title: '', industry_id: '', years: '' }
      ]
    })
  };

  const handleAddLanguageSkill = () => {
    setPayload({
      ...payload,
      languages: [
        ...payload.languages,
        { language: '', speak_level: '', read_level: '' }
      ]
    })
  }

  const handleRemoveWorkingExperienceConfirmation = index => {
    setShow(true)
    setIndex(index)
  };

  const handleRemoveDocumentConfirmation = (e) => {
    const docName = e.target.name

    setDocumentRemoveConfrmation(
      <RemoveDocumentConfirmationModal
        handleClose={() => setDocumentRemoveConfrmation(null)}
        handleRemoveDocument={() => documentRemoveHandler(docName)}
      />
    )
  };

  const handleRemoveWorkingExperience = index => {
    const values = [...payload.working_experience]
    const removed = values.splice(index, 1)
    setPayload({
      ...payload,
      working_experience: values
    })
    setShow(false)
  }

  const handleRemoveLanguageSkill = index => {
    const values = [...payload.languages]
    const removed = values.splice(index, 1)
    setPayload({
      ...payload,
      languages: values
    })
    setShow(false)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleChangeWorkingExperience = (index, event) => {
    const values = [...payload.working_experience]

    values[index] = {
      ...values[index],
      [event.target.name]: event.target.value
    }
    setPayload({
      ...payload,
      working_experience: values
    })
  };

  const handleChangeWorkingExperienceIndustry = (index, industry) => {
    const values = [...payload.working_experience]

    values[index] = {
      ...values[index],
      industry_id: industry
    }
    setPayload({
      ...payload,
      working_experience: values
    })
  };

  const handleChangeLanguage = (index, value) => {
    const values = [...payload.languages]
    values[index] = {
      ...values[index],
      language: value
    }

    setPayload({
      ...payload,
      languages: values
    })
  }

  const handleChangeReadLevel = (index, value) => {
    const values = [...payload.languages]

    values[index] = {
      ...values[index],
      read_level: value
    }

    setPayload({
      ...payload,
      languages: values
    })
  }

  const handleChangeSpeakLevel = (index, value) => {
    const values = [...payload.languages]

    values[index] = {
      ...values[index],
      speak_level: value
    }

    setPayload({
      ...payload,
      languages: values
    })
  }

  const handleChangeLanguageProficiency = (language, level) => {
    setPayload({
      ...payload,
      languages: {
        ...payload.languages,
        [language]: {
          language: language,
          level: level
        }
      }
    })
  };

  const getNationalIdPictureURL = () => {
    if (language == 'kh') {
      return '/static/media/national_id_khmer.jpg'
    } else {
      return '/static/media/nrc_myanmar.jpg'
    }
  }

  const getPassportPictureURL = () => {
    if (language == 'kh') {
      return '/static/media/passport_khmer.jpg'
    } else {
      return '/static/media/passport_myanmar.jpg'
    }
  }
  
  return (
    <BaseView title={context.t('Resume')} backurl='/resume' >
      <Container className='afterTopNavigationBar'>
        <JobseekersCreateResumeHelpModal
          currentCountry={currentCountry}
        />
        <WorkingExperienceRemoveConfirmationModal
          show={show}
          index={index}
          handleClose={handleClose}
          handleRemoveWorkingExperience={handleRemoveWorkingExperience}
        />

        {documentRemoveConfirmation}

        <Row style={{ textAlign: 'left' }}>
          <Col>
            <div className='mt-4' />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Divider text={context.t('Basic Information')} style={{ fontWeight: 500 }} />
              <Form.Row>
                <Col xs={5}>
                  <label htmlFor='avatar-input'>
                    <Image
                      src={photo.url || '/static/media/blank-profile-picture.png'}
                      crossOrigin='anonymous'
                      style={{
                        width: '90px', height: '90px', objectFit: 'cover'
                      }}
                    />
                    <p style={{ fontSize: 'smaller', marginTop: 8 }}> {context.t('Upload your photo')} ({context.t('optional')}) </p>
                  </label>
                  <input
                    id='avatar-input'
                    type="file"
                    value=''
                    name="photo"
                    accept="image/*"
                    onChange={handleChangePhoto}
                    style={{ display: 'none' }}
                  />
                </Col>
                <Col>
                  <Form.Group controlId='full_name'>
                    <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Full name')} ({context.t('required')})</Form.Label>
                    <Form.Control
                      name='full_name'
                      pattern="\D*"
                      className="button-modified"
                      placeholder={context.t('Enter your full name')}
                      defaultValue={payload.full_name}
                      onChange={handleInputChange}
                      style={{ height: 30 }}
                      required
                    />
                    <Form.Text muted>
                      {checkCountry.includes(currentCountry) ?
                        context.t('Full name must be the same name in your NRC/Passport') :
                        context.t('Full name must be the same name in your NRC/Passport/Pinkcard')}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {!payload.full_name ?
                        context.t('Please enter your full name')
                        :
                        context.t('Name can contain only letters')
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId='gender'>
                    <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Gender')} ({context.t('required')})</Form.Label>
                    <GenderSelect
                      initialValue={payload.gender}
                      onChange={value => setPayload({ ...payload, gender: value })}
                    />
                    <Form.Text style={{ marginTop: 0 }} muted>
                      {context.t('Choose male or female')}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Group controlId="education">
                <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Education')} ({context.t('required')})</Form.Label>
                <EducationLevelSelect
                  initialValue={payload.education}
                  onChange={handleEducationLevelChange}
                />
                <Form.Text muted>
                  {context.t('Select the highest level of education that you have successfully completed')}
                </Form.Text>
                <Form.Control.Feedback type="invalid" style={{ display: validated && !payload.education && 'block' }}>
                  {context.t('Please choose your education level')}
                </Form.Control.Feedback>
              </Form.Group>

              {educationLevels[payload.education] && educationLevels[payload.education].has_major &&
                <Form.Group controlId='major'>
                  <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Major')} ({context.t('required')})</Form.Label>
                  <Form.Control
                    name='major'
                    placeholder={context.t('Enter your major name')}
                    defaultValue={payload.major}
                    onChange={handleInputChange}
                    style={{ height: 30 }}
                    required
                  />
                  <Form.Text muted>
                    {context.t('Enter your major')}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {context.t('Please enter your major')}
                  </Form.Control.Feedback>
                </Form.Group>
              }

              <Divider text={context.t('Birthday (Gregorian calendar)')} style={{ fontWeight: 500 }} />
              <Form.Row>
                <Col style={{ display: 'inline-flex' }}>
                  <Form.Group>
                    <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Date')}</Form.Label>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{context.t('Please fill Gregorian day')}</Tooltip>}>
                      <Form.Control
                        type="number"
                        pattern="[0-9]*"
                        value={payload.date_of_birth_day}
                        className="button-modified"
                        onChange={handleDateOfBirthDayChange}
                        style={{ height: 30, maxWidth: 50, marginRight: 16 }}
                        min={1}
                        max={31}
                        required
                      />
                    </OverlayTrigger>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Month')}</Form.Label>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{context.t('Please fill Gregorian Month')}</Tooltip>}>
                      <Form.Control
                        type="number"
                        pattern="[0-9]*"
                        className="button-modified"
                        value={payload.date_of_birth_month}
                        onChange={handleDateOfBirthMonthChange}
                        style={{ height: 30, maxWidth: 50, marginRight: 16 }}
                        min={1}
                        max={12}
                        required
                      />
                    </OverlayTrigger>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Year')}</Form.Label>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{context.t('Please fill Gregorian Year')}</Tooltip>}>
                      <Form.Control
                        type="number"
                        pattern="[0-9]*"
                        className="button-modified"
                        value={payload.date_of_birth_year}
                        onChange={handleDateOfBirthYearChange}
                        style={{ height: 30, maxWidth: 67 }}
                        min={1940}
                        max={2005}
                        required
                      />
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Form.Row>
              <div style={{ marginTop: '-1rem', marginBottom: '1rem' }}>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: validated && !payload.date_of_birth_day.match(dayRegex) && 'block' }}
                >
                  {context.t('Day: please enter the number between 1 and 31')}
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: displayDayOutOfRangeError() && 'block' }}
                >
                  {context.t('Day: this month has less days')}
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: validated && !payload.date_of_birth_month.match(monthRegex) && 'block' }}
                >
                  {context.t('Month: please enter the number between 1 and 12')}
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: validated && !payload.date_of_birth_year.match(birthYearRegex) && 'block' }}
                >
                  {context.t('Year: please enter the number between 1940 and 2005')}
                </Form.Control.Feedback>
              </div>

              <Divider text={context.t('Contact Information')} style={{ fontWeight: 500 }} />
              <Form.Group>
                <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Email')} ({context.t('optional')})</Form.Label>
                <Form.Control
                  type="email"
                  className="button-modified"
                  value={payload.email}
                  onChange={event => setPayload({ ...payload, email: event.target.value })}
                  placeholder={context.t('Enter your email')}
                  style={{ height: 30 }}
                />
                <Form.Text muted>
                  {context.t('Enter the email address you always use')}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {context.t('Please enter valid email address')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Phone number')} ({context.t('required')})</Form.Label>
                <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2">
                    <CountryCodesSelect
                       placeholder={context.t('Select')}
                       initialValue ={payload.country_code}
                       onChange={country_code => handleCountryCodeChange(country_code)}
                    />
                    <Form.Text muted>
                      {context.t('Select the country code')}
                    </Form.Text>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: validated && !payload.country_code && 'block' }}
                    >
                      {context.t('Please select a country code')}
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-10">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{context.t('Pls add your country code in front of the no. For Eg. for 09765000150, pls put 959765000150.')}</Tooltip>}>
                      <Form.Control
                        type="tel"
                        // value={phonenumber}
                        // onChange={ e=>  setphonenumber(parseInt(e.target.value))}
                        value={payload.phone_number}
                        className="button-modified"
                        onChange={event => setPayload({ ...payload, phone_number: event.target.value })}
                        placeholder={context.t('Enter your phone number')}
                        style={{ height: 30 }}
                        required
                      />
                    </OverlayTrigger>
                    <Form.Text muted>
                      {context.t('Enter the phone number you always use')}
                    </Form.Text>
                    <Form.Control.Feedback
                      type="invalid"
                      style={{ display: validated && !payload.phone_number && 'block' }}
                    >
                      {context.t('Please enter valid phone number')}
                    </Form.Control.Feedback>
                  </div>
                </div>

              </Form.Group>
              <Form.Group>
                <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Emergency Phone number')} ({context.t('optional')})</Form.Label>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{context.t('Pls add your country code in front of the no. For Eg. for 09765000150, pls put 959765000150.')}</Tooltip>}>
                  <Form.Control
                    type="tel"
                    className="button-modified"
                    value={payload.emergency_phone_number}
                    onChange={event => setPayload({ ...payload, emergency_phone_number: event.target.value })}
                    placeholder={context.t('Enter your emergency phone number')}
                    style={{ height: 30 }}
                  />
                </OverlayTrigger>
                <Form.Text muted>
                  {context.t('Enter your Emergency Phone Number')}
                </Form.Text>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: payload.emergency_phone_number != '' && !payload.emergency_phone_number.match(workerPhoneRegex) && 'block' }}
                >
                  {context.t('Please enter valid phone number')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Enter LINE id')} ({context.t('optional')})</Form.Label>
                <Form.Control
                  type="tel"
                  className="button-modified"
                  value={payload.line_id_number}
                  onChange={event => setPayload({ ...payload, line_id_number: event.target.value })}
                  placeholder={context.t('Enter your LINE id number')}
                  style={{ height: 30 }}
                />
                <Form.Text muted>
                  {context.t('Enter your LINE id Number')}
                </Form.Text>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: payload.line_id_number != '' && !payload.line_id_number.match(lineRegex) && 'block' }}
                >
                  {context.t('Please enter valid LINE id number')}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ fontSize: 'smaller' }}>{context.t('Viber id number')} ({context.t('optional')})</Form.Label>
                <Form.Control
                  className="button-modified"
                  type="tel"
                  value={payload.viber_id_number}
                  onChange={event => setPayload({ ...payload, viber_id_number: event.target.value })}
                  placeholder={context.t('Enter your viber id number')}
                  style={{ height: 30 }}
                />
                <Form.Text muted>
                  {context.t('Enter your Viber Id Number')}
                </Form.Text>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: payload.viber_id_number != '' && !payload.viber_id_number.match(viberRegex) && 'block' }}
                >
                  {context.t('Please enter valid viber id number')}
                </Form.Control.Feedback>
              </Form.Group>

              <Divider text={context.t('Current Address')} style={{ fontWeight: 500 }} />
              <Form>
              <Row>
                <Col xs='12' md='4' lg='4'>
                <Form.Group controlId="country">
                  <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Country')} ({context.t('required')})</Form.Label>
                  <CountrySelect
                    placeholder={context.t("Select Country")}
                    initialValue={payload.country}
                    onChange={handleCountryChange}
                  />
                  <Form.Text muted>
                    {context.t('Select the country you are living currently')}
                  </Form.Text>
                </Form.Group>
                </Col>
                <Col xs='12' md='4' lg='4'>
                <Form.Group>
                  <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('State/Province')} ({context.t('required')})</Form.Label>
                  <StateSelect
                    initialValue={payload.state}
                    placeholder={context.t("Select State/Province")}
                    country={payload.country}
                    dependant={true}
                    onChange={handleStateChange}
                  />
                  <Form.Text muted>
                    {context.t('Select the state/province you are living currently')}
                  </Form.Text>
                </Form.Group>
                </Col>
                <Col xs='12' md='4' lg='4'>
                <Form.Group>
                  <Form.Label style={{ fontSize: 'smaller' }}>{context.t('City')} ({context.t('optional')})</Form.Label>
                  <CitySelect
                    placeholder={context.t("Select City")}
                    initialValue={payload.city}
                    state={payload.state}
                    dependant={true}
                    onChange={handleCityChange}
                  />
                  <Form.Text muted>
                    {context.t('Select the city you are living currently')}
                  </Form.Text>
                </Form.Group>
                </Col>
                </Row>
              </Form>

              <div style={{ marginTop: '-1rem', marginBottom: '1rem' }}>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: validated && !payload.country && 'block' }}
                >
                  {context.t('Country: please select the country')}
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: validated && !payload.state && 'block' }}
                >
                  {context.t('State/Province: please select the state/province')}
                </Form.Control.Feedback>
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: validated && !payload.state && 'block' }}
                >
                  {context.t('City: please select the city')}
                </Form.Control.Feedback>
              </div>

              <Form.Group>
                <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Address details')} ({context.t('required')})</Form.Label>
                <Form.Control
                  name='address_details'
                  className="button-modified"
                  placeholder={context.t('Please enter address details here')}
                  defaultValue={payload.address_details}
                  onChange={handleInputChange}
                  style={{ height: 30 }}
                  required
                />
                <Form.Text muted>
                  {context.t('Please enter the Address Details here (Room No, Street Name, Block Name, Township Name)')}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {context.t('Please enter your address details')}
                </Form.Control.Feedback>
              </Form.Group>

              {workersInThai}

              <Divider className='mt-3' text={context.t('Language Skills')} style={{ fontWeight: 500 }} />
              {payload.languages.map((lang, index) => {
                return (
                  <Form.Row as={Row} key={index}>
                    <Col>
                      <Form.Group controlId="job-title">
                        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Select Language')} </Form.Label>
                        <LanguageSkillSelect
                          languages={languages}
                          currentSelectedLanguages={payload.languages}
                          initialValue={payload.languages[index].language}
                          onChange={value => handleChangeLanguage(index, value)} />
                        <Form.Text style={{ marginTop: 0 }} muted>
                          {context.t('Select the Language')}
                        </Form.Text>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: validated && !payload.languages[index].language && 'block' }}
                        >
                          {context.t('Select the Langauge')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="job-title">
                        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Select Read Level')} </Form.Label>
                        <ReadLevelSelect
                          initialValue={payload.languages[index].read_level}
                          onChange={value => handleChangeReadLevel(index, value)} />
                        <Form.Text style={{ marginTop: 0 }} muted>
                          {context.t('Select the Read Level of Language')}
                        </Form.Text>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: validated && !payload.languages[index].read_level && 'block' }}
                        >
                          {context.t('Select read level of Language')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="job-title">
                        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Select Speak Level')} </Form.Label>
                        <SpeakLevelSelect
                          initialValue={payload.languages[index].speak_level}
                          onChange={value => handleChangeSpeakLevel(index, value)} />
                        <Form.Text style={{ marginTop: 0 }} muted>
                          {context.t('Select speak Level of Language')}
                        </Form.Text>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: validated && !payload.languages[index].speak_level && 'block' }}
                        >
                          {context.t('Select speak level of Language')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="danger"
                        size="sm"
                        style={{ position: 'absolute', top: 29 }}
                        onClick={() => handleRemoveLanguageSkill(index)}
                      >
                        {context.t('remove')}
                      </Button>
                    </Col>
                  </Form.Row>
                )
              })}
              {payload.languages.length <= 2 &&
                <Button
                  variant='outline-primary'
                  className="button-modified"
                  style={{ width: '100%', marginBottom: 32 }}
                  onClick={handleAddLanguageSkill}
                >
                  <i className="fas fa-plus fa-sm"></i> {context.t('Add Language Skill')}
                </Button>
              }

              <Divider className='mt-3' text={context.t('Working Experience')} style={{ fontWeight: 500 }} />
              {payload.working_experience.map((experience, index) => {
                return (
                  <Form.Row as={Row} key={index}>
                    <Col>
                      <Form.Group controlId="job-title">
                        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Job Title')} </Form.Label>
                        <Form.Control
                          name='title'
                          placeholder={context.t('Enter your job title')}
                          value={payload.working_experience[index].title}
                          onChange={event => handleChangeWorkingExperience(index, event)}
                          style={{ height: 30 }}
                          required
                        />
                        <Form.Text style={{ marginTop: 0 }} muted>
                          {context.t('Enter the job title of previous job or current job')}
                        </Form.Text>
                      </Form.Group>
                    </Col>
                    <Col xs={2}>
                      <Form.Group>
                        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Years')}</Form.Label>
                        <Form.Control
                          name='years'
                          type="number"
                          pattern="[0-9]*"
                          value={payload.working_experience[index].years}
                          onChange={event => handleChangeWorkingExperience(index, event)}
                          style={{ height: 30, maxWidth: 47 }}
                          min={1}
                          max={50}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label style={{ fontSize: 'smaller' }}>*{context.t('Job Industry')}</Form.Label>
                        <IndustrySelect
                          initialValue={payload.working_experience[index].industry_id}
                          onChange={value => handleChangeWorkingExperienceIndustry(index, value)}
                          isSearchable
                        />
                        <Form.Text style={{ marginTop: 0 }} muted>
                          {context.t('Select the type of industry you worked in for this job')}
                        </Form.Text>
                        <Form.Control.Feedback
                          type="invalid"
                          style={{ display: validated && !payload.working_experience[index].industry_id && 'block' }}
                        >
                          {context.t('Select industry')}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="danger"
                        size="sm"
                        style={{ position: 'absolute', top: 29 }}
                        onClick={() => handleRemoveWorkingExperienceConfirmation(index)}
                      >
                        {context.t('remove')}
                      </Button>
                    </Col>
                  </Form.Row>
                )
              })}
              <Button
                variant='outline-primary'
                style={{ width: '100%', marginBottom: 32 }}
                onClick={handleAddWorkingExperienece}
                className="button-modified"
              >
                <i className="fas fa-plus fa-sm"></i> {context.t('Add new experience')}
              </Button>

              {requiredDocuments}

              <Form.Control.Feedback
                type="invalid"
                style={{ display: validated && showValidationError && 'block' }}
              >
                {context.t('Please fill in all the required fields and try again.')}
              </Form.Control.Feedback>

              <Button
                variant="success"
                type="submit"
                className="button-modified"
                style={{ width: '100%' }}
                disabled={resume.loading}
              >
                {resume.loading &&
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: 8 }}
                  />
                }
                {context.t('Save CV')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </BaseView >
  )
}

JobseekersEditResumeView.contextTypes = {
  t: PropTypes.func.isRequired
}