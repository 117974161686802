import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { useSelector } from "react-redux";
import { BsClock } from "react-icons/bs";

import moment from 'moment';
import { Link } from "react-router-dom";
import { Row, Col, Image, Badge } from 'react-bootstrap';
import 'react-rater/lib/react-rater.css'
import { addDefaultImageSrc } from '../common/utilities/utilities';
import Rater from "react-rater";
import { rosterStatus } from "../common/utilities/utilities";

export default function RecruiterRosterItem(props, context) {

    const APPLICABLE = 'APPLICABLE';
    const application = useSelector(state => state.WithdrawExtendState.application);
    const [expiredAt, setExpiredAt] = useState(props.application && props.application.expired_at ? moment(props.application.expired_at).format('LL') : null);
    const agency = props.agency;

    const border = {
        border: "1px solid gray",
        borderRadius: "5px",
        boxShadow: "0 5px 9px rgba(0, 0, 0, 0.2)",
        padding: "10px",
    }
    
    const status = () => {
        const applicationStatus = props.application ? props.application.status : undefined;
        return rosterStatus(context, applicationStatus !== undefined ? applicationStatus : APPLICABLE);
    };
      
    const statusVariant = (status) => {
        const variantMap = {
            'APPLIED': 'success',
            'DOWNLOADED': 'info',
            'VIEWED': 'info',
            'APPLICANT_NA': 'danger',
            'SHORTLISTED': 'success',
        };
    
        return variantMap[status] || 'info';
    };
    
    useEffect(() => {
        if (application.fetched && props.application) {
            if (application.item.id == props.application.id) {
                setExpiredAt(moment(application.item.expired_at).format('LL'))
            }
        }
    }, [application])

    return (
        <Link
            to={props.fromApplication ? `/jobs/${agency.id}?prev=/job-applications` : `/recruiter-roster/${agency.id}`}
        >
            <div className='Card mt-3' style={border}>
                <Row>
                    {status() && status() != 'APPLICABLE' &&
                        <Col xs={12} md={12} lg={12} style={{textAlign: 'center'}}>
                            <Badge style={{fontSize: '12px'}} pill variant={statusVariant(props.application && props.application.status)}>
                                {status()}
                            </Badge>
                            <hr style={{ borderTop: '1px solid #999'}} />
                        </Col>
                    }
                    <Col xs={12} lg={6} md={6} className="mt-3" style={{textAlign: 'center'}}>
                        <Image
                            src={agency.logo ? agency.logo : '/static/media/ImagePlaceholder.png'}
                            crossOrigin='anonymous'
                            style={{ width: 100, height: 100, objectFit: 'cover', border: 'solid 1px gray', boxShadow: '0 5px 9px rgba(0, 0, 0, 0.2)' }}
                            onError={addDefaultImageSrc}
                            roundedCircle
                        />
                        <div>
                            <Rater total={5} rating={agency.rating_score} interactive={false} />
                            <span> {agency.rating_score && agency.rating_score.toFixed(1)} </span>
                            <span style={{ position: 'relative', bottom: '7px', fontSize: '0.7em' }}>({agency.rating_count})</span>
                        </div>
                        <h3 style={{ marginBottom: 0 }}>{agency.name} </h3>
                    </Col>
                    <Col xs={12} lg={6} md={6}>
                        <div className="mt-3">
                            {agency.status === 'Operational' &&
                                    <p style={{ marginBottom: 0, fontWeight: 600, color: 'green' }}> <i className="fa-regular fa-circle-check"></i> {context.t('Operational')} </p>
                                }
                                {agency.status === 'Temporarily Suspended' &&
                                    <div style={{ marginBottom: 0, fontWeight: 600, color: 'orange' }}>
                                    <i className="far fa-pause-circle"></i> {context.t('Temporarily Suspended')}
                                    {agency.status_from_date &&
                                        <p style={{ color: 'black', fontWeight: 600, marginBottom: 0 }}> {context.t('from: {date}', { date: moment(agency.status_from_date).format('LL') })} </p>
                                    }
                                    {agency.status_to_date &&
                                        <p style={{ color: 'black', fontWeight: 600 }}> {context.t('to: {date}', { date: moment(agency.status_to_date).format('LL') })} </p>
                                    }
                                    </div>
                                }
                                {agency.status === 'Permanently Blacklisted' &&
                                    <div style={{ marginBottom: 0, fontWeight: 600, color: 'red' }}>
                                    <i className="fa-sharp fa-regular fa-circle-xmark"></i> {context.t('Permanently Blacklisted')}
                                    {agency.status_from_date &&
                                        <p style={{ color: 'black', fontWeight: 600 }}> {context.t('from: {date}', { date: moment(agency.status_from_date).format('LL') })} </p>
                                    }
                                    </div>
                                }
                                {agency.status === 'License is not extended' &&
                                    <div style={{ marginBottom: 0, fontWeight: 600, color: 'darkorange' }}>
                                    <i className="fas fa-id-badge"></i> {context.t('License is not extended')}
                                    {agency.status_from_date &&
                                        <p style={{ color: 'black', fontWeight: 600 }}> {context.t('from: {date}', { date: moment(agency.status_from_date).format('LL') })} </p>
                                    }
                                    </div>
                                }
                            {agency.thai_license_number &&
                            <p style={{ marginBottom: 0 }}> {context.t('Thai License Number')} : {agency.thai_license_number}</p>
                            }
                            <p style={{ marginBottom: 0 }}> {context.t('License number')}: {agency.license_number ? agency.license_number: 'N/A'}</p>
                            <p style={{ marginBottom: 0 }}> {context.t('Email')}: {agency.email ? agency.email : 'N/A'}</p>
                        </div>
                    </Col>
                    {props.application && props.application.id && expiredAt &&
                        <Col xs={12} lg={12} md={12} style={{textAlign: 'center'}}>
                            <hr style={{ borderTop: '1px solid #999'}} />
                            <p style={{ color: 'brown', display: 'inline-block', marginBottom: '0', paddingBottom: '0' }}>
                                {context.t('Your application will expire at')}
                            </p>
                            :  <BsClock /> {expiredAt}
                        </Col>
                    }
                </Row>
            </div >
        </Link >
    )
}

RecruiterRosterItem.contextTypes = {
    t: PropTypes.func.isRequired
}