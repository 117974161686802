import axios from 'axios';
import { addProfileRating, updateProfileRating } from './ProfileActions';
import { recalculateServiceProviderScore } from './ServiceProvidersActions';
import { recalculateRecruitmentAgencyScore } from './RecruitmentAgenciesActions';
import { recalculateEmployerScore } from './EmployersActions';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const RATINGS_POST_START = 'RATINGS_POST_START';
export const RATINGS_POST_SUCCESS = 'RATINGS_POST_SUCCESS';
export const RATINGS_POST_FAIL = 'RATINGS_POST_FAIL';

export const RATINGS_UPDATE_START = 'RATINGS_UPDATE_START';
export const RATINGS_UPDATE_SUCCESS = 'RATINGS_UPDATE_SUCCESS';
export const RATINGS_UPDATE_FAIL = 'RATINGS_UPDATE_FAIL';

export const RATINGS_ADD_TO_PROFILE = 'RATINGS_ADD_TO_PROFILE';
export const RATING_POST_STATE = 'RATING_POST_STATE'


// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// POST:
export const postRatingStart = () => {
  return {
    type: RATINGS_POST_START,
  }
}

export const postRatingSuccess = () => {
  return {
    type: RATINGS_POST_SUCCESS,
  }
}

export const postRatingFail = error => {
  return {
    type: RATINGS_POST_FAIL,
    error: error
  }
}

export const postRatingState = (el) => {
  return {
    type: RATING_POST_STATE,
    state: el
  }
}

export const postRating = (payload, offsetCount, offsetScore) => {
  return (dispatch, getState) => {
    dispatch(postRatingStart());
    console.log(payload)
    return axios.post(`${process.env.REACT_APP_API_URL}/ratings`, payload)
      .then(res => {
        dispatch(postRatingState(false))
        dispatch(postRatingSuccess());
        dispatch(addProfileRating({ ...payload, ...res.data }));

        const score = payload.scores.reduce((acc, score) => acc + score.score, 0) / payload.scores.length
        const scores_count = payload.scores.length

        let scores = {}
        payload.scores.forEach(item => {
          scores[item.rating_criteria] = item
        })

        if (payload.content_type === 'service provider') {
          dispatch(recalculateServiceProviderScore(payload.object_id, scores, offsetCount, offsetScore))
        }
        else if (payload.content_type === 'recruitment agency profile') {
          dispatch(recalculateRecruitmentAgencyScore(payload.object_id, scores, offsetCount, offsetScore))
        }
        else if (payload.content_type === 'employer profile') {
          dispatch(recalculateEmployerScore(payload.object_id, scores, offsetCount, offsetScore))
        }

        return payload
      })
      .catch(err => {
        dispatch(postRatingFail(err))
        throw err
      })
  }
}


// PATCH:
export const updateRatingStart = () => {
  return {
    type: RATINGS_UPDATE_START,
  }
}

export const updateRatingSuccess = () => {
  return {
    type: RATINGS_UPDATE_SUCCESS,
  }
}

export const updateRatingFail = error => {
  return {
    type: RATINGS_UPDATE_FAIL,
    error: error
  }
}

export const updateRating = (rating_id, payload, offsetCount, offsetScore) => {
  return (dispatch, getState) => {
    dispatch(updateRatingStart());

    return axios.patch(`${process.env.REACT_APP_API_URL}/ratings/${rating_id}`, payload)
      .then(res => {
        dispatch(postRatingState(false))
        dispatch(updateRatingSuccess());
        dispatch(updateProfileRating({ ...payload, ...res.data }));

        const score = payload.scores.reduce((acc, score) => acc + score.score, 0) / payload.scores.length
        const scores_count = payload.scores.length

        let scores = {}
        payload.scores.forEach(item => {
          scores[item.rating_criteria] = item
        })

        if (payload.content_type === 'service provider') {
          dispatch(recalculateServiceProviderScore(payload.object_id, scores, offsetCount, offsetScore, true))
        }
        else if (payload.content_type === 'recruitment agency profile') {
          dispatch(recalculateRecruitmentAgencyScore(payload.object_id, scores, offsetCount, offsetScore, true))
        }
        else if (payload.content_type === 'employer profile') {
          dispatch(recalculateEmployerScore(payload.object_id, scores, offsetCount, offsetScore, true))
        }

        return payload
      })
      .catch(err => {
        dispatch(updateRatingFail(err))
        throw err
      })
  }
}
