import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Container, Image, Col, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import BaseView from "../BaseView";
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import LazyImage from "../../components/LazyImage";
import { getCities } from "../../common/redux/actions/CitiesActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import moment from "moment";
import { Online } from "react-detect-offline";
import CompanyContacts from "../../components/CompanyContacts";
import Address from "../../components/Address";
import ShareButtons from "../../components/ShareButtons";
import Rating from "../../components/Rating";
import DetailedRatings from "../../components/DetailedRatings";
import Comment from "../../components/Comment";
import useRecruiterComment from "../../common/hooks/useRecruiterComment";
import { postComment } from "../../common/redux/actions/CommentsActions";
import CommentInput from "../../components/CommentInput";
import {
  deleteComment,
  hideComment,
} from "../../common/redux/actions/CommentHideDeleteAction";
import Divider from "../../components/Divider";

export default function RecruitmentAgenciesDetailedViewV3(props, context) {
  const dispatch = useDispatch();
  const observer = useRef();
  const version = Math.random().toString(36).substring(7);
  const profile = useSelector((state) => state.ProfileState);
  const language = useSelector((state) => state.i18nState.lang);
  const raId = parseInt(props.match.params.id);
  const [businessImages, setBusinessImages] = useState([]);
  const languages = useSelector((state) => state.LanguagesState);
  const [cover, setCover] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [raName, setRaName] = useState(null);
  const countries = useSelector((state) => state.CountriesState);
  const cities = useSelector((state) => state.CitiesState);
  const states = useSelector((state) => state.StatesState);
  const recruiterRatings = useSelector((state) => state.RatingsState);
  const [description, setDescrption] = useState(null);
  const [languageId, setLanguageId] = useState();
  const [showRateForm, setShowRateForm] = useState(false);
  const [cursor, setCursor] = useState("");
  const respondedComment = useSelector(
    (state) => state.CommentsState.respondedComment
  );
  const [commentReply, setCommentReply] = useState({
    show: false,
    parent: null,
    base_parent: null,
    username: "",
    isModerator: false,
  });

  const [commentAction, setCommentAction] = useState({
    type: null,
    comment: {},
  });

  // Fetch function for React Query
  async function fetchRecruitmentAgency(language, raId) {
    // Fetch data using the raId and return it
    // You can use axios, fetch, or any other HTTP library
    const response = await fetch(
      `${process.env.REACT_APP_API_V2_URL}/recruitment-agencies/${raId}?lang=${language}`
    );
    const data = await response.json();
    return data;
  }

  // React Query for fetching recruiter detail
  const {
    data: recruitmentAgency,
    isLoading: isLoadingRecruitmentAgency,
    refetch: refetchAgency,
  } = useQuery(
    ["recruitmentAgency", raId],
    () => fetchRecruitmentAgency(language, raId),
    {
      staleTime: 30000, // Data is considered stale after 30 seconds (in milliseconds)
    }
  );

  const { next, raComments, hasMore, loading, error } = useRecruiterComment(
    cursor,
    raId,
    respondedComment,
    commentAction
  );

  useEffect(() => {
    !cities.fetched && dispatch(getCities());
    !states.fetched && dispatch(getStates());
    !countries.fetched && dispatch(getCountries());
  }, []);

  const lastCommentRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && next.current) {
          setCursor(next.current);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const handleSubmitComment = (payload) => {
    dispatch(postComment("recruitment_agency", payload));
  };

  useEffect(() => {
    async function fetchData() {
      // Trigger the refetch
      refetchAgency();
    }
    fetchData(); // Call the async function
  }, [recruiterRatings]);

  useEffect(() => {
    if (Object.keys(languages.items).length != 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code == language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  const handleCloseReply = () => {
    setCommentReply({
      show: false,
      parent: null,
      base_parent: null,
      username: "",
      isModerator: false,
    });
  };

  const commentActionsHandler = (comment, type) => {
    setCommentAction({
      ...commentAction,
      type: type,
      comment: comment,
    });
    if (type == "delete") {
      dispatch(deleteComment(comment.id));
    } else if (type == "hide") {
      dispatch(hideComment(comment.id, true));
      dispatch(deleteComment(comment.id));
    }
  };

  const handleClickReplyButton = (replyPayload) => {
    setCommentReply({
      ...replyPayload,
      show: true,
    });
  };

  useEffect(() => {
    if (recruitmentAgency && profile.data && profile.data.type === "JS") {
      const rating = profile.data.ratings.filter(
        (rating) =>
          rating.content_type === "recruitment agency profile" &&
          rating.object_id === recruitmentAgency.id
      )[0];
      if (rating) {
        setUserRating(rating);
      } else {
        setUserRating(undefined);
      }
    }

    if (
      recruitmentAgency &&
      recruitmentAgency.bussiness_images &&
      Array.isArray(recruitmentAgency.bussiness_images) &&
      recruitmentAgency.bussiness_images.length > 0
    ) {
      const cover = recruitmentAgency.bussiness_images.filter(
        (image) => image.cover === true
      );
      const nonCovers = recruitmentAgency.bussiness_images.filter(
        (image) => image.cover !== true
      );

      if (cover.length > 0) {
        setCover(cover[0]);
      }
      if (nonCovers.length > 0) {
        setBusinessImages(nonCovers);
      }
    }

    if (
      languageId &&
      recruitmentAgency &&
      Array.isArray(recruitmentAgency.translations) &&
      recruitmentAgency.translations.length > 0
    ) {
      const recruiterDetail = recruitmentAgency.translations.filter(
        (item) => item.language === languageId
      );
      if (recruiterDetail && recruiterDetail[0]) {
        setRaName(recruiterDetail[0].name);
      }
    }
  }, [recruitmentAgency, languageId]);

  useEffect(() => {
    if (
      languageId &&
      recruitmentAgency &&
      Object.keys(recruitmentAgency).length != 0 &&
      recruitmentAgency.translations &&
      recruitmentAgency.translations.length > 0
    ) {
      let translatedObj = recruitmentAgency.translations.find(
        (tran) => tran.language == languageId
      );
      setDescrption(translatedObj ? translatedObj.description : "");
    }

    console.log("here is recruitment agency", recruitmentAgency);
  }, [languageId, recruitmentAgency]);

  return (
    <BaseView
      title={context.t("Recruitment Agencies")}
      backurl="/recruitment-agencies"
    >
      {countries.loading ||
      cities.loading ||
      states.loading ||
      isLoadingRecruitmentAgency ||
      !recruitmentAgency ? (
        <div style={{ display: "flex", height: "100%", marginTop: "97px" }}>
          <Image
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container className="afterTopNavigationBar" style={{ padding: 0 }}>
          {cover ? (
            <div style={{ position: "relative" }} className="mb-6">
              <LazyImage
                style={{
                  width: "100%",
                  height: "300px",
                  objectFit: "cover",
                }}
                src={`${cover.image}?v=${version}`}
              />
              <LazyImage
                src={`${
                  recruitmentAgency.logo
                    ? recruitmentAgency.logo
                    : "/static/media/recruiter.png"
                }?timestamp=${Date.now()}`}
                crossOrigin="anonymous"
                style={{
                  position: "absolute",
                  bottom: "-75px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "150px",
                  height: "150px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  border: "4px solid white",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
                onError={addDefaultImageSrc}
              />
            </div>
          ) : (
            <LazyImage
              src={
                recruitmentAgency.logo
                  ? recruitmentAgency.logo
                  : "/static/media/recruiter.png"
              }
              crossOrigin="anonymous"
              style={{
                borderRadius: "50%",
                boxShadow: "0 5px 9px rgba(0, 0, 0, 0.2)",
                width: "150px",
                height: "150px",
                objectFit: "contain",
                marginTop: "1rem",
              }}
              onError={addDefaultImageSrc}
            />
          )}
          <Container className="pb-6">
            <Rater
              total={5}
              rating={recruitmentAgency.rating_score}
              interactive={false}
            />
            <span>
              {recruitmentAgency.rating_score &&
                recruitmentAgency.rating_score.toFixed(1)}
            </span>
            <span
              style={{ position: "relative", bottom: "7px", fontSize: "0.7em" }}
            >
              ({recruitmentAgency.rating_count})
            </span>
            <h3 className="ListItemName"> {raName} </h3>
            {language == "en" || language == "ne" ? null : (
              <h5 className="ListItemName"> {recruitmentAgency.name_en} </h5>
            )}
            <div className="description">
              {recruitmentAgency.status === "Operational" && (
                <p style={{ marginBottom: 0, fontWeight: 600, color: "green" }}>
                  <i className="fa-regular fa-circle-check"></i>&nbsp;
                  {context.t("Operational")}
                </p>
              )}
              {recruitmentAgency.status === "Temporarily Suspended" && (
                <div
                  style={{ marginBottom: 0, fontWeight: 600, color: "orange" }}
                >
                  <i className="far fa-pause-circle"></i>&nbsp;
                  {context.t("Temporarily Suspended")}
                  {recruitmentAgency.status_from_date && (
                    <p
                      style={{
                        color: "black",
                        fontWeight: 600,
                        marginBottom: 0,
                      }}
                    >
                      {context.t("from: {date}", {
                        date: moment(recruitmentAgency.status_from_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                  {recruitmentAgency.status_to_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("to: {date}", {
                        date: moment(recruitmentAgency.status_to_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruitmentAgency.status === "Permanently Blacklisted" && (
                <div style={{ marginBottom: 0, fontWeight: 600, color: "red" }}>
                  <i className="fa-sharp fa-regular fa-circle-xmark"></i>&nbsp;
                  {context.t("Permanently Blacklisted")}
                  {recruitmentAgency.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(recruitmentAgency.status_from_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruitmentAgency.status === "License is not extended" && (
                <div
                  style={{
                    marginBottom: 0,
                    fontWeight: 600,
                    color: "darkorange",
                  }}
                >
                  <i className="fa-light fa-triangle-exclamation"></i>&nbsp;
                  {context.t("License is not extended")}
                  {recruitmentAgency.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(recruitmentAgency.status_from_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruitmentAgency.status == "Processing" && (
                <div
                  style={{ marginBottom: 0, fontWeight: 600, color: "#2196F3" }}
                >
                  <i className="fas fa-cog"></i> &nbsp;{context.t("Processing")}
                  {recruitmentAgency.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(recruitmentAgency.status_from_date).format(
                          "LL"
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruitmentAgency.date_of_registration && (
                <p style={{ marginBottom: 0 }}>
                  <span style={{ fontWeight: 600 }}>
                    {context.t("Registered:")}
                  </span>
                  {moment(recruitmentAgency.date_of_registration).format("LL")}
                </p>
              )}
              {recruitmentAgency.license_number && (
                <p style={{ marginBottom: 0 }}>
                  <span style={{ fontWeight: 600 }}>
                    {context.t("License number:")}
                  </span>
                  {recruitmentAgency.license_number}
                </p>
              )}
              {recruitmentAgency.license_valid_date && (
                <p>
                  <span style={{ fontWeight: 600 }}>
                    {context.t("Valid to:")}
                  </span>
                  {moment(recruitmentAgency.license_valid_date).format("LL")}
                </p>
              )}
              <p className="mt-2"> {description} </p>
              <div className="mt-2">
                <CompanyContacts
                  website={recruitmentAgency.website}
                  email={recruitmentAgency.email}
                  phone_numbers={recruitmentAgency.phone_numbers}
                  facebook={recruitmentAgency.facebook}
                  viber={recruitmentAgency.viber}
                  line={recruitmentAgency.line}
                />
              </div>
            </div>
            {recruitmentAgency.addresses.length > 0 ? (
              <div>
                {recruitmentAgency.addresses.map((address, i) => (
                  <Address
                    key={i}
                    name_en={recruitmentAgency.name_en} // used in a google maps search
                    street={address.street}
                    city={address.city_id}
                    state={address.state_id}
                    country={address.country_id}
                    zip={address.zip}
                    addressContacts={address.contacts}
                  />
                ))}
              </div>
            ) : (
              <div></div>
              // <p className="mt-3">
              //   {context.t("No Focal Person contact provided")}
              // </p>
            )}
            {/* <hr /> */}
            <div className="mt-3">
              <ShareButtons title={recruitmentAgency.name} />
            </div>
            <hr />
            {recruitmentAgency.rating_count && (
              <p className="rated-by-text">
                {context.t("Rated by ({number}) viewers", {
                  number: recruitmentAgency.rating_count,
                })}
                &nbsp;&nbsp;<i className="fa-regular fa-circle-check"></i>
              </p>
            )}
            {profile.data && profile.data.type === "JS" && (
              <div className="NavigationBar-icon">
                <i
                  className={`mt-4 far fa-${
                    showRateForm ? "eye" : "edit"
                  } fa-2x`}
                  style={{ color: "#198474" }}
                  onClick={() => setShowRateForm(!showRateForm)}
                ></i>
                <span>
                  {showRateForm
                    ? context.t("View rating")
                    : context.t("Give rating")}
                </span>
              </div>
            )}

            {showRateForm ? (
              <Rating
                group="Recruitment Agency"
                object_id={recruitmentAgency.id}
                content_type="recruitment agency profile"
                userRating={userRating}
                onSubmit={() => setShowRateForm(false)}
              />
            ) : (
              <DetailedRatings
                overall_score={recruitmentAgency.rating_score}
                scores_for_each_criteria={
                  recruitmentAgency.scores_for_each_criteria
                }
              />
            )}

            <Divider text={context.t("comments")} className="mt-4" />

            {raComments.length > 0 && (
              <div className="CommentThread">
                {raComments
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at)
                  )
                  .map((comment, index) => {
                    return (
                      <Comment
                        key={index}
                        ref={lastCommentRef}
                        commentActionsHandler={commentActionsHandler}
                        comment={comment}
                        onClickReply={handleClickReplyButton}
                        onSubmitReply={handleSubmitComment}
                      />
                    );
                  })}
              </div>
            )}
            <div style={{ marginTop: "1em" }}>
              {loading ? context.t("Loading..") : ""}
            </div>
            <Online>
              {profile && profile.data && profile.data.id ? (
                <CommentInput
                  replyToText={commentReply.text}
                  user_id={profile.data.id}
                  object_id={recruitmentAgency.id}
                  parent={commentReply.parent}
                  base_parent={commentReply.base_parent}
                  isReply={commentReply.show}
                  onSubmit={handleSubmitComment}
                  onCloseReply={handleCloseReply}
                  replyUsername={commentReply.username}
                  replyIsModerator={commentReply.isModerator}
                />
              ) : (
                <div className="mt-3">
                  <NavLink to="/login">
                    {context.t("Please login to write a comment..")}
                  </NavLink>
                </div>
              )}
            </Online>
            {profile.data && profile.data.type === "IS" && (
              <Button
                href={`${process.env.REACT_APP_SERVER_URL}/admin/users/user/${recruitmentAgency.id}/change`}
                target="_blank"
                className="mt-3 button-modified"
                style={{ backgroundColor: "teal", border: "none" }}
              >
                {context.t("Change in CMS")}
              </Button>
            )}
          </Container>
        </Container>
      )}
    </BaseView>
  );
}

RecruitmentAgenciesDetailedViewV3.contextTypes = {
  t: PropTypes.func.isRequired,
};
