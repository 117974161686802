import axios from 'axios';
import { getMyRosterApplications } from './SubmitResumeActions';
import { getRecruiterRoster } from './RecruiterRosterActions';
// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const EXTEND_ROSTER_APPLICATION_START = 'EXTEND_ROSTER_APPLICATION_START'
export const EXTEND_ROSTER_APPLICATION_SUCCESS = 'EXTEND_ROSTER_APPLICATION_SUCCESS'
export const EXTEND_ROSTER_APPLICATION_FAIL = 'EXTEND_ROSTER_APPLICATION_FAIL'

export const WITHDRAW_ROSTER_APPLICATION_START = 'WITHDRAW_ROSTER_APPLICATION_START'
export const WITHDRAW_ROSTER_APPLICATION_SUCCESS = 'WITHDRAW_ROSTER_APPLICATION_SUCCESS'
export const WITHDRAW_ROSTER_APPLICATION_FAIL = 'WITHDRAW_ROSTER_APPLICATION_FAIL'



// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// EXTEND
export const extendRosterApplicationStart = () => {
  return {
    type: EXTEND_ROSTER_APPLICATION_START
  }
}

export const extendRosterApplicationSuccess = (application) => {
  return {
    type: EXTEND_ROSTER_APPLICATION_SUCCESS,
    application: application
  }
}

export const extendRosterApplicationFail = (error) => {
  return {
    type: EXTEND_ROSTER_APPLICATION_FAIL,
    error: error
  }
}


export const extendRosterApplication = (rosterId) => {
  return (dispatch, getState) => {
    dispatch(extendRosterApplicationStart());

    return axios.get(`${process.env.REACT_APP_API_URL}/roster-applications/${rosterId}/extend/`)
      .then(res => {
        dispatch(extendRosterApplicationSuccess(res.data))
      })
      .catch(err => {
        dispatch(extendRosterApplicationFail(err))
        throw err
      })
  }
}

// WITHDRAW
export const withdrawRosterApplicationStart = () => {
  return {
    type: WITHDRAW_ROSTER_APPLICATION_START
  }
}

export const withdrawRosterApplicationSuccess = (detail) => {
  return {
    type: WITHDRAW_ROSTER_APPLICATION_SUCCESS,
    message: detail
  }
}

export const withdrawRosterApplicationFail = (error) => {
  return {
    type: WITHDRAW_ROSTER_APPLICATION_FAIL,
    error: error
  }
}

export const withdrawRosterApplication = (rosterId) => {
  return (dispatch, getState) => {
    dispatch(withdrawRosterApplicationStart())
    const userId = getState().ProfileState.data.id
    return axios.get(`${process.env.REACT_APP_API_URL}/roster-applications/${rosterId}/withdraw/`)
      .then(res => {
        dispatch(getRecruiterRoster(true))
        dispatch(getMyRosterApplications(userId))
      })
      .catch(err => {
        dispatch(withdrawRosterApplicationFail(err))
        throw err
      })
  }
}

export const withdrawAllRosterApplication = (jobseekerId) => {
  return (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/jobseekers/${jobseekerId}/withdraw-all-applications`)
      .then(res => {
        console.log("I with drew all", res)
      })
      .catch(err => {
        throw err
      })
  }
}

export const extendAllRosterApplication = (jobseekerId) => {
  return (dispatch, getState) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/jobseekers/${jobseekerId}/extend-all-roster-applications`)
      .then(res => {
        console.log("I extend all", res)
      })
      .catch(err => {
        throw err
      })
  }
}