import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Badge, Navbar, Col } from "react-bootstrap";
import { handleNavigate } from "../common/utilities/navigations";

export default function BottomNavigationBar(props, context) {
  const profile = useSelector((state) => state.ProfileState.data);

  const unreadNotificationsCount = (notificationType) => {
    const notifications =
      profile.notifications &&
      profile.notifications.filter(
        (notification) =>
          notification.unread && notification.type === notificationType
      );

    if (notifications && notifications.length > 0) {
      return notifications.length > 9 ? "+9" : notifications.length;
    }
  };

  return (
    <Navbar className="BottomNavigationBar">
      <Col style={{ padding: 2 }}>
        <NavLink
          exact
          to="/polls"
          activeClassName="menu-active"
          className="bottom-navigation-item"
        >
          <i className="fa-solid fa-poll fa-17x"></i>
          <span>{context.t("Polls")}</span>
        </NavLink>
      </Col>
      <Col style={{ padding: 2 }}>
        <NavLink
          exact
          to="/news"
          activeClassName="menu-active"
          className="bottom-navigation-item"
        >
          <Badge pill variant="danger" className="navigationItemCountBadge">
            {unreadNotificationsCount("CONTENT_ADDED_NEWS")}
          </Badge>
          <i className="fa-solid fa-newspaper fa-17x"></i>
          <span>{context.t("News")}</span>
        </NavLink>
      </Col>
      <Col style={{ padding: 2 }}>
        <NavLink
          exact
          to="/"
          activeClassName="menu-active"
          className="bottom-navigation-item"
          onClick={handleNavigate}
        >
          <i className="fa-solid fa-home fa-17x"></i>
          <span>{context.t("Home")}</span>
        </NavLink>
      </Col>
      <Col style={{ padding: 2 }}>
        <NavLink
          exact
          to="/guides"
          activeClassName="menu-active"
          className="bottom-navigation-item"
        >
          <i className="fa-solid fa-info-circle fa-17x"></i>
          <span>{context.t("Guide")}</span>
        </NavLink>
      </Col>
      {/* <Col style={{padding: 2}}>
        <NavLink exact to='/groups' activeClassName='menu-active' className='bottom-navigation-item'>
          <i className="fa-solid fa-comments fa-17x"></i>
          <span>{context.t('Groups')}</span>
        </NavLink>
      </Col> */}
      {/*<NavLink to='/groups' activeClassName='active'>
        <i className="NavigationBar-icon fa fa-comments fa-lg"></i> {context.t('Groups')}
      </NavLink>
      <NavLink to='/like' activeClassName='active'>
        <i className="NavigationBar-icon fa fa-heart fa-lg"></i> {context.t('Like')}
      </NavLink>
      <NavLink to='/notifications' activeClassName='active'>
        <i className="NavigationBar-icon fa fa-bell fa-lg"></i> {context.t('Notifications')}
      </NavLink>
      <NavLink to='/applied' activeClassName='active'>
        <i className="NavigationBar-icon fa fa-check-square fa-lg"></i> {context.t('Applied')}
      </NavLink>*/}
    </Navbar>
  );
}

BottomNavigationBar.contextTypes = {
  t: PropTypes.func.isRequired,
};
