import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import InfiniteScroll from 'react-infinite-scroller';
import Autosuggest from 'react-autosuggest';

import moment from 'moment';

import BaseView from '../BaseView';
import CommonContentListItem from '../../components/CommonContentListItem';
import FilterResultsNotFound from '../../components/FilterResultsNotFound';
import { getCountries } from '../../common/redux/actions/CountriesActions';
import { getNews } from '../../common/redux/actions/NewsActions';
import { setFilter, setSort, setSearch } from '../../common/redux/actions/FiltersSortersActions';

import {
  reactSelectDropdownStyleLeft,
  reactSelectDropdownStyleRight
} from '../../common/styles/dropdowns';
import { theme } from '../../common/styles/autosuggestTheme';
import { JOB_SEEKER_LANGUAGES } from "../../common/utilities/utilities";

const fuzzysort = require('fuzzysort')
const Rabbit = require("rabbit-node");


export default function NewsListView(props, context) {
  const dispatch = useDispatch()

  const countries = useSelector(state => state.CountriesState)
  const news = useSelector(state => state.NewsState)
  const profile = useSelector(state => state.ProfileState)
  const language = useSelector(state => state.i18nState.lang)

  // Filters and sorters
  const filters = useSelector(state => state.FiltersSortersState.filters.news)
  const sort = useSelector(state => state.FiltersSortersState.sort.news)
  const search = useSelector(state => state.FiltersSortersState.search.news)

  // React select dropdown options
  const [countriesOptions, setCountriesOptions] = useState([])

  // state
  const [componentState, setComponentState] = useState({
    filteredNews: [],
    infiniteNews: [],
    currentPage: 1,
    delayedSearch: search,
  })
  const [typingTimeout, setTypingTimeout] = useState(null)
  const [filteringResults, setFilteringResults] = useState(true)

  // references
  const sortRef = useRef()

  // call necessary API
  useEffect(() => {
    !countries.fetched && dispatch(getCountries())
    !news.fetched && dispatch(getNews())
  }, []);

  // populate react select countries options
  useEffect(() => {
    if (countries.fetched && news.fetched) {

      let availableCountries = new Set()
      news.items.map(n => {
        availableCountries = new Set([...availableCountries, ...n.countries])
      })

      let countriesArray = []
      for (let key in countries.items) {
        if ([...availableCountries].indexOf(countries.items[key].country) >= 0) {
          countriesArray.push(countries.items[key])
        }
      }

      countriesArray = [
        {value:0, label: context.t('All countries')},
        ...countriesArray.sort((a,b) => a.name > b.name? 1 : -1).map(item => {
          return {value: item.country, label: item.name}
        })
      ]
      setCountriesOptions(countriesArray)
    }
  }, [countries.items, news.items]);

  useEffect(() => {
    if (countries.items[profile.data.country] && filters.useDefaultCountry) {
      if (countriesOptions.filter(country => country.value === profile.data.country)[0]) {
        dispatch(setFilter('news', 'country', profile.data.country))
      }
    }
  }, [countries.items, profile, countriesOptions]);

  // filter and sort news
  useEffect(() => {
    if (news.fetched) {
      filterAndSortNews(news.items)
    }
  }, [news.items, sort, filters, search]);

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => {
      dispatch(setSearch('news', componentState.delayedSearch))
    }, 450))
  }, [componentState.delayedSearch])

  const handleSort = (data, event) => {
    const value = data.value;
    dispatch(setSort('news', value))
  }

  const handleSearch = event => {
    setComponentState({
      ...componentState,
      delayedSearch: event.target.value,
    });
  };

  const handleFilter = (data, event) => {
    const name = event.name
    const value = data.value
    dispatch(setFilter('news', name, value))

    if (filters.useDefaultCountry && name === 'country') {
      dispatch(setFilter('news', 'useDefaultCountry', false))
    }
  }

  const filterBySearch = (news, search) => {

    if (language === 'mm-z') {
      search = Rabbit.zg2uni(search)
    }

    const results = fuzzysort.go(search, news, {
      keys: ['title', 'title_mm_uni'],
      allowTypo: true,
    })
    return results.map(result => result.obj)
  }

  const filterByCountry = (news, country) => {
    return news.filter(news => news.countries.indexOf(country) >= 0)
  }

  const filterAndSortNews = (news) => {

    setFilteringResults(true)

    var result = [...news]

    // Filtering:
    if (search) {
      const searchString = search.toLowerCase()
      result = filterBySearch(result, searchString)
    }

    if (filters.country) {
      result = filterByCountry(result, filters.country)
    }

    // Sorting:
    if (sort === 'Date: Newest') {
      result.sort((a,b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    }

    else if (sort === 'Date: Oldest') {
      result.sort((a,b) => {
        return new Date(a.created_at) - new Date(b.created_at)
      })
    }

    else if (sort === 'Popularity') {
      result.sort((a,b) => {
        if (a.comments_count || b.comments_count) {
          return b.comments_count - a.comments_count
        }
        return a.title > b.title? 1 : -1
      })
    }

    if (JSON.stringify(componentState.filteredNews) !== JSON.stringify(result)) {
      setComponentState({
        ...componentState,
        filteredNews: result,
        infiniteNews: result.slice(0, 10),
      });
    }

    setFilteringResults(false)
  }

  const loadMoreNews = () => {
    const page = componentState.currentPage + 1
    setComponentState({
      ...componentState,
      currentPage: page,
      infiniteNews: componentState.filteredNews.slice(0, page*10)
    });
  }

  const unfocusInput = (e, ref) => {
    e.preventDefault();
    ref.current.focus();
    ref.current.blur();
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.title;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div>
      {`${suggestion.title.slice(0, 80)} ...`}
    </div>
  );

  const onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    props.history.push(`/news/${suggestion.id}`)
  }

  return (
    <BaseView title={context.t("News")} backurl="/">
      <hr className="afterTopNavigationBar"></hr>
      {news.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container>
          {language === JOB_SEEKER_LANGUAGES.ENGLISH && (
            <Row>
              <Col
                xs={12}
                lg={6}
                className={`english-news-message english-news-message_left`}
              >
                News is posted in Burmese, Khmer, and Nepali languages only.{" "}
              </Col>
              <Col
                xs={12}
                lg={6}
                className={`english-news-message english-news-message_right`}
              >
                The English UI will only display a copy of news items.
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={6} style={{ textAlign: "left" }}>
              <Select
                name="country"
                placeholder={context.t("Country")}
                value={{
                  value: filters.country,
                  label:
                    filters.country === 0
                      ? context.t("All countries")
                      : countries.items[filters.country].name,
                }}
                options={countriesOptions}
                onChange={handleFilter}
                isSearchable={false}
                styles={reactSelectDropdownStyleLeft}
              />
            </Col>
            <Col xs={6} style={{ textAlign: "left" }}>
              <Select
                name="sort"
                ref={sortRef}
                placeholder={context.t("Sort")}
                defaultValue={{ value: sort, label: context.t(sort) }}
                options={[
                  { value: "Date: Newest", label: context.t("Date: Newest") },
                  { value: "Date: Oldest", label: context.t("Date: Oldest") },
                  { value: "Popularity", label: context.t("Popularity") },
                ]}
                onChange={handleSort}
                isSearchable={false}
                styles={reactSelectDropdownStyleRight}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <p className="found-counter">
              {" "}
              {context.t("{number} found", {
                number: componentState.filteredNews.length,
              })}{" "}
            </p>
            <Col>
              <form onSubmit={(e) => unfocusInput(e, sortRef)}>
                <Autosuggest
                  suggestions={componentState.filteredNews.slice(0, 5)}
                  onSuggestionSelected={onSuggestionSelected}
                  onSuggestionsFetchRequested={() => {}}
                  onSuggestionsClearRequested={() => {}}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    placeholder: context.t("Search"),
                    value: componentState.delayedSearch,
                    onChange: handleSearch,
                  }}
                  theme={{
                    ...theme,
                    suggestionsContainerOpen:
                      "react-autosuggest__suggestions-container--open full",
                  }}
                />
              </form>
            </Col>
          </Row>
          <InfiniteScroll
            loadMore={loadMoreNews}
            hasMore={
              componentState.infiniteNews.length <
              componentState.filteredNews.length
            }
            loader={
              <div className="loader" key={0}>
                {context.t("Loading ...")}
              </div>
            }
          >
            <div className="mt-5">
              {componentState.infiniteNews.map((item, i) => {
                return (
                  <CommonContentListItem
                    key={i}
                    id={item.id}
                    translation_id={item.translation_id}
                    type="news"
                    title={item.title}
                    image={item.image}
                    video={item.video}
                    audio={item.audio}
                    created_at={moment(item.created_at).format("LL")}
                    comments_count={item.comments_count}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
          {!filteringResults && componentState.filteredNews.length === 0 && (
            <FilterResultsNotFound
              text={context.t(
                "Sorry, no news matched filter parameters, please try again."
              )}
            />
          )}
        </Container>
      )}
    </BaseView>
  );
}

NewsListView.contextTypes = {
  t: PropTypes.func.isRequired
}
