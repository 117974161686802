import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { useSelector } from "react-redux";
import { FaAward, FaUserTie, FaIndustry, FaRegFile } from "react-icons/fa";
import moment from 'moment';
import { Link } from "react-router-dom";
import { Row, Col, Image, Badge, Alert } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup'

import { JobApplicationStatuses } from '../common/utilities/choices';
import { addDefaultImageSrc } from '../common/utilities/utilities';
import { getJobStatusClassName } from "../common/utilities/utilities";


export default function JobListItemV2(props, context) {
  const cities = useSelector(state => state.CitiesState)
  const countries = props.countries;
  const industries = useSelector(state => state.IndustriesState)
  const jobApplicationStatuses = JobApplicationStatuses({}, context, false)
  const subindustries = useSelector(state => state.SubIndustriesState)
  const states = useSelector(state => state.StatesState)
  const profile = useSelector(state => state.ProfileState.data)

  const [applicationStatus, setApplicationStatus] = useState(null)

  useEffect(() => {
    let application = profile.applications && profile.applications.filter(application => application.job == props.job.id)[0]
    application && profile.id == application.jobseeker && setApplicationStatus(application.status)
  }, [profile])

  const unread = () => {
    if (profile.notifications) {
      let notifications = profile.notifications.filter(notification => (
        notification.unread
        && notification.type == 'NEW_JOB_POSTED'
        && notification.action_object_id == props.job.translation.id
      )).length

      return notifications > 0
    }
  }

  const jobStatusRenderer = (job) => {        
    if(props.job.is_closed){
      return (
        <Row style={{ textAlign: 'center', color: 'brown' }}>
        <Col xs={12}>
          <p>
            <i className="fas fa-exclamation-circle fa-md"></i> {context.t('Recruitment closed!')}
          </p>
        </Col>
      </Row>);
    }

    if(!props.job.is_closed && moment().isAfter(props.job.expired_at) || moment().isAfter(props.job.closed_at)){
      return (
        <Row style={{ textAlign: 'center', color: 'brown' }}>
        <Col xs={12}>
          <p>
            <i className="fas fa-exclamation-circle fa-md"></i> {context.t('Recruitment Expired!')}
          </p>
        </Col>
      </Row>);
    }
  } 

  return (
    <Link
      to={props.fromApplication ? `/jobs/${props.job.id}?prev=/job-applications` : `/jobs/${props.job.id}`}
    >
      <ListGroup.Item className={`Card mt-2 ${getJobStatusClassName(props.job)}`}
      style={{border:'1px solid gray', borderRadius: '9px', boxShadow: "0 5px 9px rgba(0, 0, 0, 0.2)"}}>
        {jobStatusRenderer(props.job)}
        <Row>
          <Col xs={8}>
            {
              props.job.job_title &&
              <h5 className={props.styles.header}> {props.job.job_title} </h5>
            }
            {props.job.translation && props.job.translation.title &&
              <h5 className={props.styles.header}> {props.job.translation.title} </h5>
            }
            <p className={props.styles.header}> {props.job.employer_workers_name || props.job.employer_name} </p>
            <p className={props.styles.label}>
              {props.job.employer_industry && subindustries.items[props.job.employer_industry]
                && `${industries.items[subindustries.items[props.job.employer_industry].industry_id].name}:
                  ${subindustries.items[props.job.employer_industry].name}
                 `}
            </p>
            {props.job.employer_country &&
              props.job.employer_state &&
              props.job.employer_city &&
              <p className={props.styles.label}> {countries.items[props.job.employer_country] && countries.items[props.job.employer_country].name},
                {states.items[props.job.employer_state] && states.items[props.job.employer_state].name},
                {cities.items[props.job.employer_city] && cities.items[props.job.employer_city].name} </p>
            }
            <p className={props.styles.label}>
              {props.job.terms_of_payment == 'Monthly' ?
                context.t('{salary} {currency}/month', { salary: props.job.salary, currency: props.currencies.items[props.job.currency] && props.currencies.items[props.job.currency].code })
                :
                context.t('{daily_wage_rate} {currency}/day', { daily_wage_rate: props.job.daily_wage_rate, currency: props.currencies.items[props.job.currency] && props.currencies.items[props.job.currency].code })
              }

              {props.job.have_employer_pays_policy &&
                <span style={{ display: 'block', paddingRight: 5, paddingTop: 5, color: 'darkblue' }}><FaAward />
                  &nbsp;{context.t('Have Employer Pays Policy')}
                </span>
              }
              <span style={{ display: 'block', paddingRight: 5, paddingTop: 5, color: '#198474' }}>
                {props.job.recruitment_agency ?
                  <><FaUserTie />&nbsp;{context.t('Recruitment Agency Job')} &nbsp;&nbsp;
                    <span>
                      {
                        props.job.recruitment_agency_name ? <span style={{color:"blue"}}>{props.job.recruitment_agency_name}</span> : ""
                      }
                    </span>
                    <br /></> :
                  <><FaIndustry />&nbsp;{context.t('Employer Job')}
                    <br /></>
                }
                {
                  props.job.job_poster_country && countries.items[props.job.job_poster_country] &&
                  <>
                    <FaRegFile />&nbsp;{context.t('Only job seekers in {country} can apply', { country: countries.items && countries.items[props.job.job_poster_country].name })}
                    <br />
                  </>
                }
              </span>
            </p>
          </Col>

          <Col xs={4} style={{ textAlign: 'right' }}>
            {unread() &&
              <Badge className='newContentBadge'>
                {context.t('new')}
              </Badge>
            }
            <Image
              src={props.job.emp_logo ? props.job.emp_logo : '/static/media/ImagePlaceholder.png'}
              crossOrigin='anonymous'
              style={{ width: 90, height: 90, boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)", objectFit: 'cover' }}
              onError={addDefaultImageSrc}
              roundedCircle
            />
          </Col>

        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            {applicationStatus && jobApplicationStatuses[applicationStatus] &&
              <Alert style={{ textAlign: 'center' }}
                className="hover-effect"
                variant={
                  applicationStatus == 'VIEWED' ? 'info'
                    :
                    ['ACCEPTED', 'OFFERED'].includes(applicationStatus) ? 'success'
                      :
                      ['REJECTED_BY_APPLICANT', 'REJECTED_BY_BUSINESS', 'DOCUMENTS_REQUESTED'].includes(applicationStatus) ? 'danger'
                        :
                        'primary'
                }
              >
                <strong>{jobApplicationStatuses[applicationStatus]}</strong>
              </Alert>
            }
          </Col>
        </Row>
      </ListGroup.Item>
    </Link >
  )
}

JobListItemV2.contextTypes = {
  t: PropTypes.func.isRequired
}
