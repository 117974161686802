import React, { useEffect } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import BaseView from '../BaseView';
import NotificationsListItemV2 from '../../components/NotificationsListItemV2';
import { getProfile } from "../../common/redux/actions/ProfileActions";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";

export default function NotificationsView(props, context) {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.ProfileState.data)
  const profileState = useSelector(state => state.ProfileState)
  const userId = useSelector(state => state.AuthenticationState.id)

  useEffect(() => {
    !profileState.fetched && !profileState.loading && dispatch(getProfile('JS', userId))
  }, [])

  return (
    <BaseView title={context.t('Notifications')} backurl='/'>
      <hr className='afterTopNavigationBarWithoutFilters'></hr>
      {profileState.loading ?
        <div style={{ display: 'flex', height: 'calc(100% - 118px)' }}>
          <img src={'/static/media/spinner.png'} alt='' className='LoadingSpinner' />
        </div>
        :
        <>
           {profile && profile.notifications && profile.notifications.length > 0 ?
              profile.notifications.map(notification => {
                return <NotificationsListItemV2 key={notification.id} notification={notification}/>
              })
           :
           <FilterResultsNotFound text={context.t('No notifications')} />
           }
        </>
      }
    </BaseView>
  )
}

NotificationsView.contextTypes = {
  t: PropTypes.func.isRequired
}
