import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import BaseView from '../BaseView';
import RecruiterRosterItem from '../../components/RecruiterRosterItem';
import useRecruiterRosterSearch from "../../common/hooks/useRecruiterRosterSearch";
import FilterModal from "../../components/Filter/FilterModal";
import FilterResultsNotFound from '../../components/FilterResultsNotFound';
import { useSelector, useDispatch } from "react-redux";
import styles from './RecruiterRosterViewV2.module.css';
import { getMyRosterApplications } from "../../common/redux/actions/SubmitResumeActions";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";

export default function RecruiterRosterViewV2(props, context) {
    // dispatch
    const dispatch = useDispatch();
    // Query and Cursor
    const [query, setQuery] = useState('');
    const [cursor, setCursor] = useState('');
    //resolve duplicated ra from searching query
    const [ras, setRas] = useState([]);
    const language = useSelector(state => state.i18nState.lang);
    //my roster applications
    const myRosterApplications = useSelector(state => state.SubmitResumeState.rosterApplications);
    const [applications, setApplications] = useState([]);
    // profile
    const profile = useSelector(state => state.ProfileState);

    // filter sorter state & handler for Filter Modal 
    const [filterSorter, setFilterSorter] = useState({
        country: '',
        sort: '',
        search: '',
    });

    // get window width height
    const { height, width } = useWindowDimensions();
    // check reset state
    const [reset,setReset] = useState(false)

    // call back function for child component Filter Modal
    const handleFilterSorter = (updated) => {
        setFilterSorter({
            ...filterSorter,
            ...updated
        })
    }

    const filterSoterReseter = () => {
        setFilterSorter({
            country: '',
            sort: '-popularity',
            search: '',
        })
    }

    const { next, recruiterRoster, hasMore, loading, error } = useRecruiterRosterSearch(query, cursor);

    const observer = useRef()

    const lastRecruiterElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && next.current) {
                setCursor(next.current)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    useEffect(() => {
        // check saved settings
        const savedFilterSorter = localStorage.getItem('SAVED_FILTER_SORTER_RECRUITER') ? localStorage.getItem('SAVED_FILTER_SORTER_RECRUITER') : false
        if (savedFilterSorter) {
            let decodedFilterSorter = JSON.parse(savedFilterSorter)
            setFilterSorter({ ...filterSorter, ...decodedFilterSorter, search: '' })
        }
        // get my roster applications
        if (profile && profile.data && profile.data.id) {
            !myRosterApplications.fetching && dispatch(getMyRosterApplications(profile.data.id))
        }
    }, [])


    // initial method for all filter sorter function
    useEffect(() => {
        if (myRosterApplications.fetched) {
            setApplications(myRosterApplications.itemsArray);
        }
    }, [myRosterApplications])

    // initial method for all filter sorter function
    useEffect(() => {
        if (filterSorter.country != '' || filterSorter.sort != '') {
            localStorage.setItem('SAVED_FILTER_SORTER_RECRUITER', JSON.stringify(filterSorter))
        }

        setQuery({ search: filterSorter.search, filter: filterSorter.country, sort: filterSorter.sort })

        // check reset state
        if(filterSorter.country == '' && filterSorter.sort == '-popularity') {
            setReset(false)
        }else{
            setReset(true)
        }

    }, [filterSorter])

    useEffect(() => {
        const uniqueRAs = Array.from(new Set(recruiterRoster.map(r => r.id)))
            .map(id => {
                return recruiterRoster.find(r => r.id === id)
            })
        setRas(uniqueRAs)
    }, [recruiterRoster])

    const filterApplication = (recruiter_id) => {
        if (recruiter_id && applications.length > 0) {
            const applicationDetail = applications.find(application => application.recruitment_agency === recruiter_id);
    
            return applicationDetail || null;
        }
        
        return null;
    };

   // adjust the margin top of the list
   const handleMarginTop = (width) => {
        if(width> 991){
            return "100px";
        }else{
            // check reset state
            if(reset){
                return "140px";
            }else{
                return "104px";
            }
        }
    }
    return (
        <BaseView title={context.t('Recruiter Roster')} backurl='/' >
            <hr className='afterTopNavigationBarTwo'></hr>
            {
                <>
                    <Container>
                        <FilterModal
                            type="recruiter-roster"
                            handleFilterSorter={handleFilterSorter}
                            filterSorter={filterSorter}
                            resethandler={filterSoterReseter}
                        />
                    </Container>
                    <Container style={{marginTop:handleMarginTop(width)}}>
                        <Row>
                            {ras.map((item, index) => {
                                if (ras.length === index + 1) {
                                    return (
                                        <Col xs={12} md={12} lg={12} key={index} ref={lastRecruiterElementRef}>
                                            <RecruiterRosterItem
                                                agency={item}
                                                id={item.id}
                                                application={filterApplication(item.id)}
                                                lang={language}
                                                recruiter={item}
                                                styles={styles}
                                            />
                                        </Col>
                                    )
                                } else {
                                    return (
                                        <Col xs={12} md={12} lg={12} key={index}>
                                            <RecruiterRosterItem
                                                id={item.id}
                                                agency={item}
                                                application={filterApplication(item.id)}
                                                lang={language}
                                                recruiter={item}
                                                styles={styles}
                                            />
                                        </Col>
                                    )
                                }

                            })
                            }
                        </Row>
                        <div className="mt-4">
                            {loading ? context.t('Loading..') : ''}
                        </div>
                    </Container>
                    {ras.length == 0 && !loading &&
                        <div className="mt-4">
                            <FilterResultsNotFound text={context.t('Sorry, no recruitment agencies matched filter parameters, please try again.')} />
                        </div>
                    }
                </>
            }
        </BaseView >
    );
}

RecruiterRosterViewV2.contextTypes = {
    t: PropTypes.func.isRequired
}
