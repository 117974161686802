import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Online } from "react-detect-offline";
import { connect } from 'react-redux';
import './AccountNavButton.css';
import { Image } from 'react-bootstrap';
import { addDefaultImageSrc } from "../common/utilities/utilities";

const AccountNavButton = (props, context) => {
    return (
        <>
            <div className="account-nav-button-container">                            
                <div className="registration-text">
                {props.isAuthenticated ? props.profile.username : context.t('Click here for Registration.')}
                 &nbsp;&nbsp;
                   <i className="fas fa-arrow-right"></i>
                 &nbsp;&nbsp;
                </div>
                <div className="profile-panel">
                        <Online>
                            <Link to={props.isAuthenticated ? '/profile' : '/login'}>
                                        {props.profile.avatar ?
                                          <div 
                                            className="circle-container"
                                           >
                                            <Image 
                                                onError={addDefaultImageSrc}
                                                src={props.profile.avatar}
                                                className="circle-image"/>
                                        </div>
                                :
                                <i className="fas fa-user-circle fa-lg" style={{ color: 'white' }}></i>}
                            </Link>
                        </Online>
                </div>
            </div>
        </>
    );
}

AccountNavButton.contextTypes = {
    t: PropTypes.func.isRequired
}

const mapStateToProps = state => {
    return {
        profile: state.ProfileState.data,
        isAuthenticated: state.AuthenticationState.token != null,
    }
}

export default connect(mapStateToProps)(AccountNavButton);
