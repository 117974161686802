import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import axios from 'axios';
import { FaAward } from "react-icons/fa";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Card, Container, Form, Row, Col, Button, Image, Overlay, Popover, Badge } from 'react-bootstrap';
import moment from 'moment';
import { toast } from "react-toastify"

import BaseView from '../../BaseView';

// import { addDefaultImageSrc } from '../../../common/utilities/utilities';
import { getCities } from '../../../common/redux/actions/CitiesActions';
import { getCountries } from '../../../common/redux/actions/CountriesActions';
import { getCurrencies } from '../../../common/redux/actions/CurrenciesActions';
import { getJobs } from '../../../common/redux/actions/JobsActions';
import { getJobBenefits } from '../../../common/redux/actions/JobBenefitsActions';
import { getJobBenefitCategories } from '../../../common/redux/actions/JobBenefitCategoriesActions'
import { getJobPositionCategories } from '../../../common/redux/actions/JobPositionCategoriesActions';
import { getIndustries } from '../../../common/redux/actions/IndustriesActions';
import { getRejectionReasons } from '../../../common/redux/actions/RejectionReasonsActions';
import { getSubIndustries } from '../../../common/redux/actions/SubIndustriesActions';
import { getStates } from '../../../common/redux/actions/StatesActions';
import { applyJob } from '../../../common/redux/actions/ProfileActions';
// import { readNotification } from '../../../common/redux/actions/ProfileActions';


export default function ScreeningView(props, context) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { jobId } = useParams()

  const cities = useSelector(state => state.CitiesState)
  const countries = useSelector(state => state.CountriesState)
  const currencies = useSelector(state => state.CurrenciesState)
  const jobs = useSelector(state => state.JobsState)
  const jobBenefits = useSelector(state => state.JobBenefitsState)
  const jobBenefitCategories = useSelector(state => state.JobBenefitCategoriesState)
  const jobPositionCategories = useSelector(state => state.JobPositionCategoriesState)
  const industries = useSelector(state => state.IndustriesState)
  const language = useSelector(state => state.i18nState.lang)
  const profile = useSelector(state => state.ProfileState.data)
  const rejectionReasons = useSelector(state => state.RejectionReasonsState)
  const subindustries = useSelector(state => state.SubIndustriesState)
  const states = useSelector(state => state.StatesState)

  const [payload, setPayload] = useState({
    know_someone_working_here: false,
    think_can_do_the_job_well: true,
    allowed_to_view_documents: false,
    remark: '',
  })

  const [job, setJob] = useState(null)
  const [applyingJob, setApplyingJob] = useState(false)
  const [showPopover, setShowPopover] = useState(false)

  // References
  const popoverTarget = useRef(null);

  useEffect(() => {
    !cities.fetched && !cities.loading && dispatch(getCities())
    !countries.fetched && !countries.loading && dispatch(getCountries())
    !currencies.fetched && !currencies.loading && dispatch(getCurrencies())
    !jobs.fetched && !jobs.fetching && dispatch(getJobs())
    !jobBenefits.fetched && dispatch(getJobBenefits())
    !jobBenefitCategories.fetched && dispatch(getJobBenefitCategories())
    !jobPositionCategories.fetched && dispatch(getJobPositionCategories())
    !industries.fetched && !industries.loading && dispatch(getIndustries())
    !rejectionReasons.fetched && !rejectionReasons.loading && dispatch(getRejectionReasons())
    !subindustries.fetched && !subindustries.loading && dispatch(getSubIndustries())
    !states.fetched && !states.loading && dispatch(getStates())
  }, [])

  useEffect(() => {
    if (jobs.fetched) {
      let job = jobs.items.filter(job => job.id == jobId)[0]
      job ? setJob(job) : props.history.push('/not-found')
    }
  }, [jobs.items]);

  const handleApply = () => {

    // interupt the submission and show consent missing popover
    // if no consent was given
    if (!payload.allowed_to_view_documents) {
      setShowPopover(true);
      return;
    }

    if (profile.resume) {
      setApplyingJob(true)

      let data = {
        job: jobId,
        jobseeker: profile.id,
        resume: profile.resume.id,
        ...payload
      }

      dispatch(applyJob(data)).then(
        res => {
          toast.success(context.t('Successfully applied'));
          history.push('/jobs')
        }
      ).catch(
        err => toast.error(context.t("Couldn't apply due to server error"))
      ).finally(
        () => setApplyingJob(false)
      )

    } else {
      toast.error(context.t("Please create your CV first"))
    }
  }

  return (
    <BaseView title={context.t('Screening')} backurl={`/jobs/${jobId}`}>
      <hr className='afterTopNavigationBar'></hr>
      {jobs.fetching || industries.loading || subindustries.loading ||
        currencies.loading || cities.loading || countries.loading || states.loading ||
        jobBenefits.loading ?
        <div style={{ display: 'flex', height: 'calc(100% - 118px)' }}>
          <img src={'/static/media/spinner.png'} alt='' className='LoadingSpinner' />
        </div>
        :
        <Container style={{ textAlign: 'left' }}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label style={{ display: 'block' }}>{context.t('Do you know someone working here?')}</Form.Label>
                <Form.Check
                  type='radio'
                  id='know-someone-radio-1'
                  name='know_someone_working_here'
                  label={context.t("No, I don't know")}
                  defaultChecked
                  onChange={() => setPayload({ ...payload, know_someone_working_here: false })}
                  inline
                />
                <Form.Check
                  type='radio'
                  id='know-someone-radio-2'
                  name='know_someone_working_here'
                  label={context.t('Yes, I know someone')}
                  onChange={() => setPayload({ ...payload, know_someone_working_here: true })}
                  inline
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ display: 'block' }}>{context.t('After going through the job description, do you think you can do the job well?')}</Form.Label>
                <Form.Check
                  type='radio'
                  id='think-can-do-the-job-well-radio-1'
                  name='think_can_do_the_job_well'
                  label={context.t('No, I am not sure')}
                  onChange={() => setPayload({ ...payload, think_can_do_the_job_well: false })}
                  inline
                />
                <Form.Check
                  type='radio'
                  id='think-can-do-the-job-well-radio-2'
                  name='think_can_do_the_job_well'
                  label={context.t('Yes, I think so')}
                  onChange={() => setPayload({ ...payload, think_can_do_the_job_well: true })}
                  inline
                  defaultChecked
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ display: 'block' }}>{context.t('This employer requires documents and they may ask to look at them, do you understand this and consent to letting them look at my documents or request my documents in applying for this job?')}</Form.Label>
                <Form.Check
                  type='radio'
                  id='allowed-to-view-documents-radio-1'
                  name='allowed_to_view_documents'
                  label={context.t("No, I don't")}
                  onChange={() => setPayload({ ...payload, allowed_to_view_documents: false })}
                  inline
                  defaultChecked
                />
                <Form.Check
                  type='radio'
                  id='allowed-to-view-documents-radio-2'
                  name='allowed_to_view_documents'
                  label={context.t('Yes, I do')}
                  onChange={() => setPayload({ ...payload, allowed_to_view_documents: true })}
                  inline
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>{context.t('Remark')}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="remark"
                  value={payload.remark}
                  placeholder={context.t('Enter remark')}
                  onChange={event => setPayload({ ...payload, remark: event.target.value })}
                />
              </Form.Group>

              <Button
                ref={popoverTarget}
                onClick={handleApply}
                style={{ width: '100%' }}
                className="button-modified"
              >
                {applyingJob ? context.t('Loading..') : context.t('Apply')}
              </Button>

              <Button
                variant='light'
                onClick={() => history.push('/jobs')}
                style={{ width: '100%', marginTop: 8 }}
                className="button-modified"
              >
                {context.t('Back')}
              </Button>

              <Overlay
                show={showPopover}
                target={popoverTarget.current}
                onHide={() => setShowPopover(false)}
                onExit={() => setShowPopover(false)}
                rootClose
              >
                <Popover>
                  <Popover.Title as="h4">{context.t('No consent was given')}</Popover.Title>
                  <Popover.Content>
                    {context.t('Sorry! Employers need to see your documents to confirm you are eligible for the job. The documents required by employers may vary, so you are encourage to share the document you have, so that the employer can check if you are eligible. Is that ok for you?')}
                  </Popover.Content>
                </Popover>
              </Overlay>
            </Col>
          </Row>

        </Container>
      }
    </BaseView>
  )
}

ScreeningView.contextTypes = {
  t: PropTypes.func.isRequired
}
