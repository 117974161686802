import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap';
import BaseView from '../BaseView';
import useEmployersSearch from "../../common/hooks/useEmployersSearch";
import FilterModal from "../../components/Filter/FilterModal";
import FilterResultsNotFound from '../../components/FilterResultsNotFound';
import EmployersListItem from "../../components/EmployersListItem";
import './EmployersListViewV2.css'
import useWindowDimensions from "../../common/hooks/useWindowDimensions";

export default function EmployersListViewV2(props, context) {
    // Query and Cursor
    const [query, setQuery] = useState('')
    const [cursor, setCursor] = useState('')
    const [filter, setFilter] = useState('')
    const [sort, setSort] = useState('')
    //resolve duplicated ra from searching query
    const [emps, setEmps] = useState([])
    
    // get window width height
    const { height, width } = useWindowDimensions();
    // check reset state
    const [reset,setReset] = useState(false)

    // filter sorter state & handler for Filter Modal 
    const [filterSorter, setFilterSorter] = useState({
        country: '',
        sort: '',
        search: '',
        industry: ''
    })

    // reset status handler for child component
    const handleReset = (value) => {
        setReset(value)
    }

    // call back function for child component Filter Modal
    const handleFilterSorter = (updated) => {
        setFilterSorter({
            ...filterSorter,
            ...updated
        })
    }

    const filterSoterReseter = () => {
        setFilterSorter({
            country: '',
            sort: '-popularity',
            search: '',
            industry: ''
        })
    }


    const { next, employers, hasMore, loading, error } = useEmployersSearch(query, cursor)

    const observer = useRef()

    const lastRecruiterElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore && next.current) {
                setCursor(next.current)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    useEffect(() => {
        // check saved settings
        const savedFilterSorter = localStorage.getItem('SAVED_FILTER_SORTER_EMPLOYER') ? localStorage.getItem('SAVED_FILTER_SORTER_EMPLOYER') : false
        if (savedFilterSorter) {
            let decodedFilterSorter = JSON.parse(savedFilterSorter)
            setFilterSorter({ ...filterSorter, ...decodedFilterSorter, search: '' })
        }
    }, [])


    // initial method for all filter sorter function
    useEffect(() => {
        if (filterSorter.country != '' || filterSorter.sort != '' || filterSorter.industry != '') {
            localStorage.setItem('SAVED_FILTER_SORTER_EMPLOYER', JSON.stringify(filterSorter))
        }

        setQuery({ search: filterSorter.search, filter: filterSorter.country, sort: filterSorter.sort, industry: filterSorter.industry })

        // check reset state
        if(filterSorter.country == '' && filterSorter.sort == '-popularity' && filterSorter.industry == ''){
            setReset(false)
        }else{
            setReset(true)
        }

    }, [filterSorter])

    useEffect(() => {
        const uniqueEmps = Array.from(new Set(employers.map(r => r.id)))
            .map(id => {
                return employers.find(r => r.id === id)
            })
        setEmps(uniqueEmps)
    }, [employers])

    // adjust the margin top of the list
    const handleMarginTop = (width) => {
        if(width> 991){
             return "100px";
        }else{
            // check reset state
            if(reset){
                return "140px";
            }else{
                return "104px";
            }
        }
    }

    return (
        <BaseView title={context.t('Employers')} backurl='/' >
            <hr className='afterTopNavigationBarTwo'></hr>
            {
                <>
                    <Container>
                        <FilterModal
                            handleReset={(value) => handleReset(value)}
                            type="employer"
                            employers={employers}
                            handleFilterSorter={handleFilterSorter}
                            filterSorter={filterSorter}
                            resethandler={filterSoterReseter}
                        />
                    </Container>
                    <Container style={{marginTop:handleMarginTop(width)}}>
                        <Row>
                            {emps.map((item, index) => {
                                if (emps.length === index + 1) {
                                    return (
                                        <Col xs={6} sm={4} md={3} lg={2} key={index} ref={lastRecruiterElementRef}>
                                            <EmployersListItem
                                                id={item.id}
                                                employer={item}
                                            />
                                        </Col>
                                    )
                                } else {
                                    return (
                                        <Col xs={6} sm={4} md={3} lg={2} key={index}>
                                            <EmployersListItem
                                                id={item.id}
                                                employer={item}
                                            />
                                        </Col>
                                    )
                                }

                            })
                            }
                        </Row>
                        <div className="mt-4">
                             {loading ? context.t('Loading..') : ''}
                        </div>
                    </Container>
                    {emps.length == 0 && !loading &&
                        <div className="mt-4">
                            <FilterResultsNotFound text={context.t('Sorry, no employers matched filter parameters, please try again.')} />
                        </div>
                    }
                </>
            }
        </BaseView >
    );
}

EmployersListViewV2.contextTypes = {
    t: PropTypes.func.isRequired
}
