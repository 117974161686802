import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify"
import Select from 'react-select';
import { Container, Row, Col, Form, Button, Image, OverlayTrigger, Popover, Spinner, Modal } from 'react-bootstrap';

import BaseView from '../BaseView';
import Divider from '../../components/Divider';
import { deleteResume, createCustomResume, deleteCustomResume } from '../../common/redux/actions/ProfileActions';



export default function JobseekersResumeView(props, context) {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const uploadResumeInputRef = useRef()
  const language = useSelector(state => state.i18nState.lang)
  const profile = useSelector(state => state.ProfileState.data)
  const resume = useSelector(state => state.ProfileState.resume)

  const [uploadResume, setUploadResume] = useState({
    showModal: false,
    validated: false,
    payload: {
      file: null,
      name: null
    }
  })

  const [uploadToBeDeleted, setUploadToBeDeleted] = useState(null)

  const handleDeleteResume = () => {
    dispatch(deleteResume(profile.resume.id)).then(
      res => toast.success(context.t('Resume has been successfuly deleted'))
    ).catch(
      err => toast.error(context.t('Resume has not been deleted due to server error'))
    ).finally(
      document.body.click()
    )
  }

  const handleDeleteCustomResume = () => {
    dispatch(deleteCustomResume(uploadToBeDeleted)).then(
      res => toast.success(context.t('Resume has been successfuly deleted'))
    ).catch(
      err => toast.error(context.t('Resume has not been deleted due to server error'))
    ).finally(
      () => {
        setUploadToBeDeleted(null)
        document.body.click()
      }
    )
  }

  const handleOpenUploadResumeModal = () => {
    setUploadResume({ ...uploadResume, showModal: true })
  }

  const handleCloseUploadResumeModal = () => {
    setUploadResume({
      showModal: false,
      validated: false,
      payload: {
        file: null,
        name: null
      }
    })
  }

  const handleChangeUploadResumeFile = (event) => {
    var ext = event.target.files[0] && event.target.files[0].name.match(/\.([^\.]+)$/)[1]
    if (ext && ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png'].indexOf(ext) < 0) {
      toast.error(context.t('Please use one of the following formats: .jpeg, .jpg, .png, .pdf, .doc, .docx, .xls or .xlsx'), 10)
      uploadResumeInputRef.current.value = ''
    } else {
      setUploadResume({
        ...uploadResume,
        payload: {
          ...uploadResume.payload,
          file: event.target.files[0],
        }
      })
    }
  }

  const handleChangeUploadResumeName = (e) => {
    const value = e.target.value
    setUploadResume({
      ...uploadResume,
      payload: {
        ...uploadResume.payload,
        name: value
      }
    })
  }

  const handleUploadResume = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(createCustomResume(uploadResume.payload)).then(
        res => {
          toast.success(context.t('Resume has been successfuly uploaded'));
          handleCloseUploadResumeModal()
        }
      ).catch(
        err => toast.error(context.t('Resume has not been uploaded due to server error'))
      )
    }

    setUploadResume({
      ...uploadResume,
      validated: true
    })
  };

  const handleCreateResume = () => {
    history.push({
      pathname: '/resume-edit',
      search: location.search
    })
  }

  const deleteConfirmation = (
    <Popover>
      <Popover.Title as="h6">{context.t('Confirm deletion')}</Popover.Title>
      <Popover.Content>
        <p> {context.t('Are you sure you would like to delete your CV?')} </p>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              size='sm'
              style={{ width: '100%' }}
              onClick={() => document.body.click()}
            >
              {context.t('Cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              variant="danger"
              size='sm'
              style={{ width: '100%' }}
              disabled={resume.loading}
              onClick={handleDeleteResume}
            >
              {resume.loading &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: 8 }}
                />
              }
              {context.t('Delete')}
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  const deleteUploadConfirmation = (
    <Popover>
      <Popover.Title as="h6">{context.t('Confirm deletion')}</Popover.Title>
      <Popover.Content>
        <p> {context.t('Are you sure you would like to delete your uploaded CV?')} </p>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              size='sm'
              style={{ width: '100%' }}
              onClick={() => document.body.click()}
            >
              {context.t('Cancel')}
            </Button>
          </Col>
          <Col>
            <Button
              variant="danger"
              size='sm'
              style={{ width: '100%' }}
              disabled={resume.loading}
              onClick={handleDeleteCustomResume}
            >
              {resume.loading &&
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: 8 }}
                />
              }
              {context.t('Delete')}
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  )

  return (
    <BaseView title={context.t('Resume')} backurl='/marketplace' >
      <Container className='afterTopNavigationBar'>
        <Row style={{ textAlign: 'left' }}>
          <Col>
            <div className='mt-4' />
            <Divider text={context.t('Golden Dreamer CV')} style={{ fontWeight: 500 }} />
            {profile.resume ?
              <Row>
                <Col>
                  <Link to='resume-view'>
                    <Button variant="outline-secondary" className="button-modified" style={{ width: '100%' }}><i className="fas fa-eye fa-sm"></i> {context.t('View')} </Button>
                  </Link>
                </Col>
                <Col>
                  <Link to='resume-edit'>
                    <Button variant="outline-secondary" className="button-modified" style={{ width: '100%' }}><i className="fas fa-pen fa-sm"></i> {context.t('Edit')} </Button>
                  </Link>
                </Col>
                {/*
                <Col>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={deleteConfirmation}
                    rootClose
                  >
                    <Button variant="danger" style={{width: '100%'}}><i className="fas fa-trash-alt fa-sm"></i> {context.t('Delete')} </Button>
                  </OverlayTrigger>
                </Col>
                */}
              </Row>
              :
              <div>
                <p style={{ fontSize: 'small' }}>
                  {context.t("We encourage you to create a Golden Dreamer CV to show your educational background, work experience, and skills you've mastered.")}
                </p>
                <p style={{ fontSize: 'small' }}>
                  {context.t("It is a short document that gives you a chance to show a prospective employer the best of what you've got. It highlights your skills and experience and shows them you're the right person for the job. It takes only a few minutes to create one.")}
                </p>
                <p style={{ fontSize: 'small' }}>
                  {context.t("Once you have created your CV, you can use it to apply for many job opportunities. Good luck with your job application!")}
                </p>
                <Button
                  variant="outline-secondary"
                  style={{ width: '100%' }}
                  className="button-modified"
                  onClick={handleCreateResume}
                >
                  <i className="fas fa-plus fa-sm"></i> {context.t('Start creating my CV')}
                </Button>
              </div>
            }
          </Col>
        </Row>

        {/*
        <Row className='mt-4' style={{textAlign: 'left'}}>
          <Col>
            <Divider text='Uploaded CVs' style={{fontWeight: 500}}/>
            {profile.resume_uploads && profile.resume_uploads.map((resume, key) =>
              <Row key={key}>
                <Col xs={9}>
                  <p> {resume.name} </p>
                </Col>
                <Col style={{textAlign: 'right', paddingLeft: 0}}>
                  <Button
                    size='sm'
                    style={{padding: '2px 5px', margin: 2}}
                    variant='outline-primary'
                    href={resume.file}
                    target='__blank'
                  >
                    <i className="far fa-eye"></i>
                  </Button>
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={deleteUploadConfirmation}
                    rootClose
                  >
                    <Button
                      size='sm'
                      style={{padding: '2px 7px', margin: 2}}
                      variant='outline-danger'
                      onClick={() => setUploadToBeDeleted(resume.id)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            )}
            <Button
              variant="outline-secondary"
              style={{width: '100%'}}
              onClick={handleOpenUploadResumeModal}
            >
              <i className="fas fa-plus fa-sm"></i> {context.t('Upload custom CV')}
            </Button>
            <Modal
              show={uploadResume.showModal}
              onHide={handleCloseUploadResumeModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title as='h5'> Upload custom resume </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form noValidate validated={uploadResume.validated} onSubmit={handleUploadResume}>
                  <Form.Group controlId='file'>
                    <Form.Control
                      type="file"
                      accept=".jpeg,.jpg,.png,.pdf,.doc,.docx,.xls,.xlsx"
                      onChange={handleChangeUploadResumeFile}
                      ref={uploadResumeInputRef}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {context.t('Please choose a CV file')}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {uploadResume.payload.file &&
                    <Form.Group controlId='Name'>
                      <Form.Label>* {context.t('CV name')}</Form.Label>
                      <Form.Control
                        placeholder={context.t('Enter name of your CV')}
                        onChange={handleChangeUploadResumeName}
                        style={{height: 30}}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {context.t('Please enter name of your CV')}
                      </Form.Control.Feedback>
                    </Form.Group>
                  }
                  <Row>
                    <Col>
                      <Button
                        variant="outline-secondary"
                        style={{width: '100%'}}
                        onClick={handleCloseUploadResumeModal}
                      >
                        Close
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        type="submit"
                        style={{width: '100%'}}
                        disabled={resume.loading}
                      >
                        {resume.loading &&
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{marginRight: 8}}
                          />
                        }
                        Upload
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
        */}
      </Container>
    </BaseView>
  )
}

JobseekersResumeView.contextTypes = {
  t: PropTypes.func.isRequired
}
