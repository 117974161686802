import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'

import Select from 'react-select';

import { getIndustries } from '../../common/redux/actions/IndustriesActions';
import { reactSelectDropdownStyle } from '../../common/styles/dropdowns';


const IndustrySelect = (props, context) => {
  const dispatch = useDispatch()

  const industries = useSelector(state => state.IndustriesState)

  const [industryOptions, setIndustryOptions] = useState([])
  const [selectedIndustry, setSelectedIndustry] = useState(props.initialValue)

  useEffect(() => {
    !industries.fetched && dispatch(getIndustries())
  }, []);

  useEffect(() => {
    setSelectedIndustry(props.initialValue)
  }, [props.initialValue])

  useEffect(() => {
    const industryOptions = Object.keys(industries.items).sort(
      (a, b) => industries.items[a].name > industries.items[b].name ? 1 : -1).map(key => {
        return { value: industries.items[key].industry, label: industries.items[key].name }
      })
    setIndustryOptions(industryOptions)
  }, [industries]);

  const handleIndustryChange = (data, event) => {
    const value = data ? (props.isMulti ? data.map(industry => industry.value) : data.value) : (props.isMulti ? [] : null)
    setSelectedIndustry(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <Select
      name='industry'
      options={industryOptions}
      value={
        props.isMulti ?
          industryOptions.filter(o => selectedIndustry.indexOf(o.value) >= 0)
          :
          industryOptions.filter(o => o.value === selectedIndustry)[0]
      }
      placeholder={props.placeholder}
      style={{ textAlign: 'center' }}
      onChange={handleIndustryChange}
      styles={reactSelectDropdownStyle}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
    />
  )
}

IndustrySelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default IndustrySelect;
