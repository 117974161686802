import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';

import { Row, Form, Col, FormControl, Button, Modal } from 'react-bootstrap';

import { postProblem } from '../../common/redux/actions/ProblemsActions'

export default function NewProblemModal(props, context) {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.ProfileState.data)

  const [show, setShow] = useState(false)
  const [payload, setPayload] = useState({
    worker: profile.id,
    description: ''
  })

  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const isValid = form.checkValidity()

    if (isValid) {
      setLoading(true);
      dispatch(postProblem(payload))
      .then(() => {
        setShow(false);
        setValidated(false);
        clearPayload();
      })
      .finally(() => setLoading(false))
    }

    setValidated(true);
  };

  const handleChange = event => {
      const name = event.target.name;
      const value = event.target.value;
      setPayload({...payload, [name]: value})
  }

  const clearPayload = () => {
    setPayload({...payload, phone_number: '', description: ''})
  }

  return (
    <div>
      <Button
        variant="link"
        size='sm'
        style={{lineHeight: 'initial', fontSize: '0.8rem'}}
        onClick={() => setShow(true)}
      >
        {context.t('Share a problem')}
      </Button>
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Header closeButton>
            <Modal.Title style={{fontSize:'1rem'}}> <b> {context.t('Share a problem')}</b> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>*{context.t('Problem Description')}</Form.Label>
                <Form.Control
                  name="description"
                  required
                  value={payload.description}
                  as="textarea"
                  rows="3"
                  placeholder={context.t('Please describe the issue you are facing')}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid"> {context.t('Please describe your issue')} </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Row>
              <Col>
                <Button variant="secondary" onClick={() => setShow(false)}>
                  {context.t('Close')}
                </Button>
              </Col>
              <Col style={{textAlign: 'right'}}>
                <Button variant="primary" type='submit' disabled={loading}>
                  {loading? context.t('Loading..') : context.t('Submit a problem')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

NewProblemModal.contextTypes = {
  t: PropTypes.func.isRequired
}
