import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

// import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
// import Tabs from 'react-bootstrap/Tabs'
// import Tab from 'react-bootstrap/Tab'

import { Online } from "react-detect-offline";

import BaseView from '../BaseView';
import NewProblemModal from './NewProblemModal';
import ProblemListItem from './ProblemListItem';

import { getProblems } from '../../common/redux/actions/ProblemsActions';
// import { addDefaultImageSrc } from '../../common/utilities/utilities';


export default function ProblemsView(props, context) {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.ProfileState.data)
  const problems = useSelector(state => state.ProblemsState)

  const [showResolved, setShowResolved] = useState(false)
  const [filteredProblems, setFilteredProblems] = useState([])

  const toggleShowResolved = () => {
    setShowResolved(!showResolved)
  }

  useEffect(() => {
    if (profile.type === 'IS') {
      !problems.fetched && !problems.loading && dispatch(getProblems())
    }
  }, []);

  useEffect(() => {
    var problemsArray = []

    if (profile.type === 'IS') {
      problemsArray = problems.items
    } else {
      problemsArray = profile.problems || []
    }

    if (!showResolved) {
      problemsArray = problemsArray.filter(problem => problem.status !== 'RESOLVED')
    }
    setFilteredProblems(problemsArray)

  }, [profile.problems, problems, showResolved]);

  return (
    <BaseView title={context.t('Share a problem')} backurl='/' >
      <hr className='afterTopNavigationBar'></hr>
      <Container style={{marginTop: '-6px'}}>
        {profile.id?
          <div>
            <Row>
              <Col xs={6} style={{textAlign: 'left'}}>
                <Form.Check
                  type='checkbox'
                  onClick={toggleShowResolved}
                  style={{display: 'inline', color: '#cccccc'}}
                />
                <span style={{marginLeft: 5, fontSize: '0.8rem', verticalAlign: 'text-bottom', color: '#6c757d'}}>
                  {context.t('Show resolved')}
                </span>
              </Col>
              <Col xs={6} style={{textAlign: 'right'}}>
              {profile.type === 'JS' && <NewProblemModal/>}
              </Col>
            </Row>
            {filteredProblems.map((problem, key) => <ProblemListItem key={key} problem={problem}/>)}
          </div>
          :
          <div>
            <NavLink to='/login'> {context.t('Please login to share a problem..')} </NavLink>
          </div>
        }
        {profile.type === 'JS' && filteredProblems.length === 0 &&
          <div className='centeredBox'>
            <p style={{color: '#6c757d'}}> {context.t("You haven't shared any problems yet. Please click share a problem on the right top corner to share one.")} </p>
          </div>
        }
      </Container>
    </BaseView>
  );
}

ProblemsView.contextTypes = {
  t: PropTypes.func.isRequired
}
