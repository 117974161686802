import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { Container, Image, Button } from "react-bootstrap";

import { Online } from "react-detect-offline";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

import BaseView from "../BaseView";
import Address from "../../components/Address";
import CompanyContacts from "../../components/CompanyContacts";
import Comment from "../../components/Comment";
import CommentInput from "../../components/CommentInput";
import ShareButtons from "../../components/ShareButtons";
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import { postComment } from "../../common/redux/actions/CommentsActions";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getCities } from "../../common/redux/actions/CitiesActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import { getIndustries } from "../../common/redux/actions/IndustriesActions";
import {
  getEmployer,
  EmpCommentRemove,
} from "../../common/redux/actions/EmployersActions";
import { getLanguages } from "../../common/redux/actions/LanguagesActions";
import {
  hideComment,
  deleteComment,
} from "../../common/redux/actions/CommentHideDeleteAction";

import Rating from "../../components/Rating";
import DetailedRating from "../../components/DetailedRatings";

export default function EmployersDetailedViewV2(props, context) {
  const dispatch = useDispatch();

  const cities = useSelector((state) => state.CitiesState);
  const states = useSelector((state) => state.StatesState);
  const countries = useSelector((state) => state.CountriesState);

  const language = useSelector((state) => state.i18nState.lang);
  const profile = useSelector((state) => state.ProfileState);

  const industries = useSelector((state) => state.IndustriesState);
  const emp = useSelector((state) => state.EmployersState.detail);
  const languages = useSelector((state) => state.LanguagesState);
  const get_ratings = useSelector((state) => state.RatingsState);

  const [employer, setEmployer] = useState(null);
  const [employerIndustries, setEmployerIndustries] = useState([]);
  const [userRating, setUserRating] = useState(null);
  const [showRateForm, setShowRateForm] = useState(false);

  const [languageId, setLanguageId] = useState();
  const [description, setDescrption] = useState("");

  const [comments, setComments] = useState([]);
  const [cloudState, setCloudState] = useState(true);

  const employerId = parseInt(props.match.params.id);

  const [commentReply, setCommentReply] = useState({
    show: false,
    parent: null,
    base_parent: null,
    username: "",
    isModerator: false,
  });

  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
    !cities.fetched && dispatch(getCities());
    !states.fetched && dispatch(getStates());
    !industries.fetched && dispatch(getIndustries());
    !emp.loading && dispatch(getEmployer(employerId));
    !languages.fetched && languages.loading && dispatch(getLanguages());
    setCloudState(true);
  }, []);

  const commentActionsHandler = (comment, type) => {
    // hiding ability can be added here, if users request
    dispatch(EmpCommentRemove(comment));
    if (type == "delete") {
      dispatch(deleteComment(comment.id));
    } else if (type == "hide") {
      dispatch(hideComment(comment.id, true));
      dispatch(deleteComment(comment.id));
    }
  };

  useEffect(() => {
    setCloudState(get_ratings.state);
    dispatch(getEmployer(employerId));
  }, [get_ratings]);

  useEffect(() => {
    if (Object.keys(languages.items).length != 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code == language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  useEffect(() => {
    if (Object.keys(languages.items).length != 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code == language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  useEffect(() => {
    setCloudState(get_ratings.state);
    dispatch(getEmployer(employerId));
  }, [get_ratings]);

  useEffect(() => {
    if (Object.keys(languages.items).length != 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code == language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  useEffect(() => {
    if (Object.keys(languages.items).length != 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code == language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  useEffect(() => {
    if (Object.keys(languages.items).length != 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code == language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  useEffect(() => {
    if (emp.fetched) {
      setEmployer(emp.item);
    }
  }, [emp]);

  useEffect(() => {
    if (
      languageId &&
      employer &&
      Object.keys(employer).length != 0 &&
      employer.translations &&
      employer.translations.length > 0
    ) {
      let translatedObj = employer.translations.find(
        (tran) => tran.language == languageId
      );
      setDescrption(translatedObj ? translatedObj.description : "");
    }
  }, [languageId, employer]);

  useEffect(() => {
    if (employer) {
      setComments(employer.comments);
    }
  }, [employer]);

  useEffect(() => {
    if (employer && profile.data && profile.data.type === "JS") {
      const rating = profile.data.ratings.filter(
        (rating) =>
          rating.content_type === "employer profile" &&
          rating.object_id === employer.id
      )[0];
      if (rating) {
        setUserRating(rating);
      } else {
        setUserRating(undefined);
      }
    }
  }, [employer, profile.data]);

  useEffect(() => {
    if (employer && industries.fetched) {
      let employerIndustries = new Set();
      employer.industries.map((industry) =>
        employerIndustries.add(industries.items[industry].name)
      );
      setEmployerIndustries(employerIndustries);
    }
  }, [industries.items, employer]);

  const handleSubmitComment = (payload) => {
    dispatch(postComment("employer", payload));
  };

  const handleClickReplyButton = (replyPayload) => {
    setCommentReply({
      ...replyPayload,
      show: true,
    });
  };

  const handleCloseReply = () => {
    setCommentReply({
      show: false,
      parent: null,
      base_parent: null,
      username: "",
      isModerator: false,
    });
  };

  return (
    <BaseView title={context.t("Employers")} backurl="/employers">
      {(cities.loading ||
        states.loading ||
        countries.loading ||
        emp.loading ||
        industries.loading) &&
      cloudState ? (
        <div style={{ display: "flex", height: "100%", marginTop: "97px" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        employer && (
          <Container className="afterTopNavigationBar" style={{ padding: 0 }}>
            <Image
              src={employer.logo ? employer.logo : "/static/media/factory.png"}
              crossOrigin="anonymous"
              style={{
                boxShadow: "0 5px 9px rgba(0, 0, 0, 0.2)",
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                objectFit: "contain",
                marginTop: "1rem",
              }}
              onError={addDefaultImageSrc}
            />
            <Container className="pb-6">
              <Rater
                total={5}
                rating={employer.rating_score}
                interactive={false}
              />
              <span>
                {employer.rating_score && employer.rating_score.toFixed(1)}
              </span>
              <span
                style={{
                  position: "relative",
                  bottom: "7px",
                  fontSize: "0.7em",
                }}
              >
                ({employer.rating_count})
              </span>
              <h3 className="ListItemName"> {employer.name} </h3>
              {language !== "en" && (
                <h5 className="ListItemName"> {employer.name_en} </h5>
              )}
              <div className="description">
                <p> {description} </p>
                {employer.industries.length > 0 && (
                  <div>
                    <p className="mb-0" style={{ fontWeight: 600 }}>
                      {context.t("Industries:")}
                    </p>
                    <p className="mb-0">{[...employerIndustries].join(", ")}</p>
                  </div>
                )}
                <CompanyContacts
                  website={employer.website}
                  email={employer.email}
                  phone_numbers={employer.phone_numbers}
                  facebook={employer.facebook}
                  viber={employer.viber}
                  line={employer.line}
                />
              </div>

              {employer.jobs != undefined && employer.jobs.length > 0 && (
                <div>
                  <hr />
                  <p className="mb-0" style={{ fontWeight: 600 }}>
                    {context.t("Job Opportunities:")}
                  </p>
                  <p className="mb-0">
                    <Link to={`/jobs/employer/${employer.id}`}>
                      {context.t(
                        "{employer_name} has listed ({number_of_jobs}) job opportunities at marketplace",
                        {
                          employer_name: employer.name,
                          number_of_jobs: employer.jobs.length,
                        }
                      )}
                    </Link>
                  </p>
                </div>
              )}

              {employer.addresses.length > 0 ? (
                <div>
                  {employer.addresses.map((address, i) => (
                    <Address
                      key={i}
                      name_en={employer.name_en} // used in a google maps search
                      street={address.street}
                      city={address.city_id}
                      state={address.state_id}
                      country={address.country_id}
                      zip={address.zip}
                      addressContacts={address.contacts}
                    />
                  ))}
                </div>
              ) : (
                <p className="mt-3">
                  {context.t("No Focal Person contact provided")}
                </p>
              )}
              <hr />
              <div className="mt-3">
                <ShareButtons title={employer.name} />
              </div>
              <hr />
              <p className="rated-by-text">
                {context.t("Rated by ({number}) viewers", {
                  number: employer.rating_count,
                })}
                &nbsp;&nbsp;<i className="fa-regular fa-circle-check"></i>
              </p>
              {profile.data && profile.data.type === "JS" && (
                <div className="NavigationBar-icon">
                  <i
                    className={`mt-4 far fa-${
                      showRateForm ? "eye" : "edit"
                    } fa-2x`}
                    style={{ color: "#198474" }}
                    onClick={() => setShowRateForm(!showRateForm)}
                  ></i>
                  <span>
                    {showRateForm
                      ? context.t("View rating")
                      : context.t("Give rating")}
                  </span>
                </div>
              )}
              {showRateForm ? (
                <Rating
                  group="Employer"
                  object_id={employer.id}
                  content_type="employer profile"
                  userRating={userRating}
                  onSubmit={() => setShowRateForm(false)}
                />
              ) : (
                <DetailedRating
                  overall_score={employer.rating_score}
                  scores_for_each_criteria={employer.scores_for_each_criteria}
                />
              )}
              <hr />
              {comments.length > 0 && (
                <div className="CommentThread">
                  {comments
                    .sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    )
                    .map((comment) => (
                      <Comment
                        key={comment.id}
                        commentActionsHandler={commentActionsHandler.bind(this)}
                        comment={comment}
                        onClickReply={handleClickReplyButton.bind(this)}
                        onSubmitReply={handleSubmitComment.bind(this)}
                      />
                    ))}
                </div>
              )}
              <Online>
                {profile && profile.data && profile.data.id ? (
                  <CommentInput
                    replyToText={commentReply.text}
                    user_id={profile.data.id}
                    object_id={employer.id}
                    parent={commentReply.parent}
                    base_parent={commentReply.base_parent}
                    isReply={commentReply.show}
                    onSubmit={handleSubmitComment}
                    onCloseReply={handleCloseReply}
                    replyUsername={commentReply.username}
                    replyIsModerator={commentReply.isModerator}
                  />
                ) : (
                  <div className="mt-3">
                    <NavLink to="/login">
                      {context.t("Please login to write a comment..")}
                    </NavLink>
                  </div>
                )}
              </Online>
              {profile && profile.data && profile.data.type === "IS" && (
                <Button
                  href={`${process.env.REACT_APP_SERVER_URL}/admin/users/user/${employer.id}/change`}
                  target="_blank"
                  className="mt-3 button-modified"
                  style={{ backgroundColor: "teal", border: "none" }}
                >
                  {context.t("Change in CMS")}
                </Button>
              )}
            </Container>
          </Container>
        )
      )}
    </BaseView>
  );
}

EmployersDetailedViewV2.contextTypes = {
  t: PropTypes.func.isRequired,
};
