import axios from 'axios';
import { objectToQueryParams } from '../../utilities/utilities';

// --------------------------------------
//              ACTION TYPES
// --------------------------------------

export const RESUME_ROSTER_GET_START = 'RESUME_ROSTER_GET_START';
export const RESUME_ROSTER_GET_SUCCESS = 'RESUME_ROSTER_GET_SUCCESS';
export const RESUME_ROSTER_GET_FAIL = 'RESUME_ROSTER_GET_FAIL';

export const RESUME_DETAIL_GET_START = 'RESUME_DETAIL_GET_START';
export const RESUME_DETAIL_GET_SUCCESS = 'RESUME_DETAIL_GET_SUCCESS';
export const RESUME_DETAIL_GET_FAIL = 'RESUME_DETAIL_GET_FAIL';

export const RESUME_STATUS_PATCH_START = 'RESUME_STATUS_PATCH_START';
export const RESUME_STATUS_PATCH_SUCCESS = 'RESUME_STATUS_PATCH_SUCCESS';
export const RESUME_STATUS_PATCH_FAIL = 'RESUME_STATUS_PATCH_FAIL';

export const ATTACH_JOB_WITH_RESUME_START = 'ATTACH_JOB_WITH_RESUME_START';
export const ATTACH_JOB_WITH_RESUME_SUCCESS = 'ATTACH_JOB_WITH_RESUME_SUCCESS';
export const ATTACH_JOB_WITH_RESUME_FAIL = 'ATTACH_JOB_WITH_RESUME_FAIL';

export const GET_GENERAL_WAITLIST_START = 'GET_GENERAL_WAILIST_START';
export const GET_GENERAL_WAITLIST_SUCCESS = 'GET_GENERAL_WAITLIST_SUCCESS';
export const GET_GENERAL_WAITLIST_FAIL = 'GET_GENERAL_WAILIST_FAIL';


// GET:
export const getResumeRosterStart = () => {
  return {
    type: RESUME_ROSTER_GET_START,
  }
}

export const getResumeRosterSuccess = (resumeRoster) => {
  return {
    type: RESUME_ROSTER_GET_SUCCESS,
    resumeRoster: resumeRoster,
  }
}

export const getResumeRosterFail = error => {
  return {
    type: RESUME_ROSTER_GET_FAIL,
    error: error
  }
}

// GET RESUME DETAIL:
export const getResumeDetailStart = () => {
  return {
    type: RESUME_DETAIL_GET_START,
  }
}

export const getResumeDetailSuccess = (resumeDetail) => {
  return {
    type: RESUME_DETAIL_GET_SUCCESS,
    resumeDetail: resumeDetail,
  }
}

export const getResumeDetailFail = error => {
  return {
    type: RESUME_DETAIL_GET_FAIL,
    error: error
  }
}

// PATCH RESUME STATUS:
export const patchResumeStatusStart = () => {
  return {
    type: RESUME_STATUS_PATCH_START,
  }
}

export const patchResumeStatusSuccess = (resumeDetail) => {
  return {
    type: RESUME_STATUS_PATCH_SUCCESS,
    resumeDetail: resumeDetail,
  }
}

export const patchResumeStatusFail = error => {
  return {
    type: RESUME_STATUS_PATCH_FAIL,
    error: error
  }
}

// ATTACH RESUME WITH JOBS:
export const attachJobWithResumeStart = () => {
  return {
    type: ATTACH_JOB_WITH_RESUME_START,
  }
}

export const attachJobWithResumeSuccess = (resumeDetail) => {
  return {
    type: ATTACH_JOB_WITH_RESUME_SUCCESS,
    resumeDetail: resumeDetail,
  }
}

export const attachJobWithResumeFail = error => {
  return {
    type: ATTACH_JOB_WITH_RESUME_FAIL,
    error: error
  }
}

export const getResumeRoster = (filters) => {
  if (filters.gender && Array.isArray(filters.gender) && filters.gender.length > 0) {
    filters.gender = filters.gender.join(',');
    console.log("here is filters.gender", filters.gender)
  }

  return (dispatch, getState) => {
    dispatch(getResumeRosterStart());
    return axios.get(`${process.env.REACT_APP_API_V2_URL}/recruiter-roster-applications/${objectToQueryParams(filters)}`)
      .then(res => {
        dispatch(getResumeRosterSuccess(res.data))
      })
      .catch(err => {
        dispatch(getResumeRosterFail(err))
        throw err
      })
  }
}

export const getResumeRosterByUrl = (url) => {
  return (dispatch, getState) => {
    dispatch(getResumeRosterStart());

    return axios.get(url)
      .then(res => {
        dispatch(getResumeRosterSuccess(res.data))
      })
      .catch(err => {
        dispatch(getResumeRosterFail(err))
        throw err
      })
  }
}

export const getResumeDetail = (id) => {
  return (dispatch, getState) => {
    dispatch(getResumeDetailStart());

    return axios.get(`${process.env.REACT_APP_API_V2_URL}/recruiter-roster-applications/${id}/`)
      .then(res => {
        dispatch(getResumeDetailSuccess(res.data))
      })
      .catch(err => {
        dispatch(getResumeDetailFail(err))
        throw err
      })
  }
}

export const patchResumeStatus = (id, status) => {
  return (dispatch, getState) => {
    dispatch(patchResumeStatusStart());
    let payload = { status: status }
    return axios.patch(`${process.env.REACT_APP_API_V2_URL}/recruiter-roster-applications/${id}/`, payload)
      .then(res => {
        if (res && res.data) {
          dispatch(patchResumeStatusSuccess(res.data))
        }
      })
      .catch(err => {
        dispatch(patchResumeStatusFail(err))
        throw err
      })
  }
}

export const attachResumeWithJob = (rosterId, payload) => {
  return (dispatch, getState) => {
    dispatch(attachJobWithResumeStart());
    return axios.post(`${process.env.REACT_APP_API_URL}/recruiter-roster-applications/${rosterId}/job_shortlist/`, payload)
      .then(res => {
        console.log("attached", res)
      })
      .catch(err => {
        dispatch(attachJobWithResumeFail(err))
        throw err
      })
  }
}

// GET GENERAL WAITLIST:
export const getGeneralWaitlistStart = () => {
  return {
    type: GET_GENERAL_WAITLIST_START,
  }
}

export const getGeneralWaitlistSuccess = (waitlist) => {
  return {
    type: GET_GENERAL_WAITLIST_SUCCESS,
    waitlist: waitlist,
  }
}

export const getGeneralWaitlistFail = error => {
  return {
    type: GET_GENERAL_WAITLIST_FAIL,
    error: error
  }
}

export const getGeneralWaitlist = (recruiter_id, limit, offset) => {
  return (dispatch, getState) => {
    dispatch(getGeneralWaitlistStart());
    return axios.get(`${process.env.REACT_APP_API_URL}/recruitment-agencies/${recruiter_id}/general-waitlist?limit=${limit}&offset=${offset}`)
      .then(res => {
        dispatch(getGeneralWaitlistSuccess(res.data))
      })
      .catch(err => {
        dispatch(getGeneralWaitlistFail(err))
        throw err
      })
  }
}
