import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'

import moment from 'moment'
import { Online } from "react-detect-offline";

import { Container, Modal } from 'react-bootstrap';

import HeroImage from '../../components/HeroImage';
import PollsQuestionModal from '../Polls/QuestionModal';
import JobseekersMenu from './JobseekersMenu';
import AccountNavButton from '../../components/AccountNavButton';
import NotificationsModal from './NotificationsModal';
import SurveyModal from '../Surveys/SurveyModal';
import { messaging } from "../../firebase";
import { getSurveys } from "../../common/redux/actions/SurveysActions";

export default function JobseekersHomeView(props, context) {
  const dispatch = useDispatch()

  const language = useSelector(state => state.i18nState.lang)
  const profile = useSelector(state => state.ProfileState.data)
  const latestPoll = useSelector(state => state.PollsState.latest)
  const surveys = useSelector(state => state.SurveysState)
  const [userSurveys, setUserSurveys] = useState([]);

  const [showNotificationsModal, setShowNotificationsModal] = useState(false)
  const [surveyModal, setSurveyModal] = useState({
    survey: null,
    show: false
  })

  // move people from the old domain goldendreams-production.appspot.com
  // to a new one app.golden-dreams.org.
  // This can be removed once new domain receives 100% of traffic
  const [showUpdateApp, setShowUpdateApp] = useState(false)

  async function fetchSurveysByJobseekerId(jobseekerId, lang) {
    const url = `${process.env.REACT_APP_API_V2_URL}/jobseekers/${jobseekerId}/surveys?lang=${lang}`;
      
      try {
          const response = await axios.get(url);
          if(response.data && response.data.surveys && response.data.surveys.length > 0){
            setUserSurveys(response.data.surveys);
          }else{
            setUserSurveys([]);
          }
          return response.data;
      } catch (error) {
          console.error(`Error fetching surveys for jobseeker ${jobseekerId}:`, error);
          throw error;
      }
  }

  const getBackgroundImage = () => {
    switch (language) {
      case 'mm-z':
        return '/static/media/burmese-bg.jpg'
        break;
      case 'mm-u':
        return '/static/media/burmese-bg.jpg'
        break;
      case 'kh':
        return '/static/media/khmer-bg.jpg'
        break;
      case 'ne':
        return '/static/media/nepal-bg.jpeg'
        break;
      default:
        return '/static/media/burmese-bg.jpg'
    }
  }

  const getTitleImage = () => {
    switch (language) {
      case 'mm-z':
        return '/static/media/burmese-title.png'
        break;
      case 'mm-u':
        return '/static/media/burmese-title.png'
        break;
      case 'kh':
        return '/static/media/khmer-title.png'
        break;
      case 'ne':
        return '/static/media/nepal-title.png'
        break;
      default:
        return '/static/media/eng-title.png'
    }
  }

  const votedLastPollAlready = () => {
    return profile.votes.filter(vote =>
      vote.question_id === latestPoll.id
    ).length > 0
  }

  const rejectedLastPollQuestions = () => {
    let ask_poll = localStorage.getItem('ask_poll_user_' + profile.id)
    if (ask_poll) {
      ask_poll = JSON.parse(ask_poll)
      return (ask_poll.latest_poll_id == latestPoll.id && ask_poll.dismiss == true)
    }
    return false;
  }

  useEffect(() => {
    if (messaging && profile.id && !profile.firebase_enabled) {
      let notifications = localStorage.getItem('notifications')

      if (notifications) {
        notifications = JSON.parse(notifications)
        if (notifications.date) {
          if (moment().diff(notifications.date, 'days') >= 7) {
            setShowNotificationsModal(true)
          }
        }
      } else {
        setShowNotificationsModal(true)
      }
    }

    if(profile && profile.id && profile.type == 'JS'){
      fetchSurveysByJobseekerId(profile.id, language);
    }
  }, [])


  useEffect(() => {
    // get previously muted surveys
    let muted_surveys = JSON.parse(localStorage.getItem('muted_surveys'))

    // get latest survey
    let lastSurvey = surveys.items[surveys.items.length - 1]

    if (lastSurvey) {
      // do not show modal if user is not JS or user already participated
      if (profile.type != 'JS' || (userSurveys && userSurveys.length > 0 && userSurveys.includes(lastSurvey.identifier))) {
        return
      }

      if (muted_surveys && muted_surveys[lastSurvey.identifier]) {
        // if this survey was muted before compare the dates.
        // if the difference equals or more than 7 days show the modal
        // otherwise keep silent and wait till 7 days past
        let daysDifference = moment(muted_surveys[lastSurvey.identifier]).diff(moment.now(), 'days')

        if (daysDifference >= 7) {
          setSurveyModal({ survey: lastSurvey, show: true })
        }
      } else {
        // show the modal because it wasn't muted
        setSurveyModal({ survey: lastSurvey, show: true })
      }
    }
  }, [surveys.items])

  // move people from the old domain goldendreams-production.appspot.com
  // to a new one app.golden-dreams.org.
  // This can be removed once new domain receives 100% of traffic
  useEffect(() => {
    if (window.location.host == 'goldendreams-production.appspot.com') {
      setShowUpdateApp(true)
    }
    !surveys.fetched && dispatch(getSurveys());
  }, [])

  return (
    <Container fluid={true} style={{ padding: 0 }}>
      <HeroImage height='180px' imageURL={getBackgroundImage()}>
        <img src={getTitleImage()} style={{ width: 200, marginTop: '-12px' }} alt='' />
      </HeroImage>
      <AccountNavButton />
      <Online>
        {latestPoll && profile.votes && !votedLastPollAlready() && !rejectedLastPollQuestions() &&
          <PollsQuestionModal />
        }
      </Online>

      {showUpdateApp &&
        // move people from the old domain goldendreams-production.appspot.com
        // to a new one app.golden-dreams.org.
        // This can be removed once new domain receives 100% of traffic
        <Modal show centered onHide={() => { }}>
          <Modal.Header style={{ justifyContent: 'center' }}>
            <Modal.Title style={{ fontSize: '1rem' }}> <b>{context.t("Update required!")}</b> </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ margin: 0 }}>
              {context.t("Please {clickHere} to update your app in Google Play Store. Close and reopen the app once completed.", {
                clickHere: <a target="_blank" href='https://play.google.com/store/apps/details?id=app_golden_dreams.org'> {context.t('click here')} </a>
              })}
            </p>

          </Modal.Body>
        </Modal>
      }

      <NotificationsModal show={showNotificationsModal} />
      <JobseekersMenu />
      <SurveyModal
        show={surveyModal.show}
        survey={surveyModal.survey}
        onClose={() => setSurveyModal({ identifier: null, show: false })}
      />
    </Container>
  )
}

JobseekersHomeView.contextTypes = {
  t: PropTypes.func.isRequired
}
