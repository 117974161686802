import axios from "axios";

import { updateUserAvatar } from "./UsersActions";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------

export const PROFILE_GET_START = "PROFILE_GET_START";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAIL = "PROFILE_GET_FAIL";

export const PROFILE_UPDATE_START = "PROFILE_UPDATE_START";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";
export const PROFILE_UPDATE_FAIL = "PROFILE_UPDATE_FAIL";

export const PROFILE_USER_UPDATE_AVATAR = "PROFILE_USER_UPDATE_AVATAR";
export const PROFILE_USER_UPDATE_NOTIFICATION_SETTINGS =
  "PROFILE_USER_UPDATE_NOTIFICATION_SETTINGS";

export const PROFILE_ADD_RATING = "PROFILE_ADD_RATING";
export const PROFILE_UPDATE_RATING = "PROFILE_UPDATE_RATING";

export const PROFILE_ADD_VOTE = "PROFILE_ADD_VOTE";
export const PROFILE_ADD_PROBLEM = "PROFILE_ADD_PROBLEM";
export const PROFILE_ADD_PROBLEM_MESSAGE = "PROFILE_ADD_PROBLEM_MESSAGE";

export const PROFILE_ADD_SURVEY = "PROFILE_ADD_SURVEY";

export const RESUME_CREATE_START = "RESUME_CREATE_START";
export const RESUME_CREATE_SUCCESS = "RESUME_CREATE_SUCCESS";
export const RESUME_CREATE_FAIL = "RESUME_CREATE_FAIL";

export const RESUME_DELETE_START = "RESUME_DELETE_START";
export const RESUME_DELETE_SUCCESS = "RESUME_DELETE_SUCCESS";
export const RESUME_DELETE_FAIL = "RESUME_DELETE_FAIL";

export const RESUME_UPDATE_START = "RESUME_UPDATE_START";
export const RESUME_UPDATE_SUCCESS = "RESUME_UPDATE_SUCCESS";
export const RESUME_UPDATE_FAIL = "RESUME_UPDATE_FAIL";

export const CUSTOM_RESUME_CREATE_START = "CUSTOM_RESUME_CREATE_START";
export const CUSTOM_RESUME_CREATE_SUCCESS = "CUSTOM_RESUME_CREATE_SUCCESS";
export const CUSTOM_RESUME_CREATE_FAIL = "CUSTOM_RESUME_CREATE_FAIL";

export const CUSTOM_RESUME_DELETE_START = "CUSTOM_RESUME_DELETE_START";
export const CUSTOM_RESUME_DELETE_SUCCESS = "CUSTOM_RESUME_DELETE_SUCCESS";
export const CUSTOM_RESUME_DELETE_FAIL = "CUSTOM_RESUME_DELETE_FAIL";

export const JOB_APPLICATION_APPLY_START = "JOB_APPLICATION_APPLY_START";
export const JOB_APPLICATION_APPLY_SUCCESS = "JOB_APPLICATION_APPLY_SUCCESS";
export const JOB_APPLICATION_APPLY_FAIL = "JOB_APPLICATION_APPLY_FAIL";

export const WORKER_JOB_APPLICATION_CHANGE_STATUS_SUCCESS =
  "WORKER_JOB_APPLICATION_CHANGE_STATUS_SUCCESS";

export const NOTIFICATION_RECEIVED = "NOTIFICATION_RECEIVED";
export const NOTIFICATION_MARK_AS_READ_SUCCESS =
  "NOTIFICATION_MARK_AS_READ_SUCCESS";

export const NOTIFICATION_JOB_APPLICATION_UPDATE =
  "NOTIFICATION_JOB_APPLICATION_UPDATE";

export const USER_DOCUMENTS_UPDATE_START = "USER_DOCUMENTS_UPDATE_START";
export const USER_DOCUMENTS_UPDATE_SUCCESS = "USER_DOCUMENTS_UPDATE_SUCCESS";
export const USER_DOCUMENTS_UPDATE_FAIL = "USER_DOCUMENTS_UPDATE_FAIL";

export const USER_UPDATE_PROFILE_IMAGES_START =
  "USER_UPDATE_PROFILE_IMAGES_START";
export const USER_UPDATE_PROFILE_IMAGES_SUCCESS =
  "USER_UPDATE_PROFILE_IMAGES_SUCCESS";
export const USER_UPDATE_PROFILE_IMAGES__FAIL =
  "USER_UPDATE_PROFILE_IMAGES__FAIL";

export const DELETE_USER_PROFILE_IMAGE_START =
  "DELETE_USER_PROFILE_IMAGE_START";
export const DELETE_USER_PROFILE_IMAGE_SUCCESS =
  "DELETE_USER_PROFILE_IMAGE_SUCCESS";
export const DELETE_USER_PROFILE_IMAGE_FAIL = "DELETE_USER_PROFILE_IMAGE_FAIL";

export const USER_UPDATE_COVER_IMAGE_START = "USER_UPDATE_COVER_IMAGE_START";
export const USER_UPDATE_COVER_IMAGE_SUCCESS =
  "USER_UPDATE_COVER_IMAGE_SUCCESS";
export const USER_UPDATE_COVER_IMAGE_FAIL = "USER_UPDATE_COVER_IMAGE_FAIL";

export const CHECK_PHONE_VERIFY_START = "CHECK_PHONE_VERIFY_START";
export const CHECK_PHONE_VERIFY_SUCCESS = "CHECK_PHONE_VERIFY_SUCCESS";
export const CHECK_PHONE_VERIFY_FAIL = "CHECK_PHONE_VERIFY_FAIL";

// --------------------------------------
//          PROFILE TYPES TABLE
// --------------------------------------
const types = {
  JS: "jobseekers",
  EM: "employers",
  RA: "recruitment-agencies",
  IS: "issara_staff",
};

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getProfileStart = () => {
  return {
    type: PROFILE_GET_START,
  };
};

export const getProfileSuccess = (data) => {
  return {
    type: PROFILE_GET_SUCCESS,
    data: data,
  };
};

export const getProfileFail = (error) => {
  return {
    type: PROFILE_GET_FAIL,
    error: error,
  };
};

// check phone verify status actions
export const checkPhoneVerifyStart = () => {
  return {
    type: CHECK_PHONE_VERIFY_START,
  };
};

export const checkPhoneVerifySuccess = (data) => {
  return {
    type: CHECK_PHONE_VERIFY_SUCCESS,
    data: data,
  };
};

export const checkPhoneVerifyFail = (error) => {
  return {
    type: CHECK_PHONE_VERIFY_FAIL,
    error: error,
  };
};

export const checkPhoneVerifiedStatus = (type, user_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(checkPhoneVerifyStart());

      const language = getState().i18nState.lang;

      let url = null;

      if (type != "JS") {
        url = `${process.env.REACT_APP_API_V2_URL}/business/${types[type]}/${user_id}?lang=${language}`;
      } else {
        url = `${process.env.REACT_APP_API_V2_URL}/${types[type]}/${user_id}?lang=${language}`;
      }

      if (url) {
        axios
          .get(url)
          .then((res) => {
            // sort worker's problems (newest first)
            if (type === "JS") {
              res.data.problems.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
              });
            }
            dispatch(checkPhoneVerifySuccess(res.data));
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(checkPhoneVerifyFail(err));
            reject(err);
          });
      }
    });
  };
};

export const getProfile = (type, user_id) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(getProfileStart());

      const language = getState().i18nState.lang;

      let url = null;

      if (type == "RA" || type == "EM") {
        url = `${process.env.REACT_APP_API_V2_URL}/business/${types[type]}/${user_id}?lang=${language}`;
      } else if (type == "JS") {
        url = `${process.env.REACT_APP_API_V2_URL}/${types[type]}/${user_id}?lang=${language}`;
      } else if (type == "IS") {
        url = `${process.env.REACT_APP_API_URL}/${types[type]}/${user_id}?lang=${language}`;
      }

      if (url) {
        axios
          .get(url)
          .then((res) => {
            // sort worker's problems (newest first)
            if (type === "JS") {
              res.data.problems.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
              });
            }
            dispatch(getProfileSuccess(res.data));
            resolve(res.data);
          })
          .catch((err) => {
            dispatch(getProfileFail(err));
            reject(err);
          });
      }
    });
  };
};

export const readNotificationSuccess = (notification) => {
  return {
    type: NOTIFICATION_MARK_AS_READ_SUCCESS,
    notification: notification,
  };
};

export const readNotification = (id) => (dispatch) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/notifications/${id}/mark_as_read/`)
    .then((res) => {
      dispatch(readNotificationSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

// CREATE:
export const createResumeStart = () => {
  return {
    type: RESUME_CREATE_START,
  };
};

export const createResumeSuccess = (resume) => {
  return {
    type: RESUME_CREATE_SUCCESS,
    resume: resume,
  };
};

export const createResumeFail = (error) => {
  return {
    type: RESUME_CREATE_FAIL,
    error: error,
  };
};

export const createResume = (payload) => (dispatch) => {
  dispatch(createResumeStart());
  return axios
    .post(`${process.env.REACT_APP_API_URL}/jobseekers/resume/`, payload)
    .then((res) => {
      dispatch(createResumeSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch(createResumeFail(err));
      return Promise.reject(err);
    });
};

export const createCustomResumeStart = () => {
  return {
    type: CUSTOM_RESUME_CREATE_START,
  };
};

export const createCustomResumeSuccess = (resume) => {
  return {
    type: CUSTOM_RESUME_CREATE_SUCCESS,
    resume: resume,
  };
};

export const createCustomResumeFail = (error) => {
  return {
    type: CUSTOM_RESUME_CREATE_FAIL,
    error: error,
  };
};

export const createCustomResume = (payload) => (dispatch) => {
  dispatch(createCustomResumeStart());

  var formData = new FormData();
  formData.append("file", payload.file, payload.file.name.slice(-100));
  formData.append("name", payload.name);

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/jobseekers/uploaded-resumes/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      dispatch(createCustomResumeSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch(createCustomResumeFail(err));
      return Promise.reject(err);
    });
};

export const applyJobStart = () => {
  return {
    type: JOB_APPLICATION_APPLY_START,
  };
};

export const applyJobSuccess = (application) => {
  return {
    type: JOB_APPLICATION_APPLY_SUCCESS,
    application: application,
  };
};

export const applyJobFail = (error) => {
  return {
    type: JOB_APPLICATION_APPLY_FAIL,
    error: error,
  };
};

export const applyJob = (payload) => (dispatch) => {
  dispatch(applyJobStart());

  return axios
    .post(`${process.env.REACT_APP_API_URL}/job-applications/`, payload)
    .then((res) => {
      dispatch(applyJobSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch(applyJobFail(err));
      return Promise.reject(err);
    });
};

// UPDATE:
export const updateProfileStart = () => {
  return {
    type: PROFILE_UPDATE_START,
  };
};

export const updateProfileSuccess = (data) => {
  return {
    type: PROFILE_UPDATE_SUCCESS,
    data: data,
  };
};

export const updateProfileFail = (error) => {
  return {
    type: PROFILE_UPDATE_FAIL,
    error: error,
  };
};

export const updateProfileUserAvatar = (avatar) => {
  return {
    type: PROFILE_USER_UPDATE_AVATAR,
    avatar: avatar,
  };
};

export const updateProfileNotificationSettings = (notificationSettings) => {
  return {
    type: PROFILE_USER_UPDATE_NOTIFICATION_SETTINGS,
    notificationSettings: notificationSettings,
  };
};

export const updateProfile = (type, user_id, payload) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(updateProfileStart());
      const language = getState().i18nState.lang;

      let url = null;

      if (type != "JS") {
        url = `${process.env.REACT_APP_API_V2_URL}/business/${types[type]}/${user_id}?lang=${language}`;
      } else {
        url = `${process.env.REACT_APP_API_V2_URL}/${types[type]}/${user_id}?lang=${language}`;
      }

      if (url) {
        axios
          .patch(url, payload)
          .then((res) => {
            dispatch(updateProfileSuccess(res.data));
            if (payload.avatar) {
              dispatch(updateUserAvatar(user_id, payload.avatar));
            }
            resolve(res);
          })
          .catch((error) => {
            if (error.response) {
              dispatch(
                updateProfileFail(error.response.data.non_field_errors[0])
              );
              reject(error.response.data.non_field_errors[0]);
            }
          })
          .finally(() => {
            if (payload.preferred_language) {
              const language_id = parseInt(payload.preferred_language);
              const language =
                getState().LanguagesState.items[language_id].code.toLowerCase();
              localStorage.setItem("language", language);
            }
          });
      }
    });
  };
};

export const updateProfileV2 = (type, user_id, payload) => {
  console.log("Payload", payload);
  console.log("Type", type);
  console.log("User Id", user_id);
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(updateProfileStart());
      const language = getState().i18nState.lang;

      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/${types[type]}/${user_id}?lang=${language}`,
          payload
        )
        .then((res) => {
          dispatch(updateProfileSuccess(res.data));
          if (payload.avatar) {
            dispatch(updateUserAvatar(user_id, payload.avatar));
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch(updateProfileFail(err));
          reject(err);
        })
        .finally(() => {
          if (payload.preferred_language) {
            const language_id = parseInt(payload.preferred_language);
            const language =
              getState().LanguagesState.items[language_id].code.toLowerCase();
            localStorage.setItem("language", language);
          }
        });
    });
  };
};

export const updateResumeStart = () => {
  return {
    type: RESUME_UPDATE_START,
  };
};

export const updateResumeSuccess = (resume) => {
  return {
    type: RESUME_UPDATE_SUCCESS,
    resume: resume,
  };
};

export const updateResumeFail = (error) => {
  return {
    type: RESUME_UPDATE_FAIL,
    error: error,
  };
};

export const updateResume = (id, payload) => (dispatch) => {
  dispatch(updateResumeStart());
  return axios
    .patch(`${process.env.REACT_APP_API_URL}/jobseekers/resume/${id}/`, payload)
    .then((res) => {
      dispatch(updateResumeSuccess(res.data));
      return Promise.resolve(res.data);
      // TODO: update resume photo
    })
    .catch((err) => {
      dispatch(updateResumeFail(err));
      return Promise.reject(err);
    });
};

export const updateResumePhoto = (id, photo) => (dispatch) => {
  var formData = new FormData();
  formData.append("photo", photo, photo.name.slice(-100));

  return axios
    .patch(
      `${process.env.REACT_APP_API_URL}/jobseekers/resume/${id}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      dispatch(updateResumeSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch(updateResumeFail(err));
      return Promise.reject(err);
    });
};

// DELETE:
export const deleteResumeStart = () => {
  return {
    type: RESUME_DELETE_START,
  };
};

export const deleteResumeSuccess = () => {
  return {
    type: RESUME_DELETE_SUCCESS,
  };
};

export const deleteResumeFail = (error) => {
  return {
    type: RESUME_DELETE_FAIL,
    error: error,
  };
};

export const deleteResume = (id) => {
  return (dispatch) => {
    dispatch(deleteResumeStart());
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/jobseekers/resume/${id}/`)
      .then((res) => {
        dispatch(deleteResumeSuccess());
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch(deleteResumeFail(err));
        return Promise.reject(err);
      });
  };
};

export const deleteCustomResumeStart = () => {
  return {
    type: CUSTOM_RESUME_DELETE_START,
  };
};

export const deleteCustomResumeSuccess = (id) => {
  return {
    type: CUSTOM_RESUME_DELETE_SUCCESS,
    id: id,
  };
};

export const deleteCustomResumeFail = (error) => {
  return {
    type: CUSTOM_RESUME_DELETE_FAIL,
    error: error,
  };
};

export const deleteCustomResume = (id) => {
  return (dispatch) => {
    dispatch(deleteCustomResumeStart());
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/jobseekers/uploaded-resumes/${id}/`
      )
      .then((res) => {
        dispatch(deleteCustomResumeSuccess(id));
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch(deleteCustomResumeFail(err));
        return Promise.reject(err);
      });
  };
};

// INTERNAL INTERACTIONS:
export const addProfileRating = (rating) => {
  return {
    type: PROFILE_ADD_RATING,
    rating: rating,
  };
};

export const addProfileSurvey = (surveyIdentifier) => {
  return {
    type: PROFILE_ADD_SURVEY,
    identifier: surveyIdentifier,
  };
};

export const updateProfileRating = (rating) => {
  return {
    type: PROFILE_UPDATE_RATING,
    rating: rating,
  };
};

export const addProfileVote = (vote) => {
  return {
    type: PROFILE_ADD_VOTE,
    vote: vote,
  };
};

export const addProfileProblem = (problem) => {
  return {
    type: PROFILE_ADD_PROBLEM,
    problem: problem,
  };
};

export const addProfileProblemMessage = (message) => {
  return {
    type: PROFILE_ADD_PROBLEM_MESSAGE,
    message: message,
  };
};

export const receiveNotification = (notification) => {
  return {
    type: NOTIFICATION_RECEIVED,
    notification: notification,
  };
};

export const notifyJobApplication = (application) => {
  return {
    type: NOTIFICATION_JOB_APPLICATION_UPDATE,
    application: application,
  };
};

export const jobApplicationChangeStatusSuccess = (application) => {
  return {
    type: WORKER_JOB_APPLICATION_CHANGE_STATUS_SUCCESS,
    application: application,
  };
};

export const acceptJobApplication = (id) => (dispatch) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/job-applications/${id}/accept/`)
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const acceptDocumentsRequestJobApplication = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/job-applications/${id}/documents_request_accept/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const rejectDocumentsRequestJobApplication = (id) => (dispatch) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}/job-applications/${id}/documents_request_reject/`
    )
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const rejectJobApplication = (id) => (dispatch) => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/job-applications/${id}/reject`)
    .then((res) => {
      dispatch(jobApplicationChangeStatusSuccess(res.data));
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

// UPDATE:
export const updateUserDocumentsStart = () => {
  return {
    type: USER_DOCUMENTS_UPDATE_START,
  };
};

export const updateUserDocumentsSuccess = (documents) => {
  return {
    type: USER_DOCUMENTS_UPDATE_SUCCESS,
    documents: documents,
  };
};

export const updateUserDocumentsFail = (error) => {
  return {
    type: USER_DOCUMENTS_UPDATE_FAIL,
    error: error,
  };
};

export const updateUserDocuments = (documents) => {
  return (dispatch, getState) => {
    dispatch(updateUserDocumentsStart());

    return new Promise((resolve, reject) => {
      var formData = new FormData();

      Object.keys(documents).map((docName) => {
        formData.append(docName, documents[docName]);
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/users/documents`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(updateUserDocumentsSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(updateUserDocumentsFail(err));
          resolve({ error: { ...err } });
        });
    });
  };
};

// UPDATE:
export const updateUserProfileImagesStart = () => {
  return {
    type: USER_UPDATE_PROFILE_IMAGES_START,
  };
};

export const updateUserProfileImagesSuccess = (result) => {
  return {
    type: USER_UPDATE_PROFILE_IMAGES_SUCCESS,
    result: result,
  };
};

export const updateUserProfileImagesFail = (error) => {
  return {
    type: USER_UPDATE_PROFILE_IMAGES__FAIL,
    error: error,
  };
};

export const updateUserCoverImageStart = () => {
  return {
    type: USER_UPDATE_COVER_IMAGE_START,
  };
};

export const updateUserCoverImageSuccess = (result) => {
  return {
    type: USER_UPDATE_COVER_IMAGE_SUCCESS,
    result: result,
  };
};

export const updateUserCoverImageFail = (error) => {
  return {
    type: USER_UPDATE_COVER_IMAGE_FAIL,
    error: error,
  };
};

export const updateUserProfileImages = (payload) => {
  return (dispatch, getState) => {
    dispatch(updateUserProfileImagesStart());

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/bussiness-images/`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(updateUserProfileImagesSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(updateUserProfileImagesFail(err));
          resolve({ error: { ...err } });
        });
    });
  };
};

export const updateUserCoverImage = (payload) => {
  return (dispatch, getState) => {
    dispatch(updateUserCoverImageStart());

    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.REACT_APP_API_URL}/bussiness-images/`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(updateUserCoverImageSuccess(res.data));
          resolve(res.data);
        })
        .catch((err) => {
          dispatch(updateUserCoverImageFail(err));
          resolve({ error: { ...err } });
        });
    });
  };
};

export const deleteProfileImageSuccess = (image_id) => {
  return {
    type: DELETE_USER_PROFILE_IMAGE_SUCCESS,
    image_id: image_id,
  };
};

export const deleteProfileImage = (image_id) => {
  return (dispatch) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/bussiness-images/${image_id}`)
      .then((res) => {
        console.log("Image deleted");
        console.log(res.data);
        // dispatch(deleteProfileImageSuccess(image_id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Delete JobSeeker Profile
export const deleteProfile = (user_id) => {
  return (dispatch) => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_V2_URL}/jobseekers/${user_id}/remove-profile`
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};
