import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'
import moment from 'moment'
import { Container, Row, Col, Form, Button, Image, Table, Card } from 'react-bootstrap';

import BaseView from '../BaseView';
import Divider from '../../components/Divider';
import { getCities } from '../../common/redux/actions/CitiesActions';
import { getStates } from '../../common/redux/actions/StatesActions';
import { getCountries } from '../../common/redux/actions/CountriesActions';
import { getIndustries } from '../../common/redux/actions/IndustriesActions';
import { getLanguages } from '../../common/redux/actions/LanguagesActions';
import { getEducationLevels } from '../../common/redux/actions/EducationLevelsActions';
import { Genders, LanguageProficiencyLevels } from '../../common/utilities/choices';
import { readSkillLevels, speakSkillLevels, langaugeSkills } from "../../common/utilities/utilities";

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default function JobseekersDetailedResumeView(props, context) {
  const history = useHistory()
  const dispatch = useDispatch()

  const profile = useSelector(state => state.ProfileState.data)
  const language = useSelector(state => state.i18nState.lang)
  const cities = useSelector(state => state.CitiesState)
  const states = useSelector(state => state.StatesState)
  const countries = useSelector(state => state.CountriesState)
  const genders = Genders({}, context, false)
  const industries = useSelector(state => state.IndustriesState)
  const languages = useSelector(state => state.LanguagesState)
  const languageProficiencyLevels = LanguageProficiencyLevels({}, context, false)
  const educationLevels = useSelector(state => state.EducationLevelsState)
  const [documents, setDocuments] = useState(null)
  const [currentCountry, setCurrentCountry] = useState(null)
  const [countryObj, setCountryObj] = useState({})

  const resumeLangSkills = listObjToArr(profile.resume.languages)

  // Khmer and Myanmar
  const exceptCountries = [2, 3]

  useEffect(() => {
    !cities.fetched && dispatch(getCities())
    !states.fetched && dispatch(getStates())
    !countries.fetched && dispatch(getCountries())
    !industries.fetched && dispatch(getIndustries())
    !languages.fetched && dispatch(getLanguages())
    !educationLevels.fetched && dispatch(getEducationLevels())
  }, [])

  function listObjToArr(Objs) {
    if (Object.getOwnPropertyNames(Objs).length > 0) {
      let converted = []
      Object.values(Objs).forEach(value => converted.push(value))
      return converted;
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (profile.resume.country) {
      setCurrentCountry(profile.resume.country)
    }
  }, [profile.resume])

  useEffect(() => {
    if(countries.fetched) {
      setCountryObj(countries.items[currentCountry])
    }
  }, [currentCountry])

  useEffect(() => {
    if(countryObj && Object.keys(countryObj).length != 0){
      switch (countryObj.code) {
        case "mm":
          setDocuments(myanmarDocs)
          break;
        case "kh":
          setDocuments(combodianDocs)
          break;
        case "ne":
          setDocuments(nepalDocs)
          break;
        default:
          setDocuments(walkInDocs)
          break;
      }
    }
  },[countryObj])

  const nepalDocs = () => {
    return (<>
      <Row>
        <Col>
          <div className='mt-4' />
          <Divider text={context.t('Uploaded Nepal Documents')} style={{ fontWeight: 500 }} />
          <Row>
            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Passport')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.passport || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>
          </Row>

        </Col>
      </Row>
    </>)
  }


  const myanmarDocs = () => {
    return (<>
      <Row>
        <Col>
          <div className='mt-4' />
          <Divider text={context.t('Uploaded Myanmar Documents')} style={{ fontWeight: 500 }} />
          <Row>
            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('NRC Card')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.nrc || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>
            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Household List')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.householdlist || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>

            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Passport (PJ)')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.passport || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>

            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Labour Card')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.labourcard || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>

            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Covid Vaccine Certificate')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.covidvaccinecertificate || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>
            </Col>
          </Row>

        </Col>
      </Row>
    </>)
  }

  const combodianDocs = () => {
    return (
      <>
        <Row>
          <Col>
            <div className='mt-4' />
            <Divider text={context.t('Uploaded Khmer Documents')} style={{ fontWeight: 500 }} />
            <Row>
              <Col>
                <label htmlFor='passportImage'>
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t('Passport')}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={profile.passport || '/static/media/ImagePlaceholder.png'}
                          fallback={'/static/media/ImagePlaceholder.png'}
                          crossOrigin='anonymous'

                          style={{
                            width: '120px', height: '120px', objectFit: 'cover',
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>

              </Col>
              <Col>
                <label htmlFor='passportImage'>
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t('Identification Card')}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={profile.idcard || '/static/media/ImagePlaceholder.png'}
                          fallback={'/static/media/ImagePlaceholder.png'}
                          crossOrigin='anonymous'

                          style={{
                            width: '120px', height: '120px', objectFit: 'cover',
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>

              </Col>

              <Col>
                <label htmlFor='passportImage'>
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t('Birth Certificate')}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={profile.birthcertificate || '/static/media/ImagePlaceholder.png'}
                          fallback={'/static/media/ImagePlaceholder.png'}
                          crossOrigin='anonymous'

                          style={{
                            width: '120px', height: '120px', objectFit: 'cover',
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>

              </Col>

              <Col>
                <label htmlFor='passportImage'>
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t('Family Record Book')}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={profile.familyrecordbook || '/static/media/ImagePlaceholder.png'}
                          fallback={'/static/media/ImagePlaceholder.png'}
                          crossOrigin='anonymous'

                          style={{
                            width: '120px', height: '120px', objectFit: 'cover',
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>

              </Col>

              <Col>
                <label htmlFor='passportImage'>
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t('Residence Book')}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={profile.residencebook || '/static/media/ImagePlaceholder.png'}
                          fallback={'/static/media/ImagePlaceholder.png'}
                          crossOrigin='anonymous'

                          style={{
                            width: '120px', height: '120px', objectFit: 'cover',
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
              <Col>
                <label htmlFor='passportImage'>
                  <Card>
                    <Card.Header>
                      <Form.Label>{context.t('Covid Vaccine Certificate')}</Form.Label>
                    </Card.Header>
                    <Card.Body>
                      <Zoom>
                        <Image
                          src={profile.covidvaccinecertificate || '/static/media/ImagePlaceholder.png'}
                          fallback={'/static/media/ImagePlaceholder.png'}
                          crossOrigin='anonymous'

                          style={{
                            width: '120px', height: '120px', objectFit: 'cover',
                          }}
                        />
                      </Zoom>
                    </Card.Body>
                  </Card>
                </label>
              </Col>
            </Row>

          </Col>
        </Row>
      </>)
  }

  const walkInDocs = () => {
    return (<>
      <Row>
        <Col>
          <div className='mt-4' />
          <Divider text={context.t('Uploaded Documents')} style={{ fontWeight: 500 }} />
          <Row>
            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Passport')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.passport || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>
            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Visa')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.visa || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>

            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Work Permit')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.workpermit || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>

            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Pink Card')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.pinkcard || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>

            </Col>

            <Col>
              <label htmlFor='passportImage'>
                <Card>
                  <Card.Header>
                    <Form.Label>{context.t('Cancellation letter')}</Form.Label>
                  </Card.Header>
                  <Card.Body>
                    <Zoom>
                      <Image
                        src={profile.cancellationletter || '/static/media/ImagePlaceholder.png'}
                        fallback={'/static/media/ImagePlaceholder.png'}
                        crossOrigin='anonymous'

                        style={{
                          width: '120px', height: '120px', objectFit: 'cover',
                        }}
                      />
                    </Zoom>
                  </Card.Body>
                </Card>
              </label>
            </Col>
          </Row>

        </Col>
      </Row>
    </>)
  }


  return (
    <BaseView title={context.t('Resume')} backurl='/resume' >
      <Container className='afterTopNavigationBar'>
        <Row style={{ textAlign: 'left' }}>
          <Col>
            <div className='mt-4' />
            <Divider text={context.t('Basic Information')} style={{ fontWeight: 500 }} />
            <Row>
              <Col xs={4}>
                <Image
                  src={profile.resume.photo || '/static/media/blank-profile-picture.png'}
                  crossOrigin='anonymous'
                  style={{
                    width: '90px', height: '90px', objectFit: 'cover'
                  }}
                />
              </Col>
              <Col>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Full name')}: </span>
                  {profile.resume.full_name}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Gender')}: </span>
                  {genders[profile.resume.gender]}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Birthday')}: </span>
                  {moment(profile.resume.date_of_birth).format('ll')}
                </p>
                <p>
                  <span className='label'>{context.t('Education')}: </span>
                  {educationLevels.items[profile.resume.education] && educationLevels.items[profile.resume.education].name}
                </p>
                {educationLevels.items[profile.resume.education] && educationLevels.items[profile.resume.education].has_major &&
                  <p style={{ marginTop: 'calc(-1rem + 4px)' }}>
                    <span className='label'>{context.t('Major')}: </span>
                    {profile.resume.major}
                  </p>
                }
              </Col>
            </Row>

            <Divider text={context.t('Contact Information')} style={{ fontWeight: 500 }} />
            <Row>
              <Col>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Email')}: </span>
                  {profile.resume.email}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Phone number')}: </span>
                  {profile.resume.phone_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Emergency Phone number')}: </span>
                  {profile.resume.emergency_phone_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('LINE id number')}: </span>
                  {profile.resume.line_id_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Viber id number')}: </span>
                  {profile.resume.viber_id_number}
                </p>
                <p>
                  <span className='label'>{context.t('Current Address')}: </span>
                  {[
                    profile.resume.address_details,
                    cities.items[profile.resume.city] && cities.items[profile.resume.city].name,
                    states.items[profile.resume.state] && states.items[profile.resume.state].name,
                    countries.items[profile.resume.country] && countries.items[profile.resume.country].name
                  ].filter(x => x).join(', ')}
                </p>
              </Col>
            </Row>

            <Divider text={context.t('Documents')} style={{ fontWeight: 500 }} />
            <Row>
              <Col>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('National ID')}: </span>
                  {profile.resume.national_id_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Passport')}: </span>
                  {profile.resume.passport_number}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Passport expires')}: </span>
                  {profile.resume.passport_expire_date && moment(profile.resume.passport_expire_date).format('ll')}
                </p>
                <p style={{ marginBottom: 4 }}>
                  <span className='label'>{context.t('Certificate of Identity Number')}: </span>
                  {profile.resume.certificate_of_identity_number}
                </p>
                {language !== 'kh' &&
                  // not aplicable for Khmer users, just hide it
                  <p style={{ marginBottom: 4 }}>
                    <span className='label'>{context.t('Certificate of Identity expires')}: </span>
                    {profile.resume.certificate_of_identity_expire_date && moment(profile.resume.certificate_of_identity_expire_date).format('ll')}
                  </p>
                }
                {profile.resume && !exceptCountries.includes(profile.resume.country) &&
                  <p>
                    <span className='label'>{context.t('Pink Card Number')}: </span>
                    {profile.resume.pinkcard_id_number}
                  </p>
                }
              </Col>
            </Row>

            <Divider text={context.t('Working Experience')} style={{ fontWeight: 500 }} />
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{context.t('Title')}</th>
                  <th>{context.t('Industry')}</th>
                  <th style={{ minWidth: 50 }}>{context.t('Years')}</th>
                </tr>
              </thead>
              <tbody>
                {profile.resume.working_experience.map((item, index) =>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{profile.resume.working_experience[index].title}</td>
                    <td>{Object.keys(industries.items).length > 0 && industries.items[profile.resume.working_experience[index].industry_id].name}</td>
                    <td>{profile.resume.working_experience[index].years}</td>
                  </tr>
                )}
              </tbody>
            </Table>

            <Divider text={context.t('Languages Proficiency')} style={{ fontWeight: 500 }} />
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{context.t('Language Skills')}</th>
                  <th>{context.t('Read Level')}</th>
                  <th style={{ minWidth: 50 }}>{context.t('Speak Level')}</th>
                </tr>
              </thead>
              <tbody>
                {resumeLangSkills.map((item, index) =>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{Object.keys(languages.items).length > 0 &&
                      langaugeSkills(context, languages).map(option => {
                        if (option.value == resumeLangSkills[index].language) {
                          return option.label
                        }
                      })}
                    </td>
                    <td>{readSkillLevels(context).map(option => {
                      if (option.value == resumeLangSkills[index].read_level) {
                        return option.label
                      }
                    })}</td>
                    <td>{speakSkillLevels(context).map(option => {
                      if (option.value == resumeLangSkills[index].speak_level) {
                        return option.label
                      }
                    })}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {/* <Row>
              <Col>
                {Object.keys(profile.resume && profile.resume.languages).map((key, i) => {
                  const language = languages.items[key]

                  return (
                    <div key={i}>
                      <img src={language && language.flag} style={{ width: 12, marginRight: 6 }} />
                      <span> {language && language.name}: {languageProficiencyLevels[profile.resume.languages[key].level]} </span>
                    </div>
                  )
                })}
              </Col>
            </Row> */}
          </Col>
        </Row>
        {
          // Passport
          // Visa
          // Work Permit
          // Pink card
          // Cancellation letter
        }
        {documents}
      </Container>
    </BaseView>
  )
}

JobseekersDetailedResumeView.contextTypes = {
  t: PropTypes.func.isRequired
}
