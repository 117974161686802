import React, { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import { Row, Spinner, Button } from 'react-bootstrap';

import Subrating from './Subrating';
import { getRatingCriteries } from '../common/redux/actions/RatingCriteriesActions';
import { postRating } from '../common/redux/actions/RatingsActions'
import { updateRating } from '../common/redux/actions/RatingsActions'
import { toast } from "react-toastify"

export default function Rating(props, context) {
  const dispatch = useDispatch()

  const ratings = useSelector(state => state.RatingsState);
  const ratingCriteries = useSelector(state => state.RatingCriteriesState);
  const profile = useSelector(state => state.ProfileState);

  // criteries
  const [criteries, setCriteries] = useState([])

  // state
  const [payload, setPayload] = useState({
    user: profile.data.id,
    object_id: props.object_id,
    content_type: props.content_type,
    scores: [],
  })

  const [initialScores, setInitialScores] = useState([])

  useEffect(() => {
    !ratingCriteries.fetched && dispatch(getRatingCriteries())
  }, []);

  useEffect(() => {
    if (props.userRating) {
      setPayload({
        ...payload,
        scores: props.userRating.scores
      })
      setInitialScores(props.userRating.scores)
    }
  }, [props.userRating]);

  useEffect(() => {
    let criteries = Object.keys(ratingCriteries.items).filter(key => {
      return ratingCriteries.items[key].group === props.group
    }).map(key => ratingCriteries.items[key])
    setCriteries(criteries)
  }, [ratingCriteries.items]);


  const onRate = (subrating) => {
    const filteredSubratings = payload.scores.filter(score => score.rating_criteria !== subrating.rating_criteria)

    setPayload({
      ...payload,
      scores: [...filteredSubratings, subrating]
    })
  }

  const handleSubmitRating = () => {
    let offsetCount = 0
    let offsetScore = 0

    const flaggedScores = payload.scores.map(score => {
      const initialScore = initialScores.filter(item => item.rating_criteria === score.rating_criteria)[0]

      if (initialScore) {
        score.updated = true
        score.offset = score.score - initialScore.score
        offsetScore += score.offset
      } else {
        offsetScore += score.score
        offsetCount += 1
      }
    })

    setPayload({...payload, scores: flaggedScores})

    if (props.userRating) {
      dispatch(updateRating(props.userRating.id, payload, offsetCount, offsetScore))
    } else {
      dispatch(postRating(payload, offsetCount, offsetScore))
    }
    toast.success(context.t('Successfully gave the ratings!'))

    props.onSubmit()
  }

  const canSubmitRating = (scores) => {
    return scores.length > 0
  }

  return (
    <div>
      {ratingCriteries.loading?
        <Spinner animation="border" role="status" />
        :
        <div>
          <Row className='mt-2'>
            {criteries &&
              criteries.map((ratingCriteria, i) => {
                let value = payload.scores.filter(item => item.rating_criteria == ratingCriteria.id)[0]
                return <Subrating
                        key={i}
                        id={ratingCriteria.id}
                        score={value && value.score}
                        name={ratingCriteria.name}
                        description={ratingCriteria.description}
                        image={ratingCriteria.image}
                        onRate={onRate}/>
              })
            }

          </Row>
          <Button
            variant="outline-primary"
            className="mt-3 button-modified"
            onClick={handleSubmitRating}
            disabled={!canSubmitRating(payload.scores) || ratings.loading}>
              {ratings.loading? context.t('Loading...') : context.t('Submit rating')}
          </Button>
        </div>
      }
    </div>
  );
}

Rating.contextTypes = {
  t: PropTypes.func.isRequired
}
