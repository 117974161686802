import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types'

import Select from 'react-select';

import { getCountries } from '../../common/redux/actions/CountriesActions';
import { reactSelectDropdownStyle } from '../../common/styles/dropdowns';


const CountrySelect = (props, context) => {
  const dispatch = useDispatch()

  const countries = useSelector(state => state.CountriesState)

  const [countryOptions, setCountryOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(props.initialValue)

  useEffect(() => {
    !countries.fetched && dispatch(getCountries())
  }, []);

  useEffect(() => {
    setSelectedCountry(props.initialValue)
  }, [props.initialValue])

  useEffect(() => {
    const countryOptions = Object.keys(countries.items).sort(
      (a,b) => countries.items[a].name > countries.items[b].name? 1 : -1).map(key => {
        return {value: countries.items[key].country, label: countries.items[key].name}
    })

    setCountryOptions(countryOptions)
  }, [countries]);

  const handleCountryChange = (data, event) => {
    const value = data? (props.isMulti? data.map(country => country.value) : data.value) : (props.isMulti? [] : null)
    setSelectedCountry(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  return (
    <Select
      name='country'
      options={countryOptions}
      value={
        props.isMulti?
          countryOptions.filter(o => selectedCountry.indexOf(o.value) >= 0)
          :
          countryOptions.filter(o => o.value === selectedCountry)[0]
      }
      placeholder={props.placeholder}
      style={{textAlign:'center'}}
      onChange={handleCountryChange}
      styles={reactSelectDropdownStyle}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
    />
  )
}

CountrySelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default CountrySelect;
