import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom'
import BaseView from '../BaseView';
import JobListItemV2 from '../../components/JobListItemV2';
import useJobSearch from '../../common/hooks/useJobSearch';
import useWindowDimensions from '../../common/hooks/useWindowDimensions';

import FilterModal from "../../components/Filter/FilterModal";
import FilterResultsNotFound from '../../components/FilterResultsNotFound';
import { getCurrencies } from "../../common/redux/actions/CurrenciesActions";
import styles from './JobsViewV2.module.css';
import { Online } from "react-detect-offline";
import { getCountries } from "../../common/redux/actions/CountriesActions";

export default function JobsViewV2(props, context) {
  const dispatch = useDispatch()
  // Query and Cursor
  const [query, setQuery] = useState('')
  const [cursor, setCursor] = useState('')
  // state 
  const currencies = useSelector(state => state.CurrenciesState)
  const countries = useSelector(state => state.CountriesState)
  //resolve duplicated ra from searching query
  const [jbs, setJBS] = useState([])
  const profile = useSelector(state => state.ProfileState)
  // get window width height
  const { height, width } = useWindowDimensions();
  // check reset state
  const [reset,setReset] = useState(false)

  // filter sorter state & handler for Filter Modal 
  const [filterSorter, setFilterSorter] = useState({
    country: '',
    sort: '',
    search: '',
    industry: '',
  })

  // call back function for child component Filter Modal
  const handleFilterSorter = (updated) => {
    setFilterSorter({
      ...filterSorter,
      ...updated
    })
  }

  const filterSoterReseter = () => {
    setFilterSorter({
      country: '',
      sort: '-created_at',
      search: '',
      industry: ''
    })
  }

  const { next, loading, error, jobs, hasMore } = useJobSearch(query, cursor)

  const observer = useRef()

  const lastJobsElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && next.current) {
        setCursor(next.current)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])

  useEffect(() => {
    // check saved settings
    const savedFilterSorter = localStorage.getItem('SAVED_FILTER_SORTER_JOBS') ? localStorage.getItem('SAVED_FILTER_SORTER_JOBS') : false
    if (savedFilterSorter) {
      let decodedFilterSorter = JSON.parse(savedFilterSorter)
      setFilterSorter({ ...filterSorter, ...decodedFilterSorter, search: '' })
    }
    
    !countries.fetched && dispatch(getCountries())
    !currencies.fetched && !currencies.loading && dispatch(getCurrencies())
  }, [])

  // initial method for all filter sorter function
  useEffect(() => {
    if (filterSorter.sort != '' || filterSorter.industry != '') {
      localStorage.setItem('SAVED_FILTER_SORTER_JOBS', JSON.stringify(filterSorter))
    }

    setQuery({ search: filterSorter.search, sort: filterSorter.sort, industry: filterSorter.industry })

    //check reset state
    if(filterSorter.country== '' && filterSorter.sort == '-created_at' && filterSorter.industry == '') {
        setReset(false)
    }else{
      setReset(true)
    }

  }, [filterSorter])

  useEffect(() => {
    const uniqueJBs = Array.from(new Set(jobs.map(j => j.id)))
      .map(id => {
        return jobs.find(j => j.id === id)
      })
    setJBS(uniqueJBs)
  }, [jobs])

  // adjust the margin top of the list
  const handleMarginTop = (width) => {
    if(width> 991){
      return "120px";
    }else{
      // check reset state
      if(reset){
        return "160px";
      }else{
        return "124px";
      }
    }
  }

  return (
    <BaseView title={context.t('Jobs')} backurl='/' >
      <hr className='afterTopNavigationBarTwo'></hr>
      {
        <>
          <Online>
            {profile && profile.data && profile.data.id && profile.data.type != 'IS'?
              <>
                <Container>
                  <FilterModal
                    type="job"
                    handleFilterSorter={handleFilterSorter}
                    filterSorter={filterSorter}
                    resethandler={filterSoterReseter}
                  />
                </Container>
                <Container style={{marginTop:handleMarginTop(width)}}>
                  <Row>
                    {jbs.map((item, index) => {
                      if (jbs.length === index + 1) {
                        return (
                          <Col xs={12} sm={12} md={12} lg={12} key={index} ref={lastJobsElementRef} className="job-list-item">
                            <JobListItemV2
                              countries = {countries}
                              job={item}
                              currencies={currencies}
                              styles={styles}
                            />
                          </Col>
                        )
                      } else {
                        return (
                          <Col xs={12} sm={12} md={12} lg={12} key={index} className="job-list-item">
                            <JobListItemV2
                              countries = {countries}
                              job={item}
                              currencies={currencies}
                              styles={styles}
                            />
                          </Col>
                        )
                      }

                    })
                    }
                  </Row>
                  <div className="mt-4">
                    {loading ? context.t('Loading..') : ''}
                  </div>
                </Container>
              </>
              :
              <div className="list-view-container">
                <NavLink to='/login'> {context.t('Please login to view the jobs..')} </NavLink>
              </div>
            }
          </Online>
          {jbs.length == 0 && !loading &&
            <FilterResultsNotFound text={context.t('Sorry, no recruitment agencies matched filter parameters, please try again.')} />
          }
        </>
      }
    </BaseView >
  );
}

JobsViewV2.contextTypes = {
  t: PropTypes.func.isRequired
}
