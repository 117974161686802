import React from "react";
import { Link } from "react-router-dom";
import moment from 'moment';
import { Row, Col, Image, Badge } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup'
import 'react-rater/lib/react-rater.css'
import { addDefaultImageSrc } from '../common/utilities/utilities';
import './NotificationsListItemV2.css'

const NotificationsListItemV2 = React.memo((props) => {

  return (
    <Link
      to={props.notification.message_click_action || '#'}
    >
      <ListGroup.Item className='Card list-item' style={{ backgroundColor:props.notification.unread && "lightgreen", color:props.notification.unread && "darkblue"}}>
        <Row>
          <Col xs={3}>
            <Image
              src={props.notification.message_icon ? props.notification.message_icon : '/static/media/logo192.png'}
              crossOrigin='anonymous'
              style={{ width: 60, height: 60, objectFit: 'contain' }}
              onError={addDefaultImageSrc}
            />
          </Col>
          <Col xs={9}>
            <p style={{ marginBottom: 0 }}> {props.notification.message_body} </p>
            <p style={{ position: 'relative', textAlign: 'right', marginBottom: 0, color: '#616161', fontSize: '0.8rem' }}>
              {props.notification.unread ?
              <i className="fa fa-envelope" aria-hidden="true"></i>
              :
              <i className="fa fa-envelope-open" aria-hidden="true"></i>
              }
              &nbsp;{moment(props.notification.timestamp).fromNow()}
            </p>
          </Col>
        </Row>
      </ListGroup.Item>
    </Link>
  )
});

export default NotificationsListItemV2;
