import { combineReducers } from 'redux';

import { i18nState } from "redux-i18n";

import AuthenticationState from './reducers/AuthenticationReducer';
import AddressesState from './reducers/AddressesReducer';
import AdminState from './reducers/AdminReducer';
import CommentsState from './reducers/CommentsReducer';
import CommentFlagsState from './reducers/CommentFlagsReducer';
import ContactsState from './reducers/ContactsReducer';
import CountriesState from './reducers/CountriesReducer';
import CitiesState from './reducers/CitiesReducer';
import CurrenciesState from './reducers/CurrenciesReducer';
import EmployersState from './reducers/EmployersReducer';
import EducationLevelsState from './reducers/EducationLevelsReducer';
import FiltersSortersState from './reducers/FiltersSortersReducer';
import GroupsState from './reducers/GroupsReducer';
import GuidesState from './reducers/GuidesReducer';
import GuideCategoriesState from './reducers/GuideCategoriesReducer';
import GuideSubcategoriesState from './reducers/GuideSubcategoriesReducer';
import IndustriesState from './reducers/IndustriesReducer';
import JobsState from './reducers/JobsReducer';
import JobBenefitCategoriesState from './reducers/JobBenefitCategoriesReducer';
import JobBenefitsState from './reducers/JobBenefitsReducer';
import JobPositionCategoriesState from './reducers/JobPositionCategoriesReducer';
import LanguagesState from './reducers/LanguagesReducer';
import MaritalStatusesState from './reducers/MaritalStatusesReducer';
import MyJobsState from './reducers/MyJobsReducer';
import NationalitiesState from './reducers/NationalitiesReducer';
import NewsState from './reducers/NewsReducer';
import PollsState from './reducers/PollsReducer';
import ProfileState from './reducers/ProfileReducer';
import ProblemsState from './reducers/ProblemsReducer';
import PushNotificationsState from './reducers/PushNotificationsReducer';
import RatingCriteriesState from './reducers/RatingCriteriesReducer';
import RatingsState from './reducers/RatingsReducer';
import RecruitmentAgenciesState from './reducers/RecruitmentAgenciesReducer';
import RejectionReasonsState from './reducers/RejectionReasonsReducer';
import ServiceCategoriesState from './reducers/ServiceCategoriesReducer';
import ServiceProvidersState from './reducers/ServiceProvidersReducer';
import ServiceWorkerState from './reducers/ServiceWorkerReducer';
import StatesState from './reducers/StatesReducer';
import StaticPagesState from './reducers/StaticPagesReducer';
import SubIndustriesState from './reducers/SubIndustriesReducer';
import SurveysState from './reducers/SurveysReducer';
import UsersState from './reducers/UsersReducer';
import PendingRaState from './reducers/PendingRaReducer';
import VerfiedRaState from './reducers/VerifiedRaReducer';
import PendingEmployerState from './reducers/PendingEmployerReducer';
import VerifiedEmployerState from './reducers/VerifiedEmployerReducer';
import RecruiterRosterState from './reducers/RecruiterRosterReducer';
import ResumeRosterState from './reducers/ResumeRosterReducer';
import SubmitResumeState from './reducers/SubmitResumeReducer';
import WithdrawExtendState from './reducers/WithdrawExtendReducer';
import commentHideDeleteState from './reducers/CommentHideDeleteReducer';
import commentReactionState from './reducers/CommentReactionReducer';



const AppReducer = combineReducers({
  i18nState,
  AuthenticationState,
  AddressesState,
  AdminState,
  CommentsState,
  CommentFlagsState,
  ContactsState,
  CountriesState,
  CitiesState,
  CurrenciesState,
  EmployersState,
  EducationLevelsState,
  FiltersSortersState,
  GroupsState,
  GuidesState,
  GuideCategoriesState,
  GuideSubcategoriesState,
  IndustriesState,
  JobsState,
  JobBenefitCategoriesState,
  JobBenefitsState,
  JobPositionCategoriesState,
  LanguagesState,
  MaritalStatusesState,
  MyJobsState,
  NationalitiesState,
  NewsState,
  PollsState,
  ProfileState,
  ProblemsState,
  PushNotificationsState,
  PendingRaState,
  VerfiedRaState,
  PendingEmployerState,
  VerifiedEmployerState,
  RatingsState,
  RatingCriteriesState,
  RecruitmentAgenciesState,
  RejectionReasonsState,
  RecruiterRosterState,
  ResumeRosterState,
  ServiceCategoriesState,
  ServiceProvidersState,
  ServiceWorkerState,
  SubmitResumeState,
  StatesState,
  StaticPagesState,
  SubIndustriesState,
  SurveysState,
  UsersState,
  WithdrawExtendState,
  commentHideDeleteState,
  commentReactionState
})

const initialState = AppReducer({}, {})

const CommonReducer = (state, action) => {
  if (action.type === 'AUTHENTICATION_LOGOUT') {
    state = initialState
  }
  return AppReducer(state, action)
}

export default CommonReducer;
