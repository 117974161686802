import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";

import moment from 'moment';
import { PieChart, Pie, Sector, Cell } from 'recharts';

import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col, Image, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

import { postPollsVote } from '../../common/redux/actions/PollsActions';


// PIE CHART START
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8BC34A', '#8D6E63'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor='middle' dominantBaseline="central">
      {percent > 0 && `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
// PIE CHART END

export default function PollsListItem(props, context) {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.ProfileState.data)

  const handleClickVoteButton = (e) => {
    const payload = {
      question_id: props.data.id,
      choice_id: parseInt(e.target.value)
    }
    dispatch(postPollsVote(payload))
  }


  return (
    <div className='Card'>
      <ListGroup.Item >
        <h3 className='ListItemName'> {moment(props.data.created_at).format('LL')}: {props.data.description} </h3>
        <Row className='mt-2'>
          <Col>
            <PieChart width={120} height={120}>
              <Pie
                isAnimationActive={false}
                data={
                  Object.keys(props.data.choices).map(key => {
                    return {
                      name: props.data.choices[key].description,
                      value: props.data.choices[key].votes_count
                    }
                  }
                )}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={60}
                fill="#8884d8"
                dataKey="value"
              >
                {
                  Object.keys(props.data.choices).map((key, index) =>
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  )
                }
              </Pie>
            </PieChart>
          </Col>
          <Col style={{alignSelf: 'center', paddingLeft: 0}}>
            {profile.votes && profile.votes.filter(vote => vote.question_id === props.data.id).length > 0 || !profile.id?
              <div>
                <p style={{fontWeight: 600, marginBottom: 6}}> {context.t('{number} responses', {number: props.data.votes_count})} </p>
                {
                  Object.keys(props.data.choices).map((key, index) => {
                    return (
                      <div className='mt-1' key={index}>
                        <div style={{
                          display: 'inline-block', width: 10, height: 10,
                          marginRight: 5, backgroundColor: COLORS[index],
                          borderRadius: profile.votes && profile.votes.filter(vote => vote.choice_id === props.data.choices[key].id).length > 0 && 10
                        }}>
                        </div>
                        <p style={{display: 'inline', margin: 0}}> {props.data.choices[key].description} <span>({props.data.choices[key].votes_count})</span></p>
                      </div>
                    )
                  })
                }
              </div>
              :
              <div style={{ display: 'grid', gridGap: '5px', width: '-webkit-fill-available' }}>
                {
                  Object.keys(props.data.choices).map((key, index) => {
                    return <Button variant='outline-secondary' key={index} value={props.data.choices[key].id} onClick={handleClickVoteButton}> {props.data.choices[key].description} </Button>
                  })
                }
              </div>
            }
          </Col>
        </Row>
      </ListGroup.Item>
    </div>
  )
}

PollsListItem.contextTypes = {
  t: PropTypes.func.isRequired
}
